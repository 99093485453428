// @name Form
.tx-solr-search-functions {
    .form__field-datewrapper {
        label {
            font-size: 1em;
        }
    }
}

.clear-fix-before {
    &:before {
        clear: both;
        display: block;
        content: "" !important;
        overflow: hidden;
        visibility: hidden;
        height: 0;
        speak: none;
    }
}

form {
    display: block;
    margin: 2em 0 3em;
    &:first-child {
        margin-top: 0;
    }
    // @name Field wrapper
    // @description For wrap set of field (field + label + text...)
    .form__field-wrapper {
        margin: 1.5em 0 2.6em;
        &-inline {
            margin-bottom: 0;
            fieldset {
                margin-bottom: 0;
            }
        }
        &.grid {
            margin-bottom: 0;
        }
        .form__field-wrapper {
            margin-top: 0;
        }
    }
}

.form__twocolumns {
    margin: 0 -10px;
    &:after {
        content: "";
        display: block;
        clear: both;
    }
    &-col {
        width: 50%;
        float: left;
        padding: 0 10px;
    }
    .form__field-wrapper {
        margin-bottom: 12px;
    }
}

.form__threecolumns {
    margin: 0 -10px;
    &:after {
        content: "";
        display: block;
        clear: both;
    }
    &-col {
        width: 33.33%;
        float: left;
        padding: 0 10px 18px;
        label {
            margin-top: 0;
            .text-help {
                margin-top: 0;
            }
        }
    }
}

.form__button-wrap {
    margin-top: 8px;
    padding-bottom: 10px;
}

.form__text {
    font-size: 1.4rem;
    line-height: 1.4;
    color: $color-black;
    font-weight: 300;
    margin: 5px 0 34px;
}

.form__grid-box {
    padding-top: 14px;
    .checkbox,
    .radio {
        > div {
            width: 13.9%;
            margin-bottom: 12px;
        }
    }
}

.form__button-holder-r {
    button {
        margin-left: 12px;
    }
}

// @name Form in Powermail confirmation page
.confirmation {
    form {
        display: inline-block;
        margin: 0;
    }
}

// @name Fieldset
fieldset {
    margin-top: 3em;
    margin-bottom: 1.1em;
    border: none;
    position: relative;
    background: transparent;
    min-width: inherit; // trick for chrome
    &:first-child {
        margin-top: 0;
    }
}

// @name legend
legend {
    display: block;
    padding: 0;
    color: $color-black;
    text-align: left;
    font-style: normal;
    font-size: 2em;
    font-weight: $bold;
    white-space: normal;
    width: 100%;
    margin-bottom: 0.9em;
}

// @name Label
$c-label: $color-black;
$label-font-size: 1.4;
label,
.label {
    display: block;
    font-size: #{$label-font-size}em;
    font-weight: $bold;
    color: $c-label;
    margin: 5px 0 11px;
    cursor: pointer;
    .text-help {
        font-size: em(1.2, $label-font-size);
        color: $color-3--3;
    }
    .required {
        font-size: 1em;
    }
    // field nested
    input, select, textarea {
        margin-top: em(.5, $label-font-size);
        font-size: 1em;
        padding: em(0.85, $label-font-size) em(1, $label-font-size);
    }
}

.content ul, .content ol {
    label {
        font-size: 1.4rem;
    }
}

.nolabel {
    > label,
    > legend {
        @extend .ghost;
    }
}

// @name Help text
// @state .text-help--inline - Indicates the inline positioning
.text-help {
    font-weight: $light;
    font-size: 1.2em;
    display: block;
    color: $color-3--4;
    margin: .5em 0 -.4em;
    &.text-help--inline {

    }
}

.content ul, .content ol {
    p.text-help {
        font-size: 1.2rem;
    }
}

// @name Required field
.required {
    color: $color-red;
    font-size: 1.4em;
    font-weight: $normal;
    font-style: normal;
}

// @name Input, textarea, select
$input__font-size: 1.4;
input,
textarea,
select {
    font-size: 1.4rem;
    font-family: $typo-1;
    background: $color-white;
    width: 100%;
    border: 1px solid $color-3--2;
    padding: 0 14px;
    height: 40px;
    color: $color-black;
    &:disabled {
        background: $color-3--1;
        color: $color-3--2;
    }
    &:focus {
        outline: 1px dashed $search-bg;
        outline-offset: 0;
    }
}

input {
    &[type="file"]:read-only {
        color: $color-4--3;
        font-weight: bold;
    }
    
    &:-ms-input-placeholder {
        color: $color-3--2;
    }
}

input,
textarea {
    &:read-only {
        background: $color-3--1;
        color: $color-3--2;
    }
}

textarea {
    height: em(10, 1.4);
}

// @name Select
select {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    background: $color-white url("#{$path-images}form/select_right.png") no-repeat 100% 50%;
    cursor: pointer;
    color: $color-black;
    padding-left: 10px;
    padding-right: 30px;
    overflow: hidden !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    &:disabled {
        background: $color-3--1 url("#{$path-images}form/select_right.png") no-repeat 100% 50%;
        cursor: inherit;
    }
    &::-ms-expand {
        display: none;
    }
}

// @name Geocode input text
// @description add a "localization me" button with JS, and report user address in the input field
.geocode {
    position: relative;
    .geocode--wrapper {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 4px;
        background: none;
        .geocode--wrapper__button {
            &:before {
                content: '\e055';
            }
        }
    }
}

// @name Datepicker extend
// @description Extend _datepicker.scss : Move the datepicker opener and change datepicker size
form {
    .form__field-datewrapper {
        position: relative;
        .date-picker-control {
            position: absolute;
            bottom: 0;
            right: 0;
            margin: 10px;
            background: none;
            &:before {
                content: "\e05c";
                font-family: "icons-default";
                font-size: 1.6rem;
                color: $color-1--2;
                position: absolute;
                top: 1px;
                left: 1px;
                line-height: 1;
            }
        }
    }
}

.date-picker {
    table {
        font-size: 1.5em;
    }
}

// @name Datepicker extend
// @description Extend _jquery.timepicker.scss : change timepicker size
.ui-timepicker-wrapper {
    font-size: 1.5em;
}

// @name Parsley styling
// @description Style for form error generated by parlsey (powermail)
.parsley-errors-list {
    p {
        margin: 5px 0 15px;
        color: $alert-title;
        font-style: italic;
        font-size: 1.2em;
        @include default-icons-before('\e00f', 0, 16px, $alert-title);
    }
}

// @name Input radio and checkbox
// @description
// @state .column-2 - Indicates the column positioning
// @state .radio-checkbox--inline - Indicates radio or checkbox positioning side by side. Works only on one line.
.radio, .checkbox {
    clear: both;
    margin: 1.5em 0 1.5em;
    @extend .clear-fix;
    &.radio-checkbox--inline {
        div {
            display: inline-block;
            vertical-align: top;
            margin-top: 0;
            margin-right: 2.4%;
        }
    }
    &.column-2 {
        div {
            display: inline-block;
            vertical-align: top;
            width: 47.4%;
            &:nth-child(even) {
                margin-right: 1%;
            }
            &:nth-child(odd) {
                margin-left: 1%;
            }
        }
        legend + div, legend + div + div {
            margin-top: 0;
        }

    }
    div {
        margin: 1.1em 0;
        &:nth-of-type(1) {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    legend {
        margin: 5px 0 11px;
        font-size: 1.4em;
        .required {
            font-size: 1em;
        }
    }
    label {
        font-weight: $light;
        display: inline-block;
        position: relative;
        line-height: em(1.4, $label-font-size);
        padding: 0 0 0 22px;
        margin: 0;
        &:before {
            content: "";
            position: absolute;
            display: inline-block;
            background-color: $color-white;
            border: 1px solid $color-3--2;
            font-size: 1em; // 13px
            width: 17px;
            height: 17px;
            left: 0;
            top: -1px;
            speak: none;
        }
    }
    input[type=radio], input[type=checkbox] {
        position: absolute;
        left: -9999px;
        &:disabled + label {
            cursor: default;
            color: lighten($c-label, 70%);
        }
        &:disabled + label:before {
            border-color: $color-3--2;
            cursor: default;
        }
        &:checked + label:after {
            position: absolute;
            line-height: normal;
            content: "";
            speak: none;
        }
        &:focus {
            & + label {
                outline: 1px dashed $color-1--2;
                outline-offset: 2px;
            }
        }
    }
    // Checkbox
    input[type=checkbox] {
        &:checked + label:after, &:checked:disabled + label:after {
            font-family: icons-default;
            font-size: 10px;
            content: '\e057';
            line-height: 1;
            position: absolute;
            top: 2px;
            left: 3px;
            color: $color-1--2;
        }
        &:checked:disabled + label:after {
            color: $color-3--2;
        }
        // Here you can change svg image
        &:checked:disabled + label:after {

        }
    }

    // Radio
    input[type=radio] {
        &:checked + label:after, &:checked:disabled + label:after {
            top: 4px;
            left: 5px;
            width: 7px;
            height: 7px;
            background: $color-1--2;
            border-radius: 50%;
            display: block;
            content: "";
        }
    }
}

// Radio
.radio {
    label {
        &:before {
            border-radius: 50%;
        }
    }
}

// @name Dark form
// @description Form with dark background
.form--dark {
    background: $color-3--3;

    // @name legend
    legend {
        color: $color-white;
    }

    // @name Label
    label {
        color: $color-white;
    }

    .text-help {
        color: $color-3--1;
    }

    .required {
        color: lighten($color-red, 30%);
    }

    // @name Input, textarea, select
    input, textarea, select {
        background: darken($color-3--3, 10%);
        border-color: darken($color-3--3, 20%);
        color: $color-white;
        &:focus {
            outline-color: $color-3--1;
            outline-offset: 0;
        }
    }

    // @name Select
    select {
        background: darken($color-3--3, 10%) url("#{$path-images}form/select_right-2.png") no-repeat 100% 50%;
        &:disabled {
            background: darken($color-3--3, 10%) url("#{$path-images}form/select_right-3.png") no-repeat 100% 50%;
            color: lighten($color-3--3, 10%);
        }
    }
}

@include breakpoint(small only) {
    // @name Label
    label {
        margin-top: 0;
    }

    // @name Input radio and checkbox
    // @description
    // @state .column-2 - Indicates the column positioning
    .radio, .checkbox {
        margin: 2em 0 1.5em;
        &.column-2 {
            div {
                display: block;
                vertical-align: inherit;
                width: 100%;
            }
        }
        legend {
            margin-top: 0;
        }
    }

    // @name form position
    .form__left, .form__right {
        width: 100%;
        display: block;
        vertical-align: inherit;
        padding: 0;
        margin: 1.5em 0 2.5em;
    }

    .form__twocolumns {
        &-col {
            width: 100%;
            float: none;
        }
        .form__field-wrapper {
            margin-bottom: 12px;
        }
    }

    .form__threecolumns {
        margin: 0 -10px;
        &-col {
            width: 100%;
            display: block;
        }
    }

    .form__grid-box {
        padding-top: 14px;
        .checkbox,
        .radio {
            > div {
                width: 30%;
                margin-bottom: 12px;
            }
        }
    }

    .form__button-holder-r {
        text-align: center;
        button {
            margin: 0 0 10px;
        }
    }
}
