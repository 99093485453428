// @name Header page
.header {
    @extend .clear-fix;
    padding: 0 100px;
    position: relative;
    z-index: 10;
    &:before {
        background: $color-white url(#{$path-images}bg/bg-decor-01.png);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        content: '';
    }
    &-shadow {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 10px;
        z-index: -2;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    }
    &-holder {
        float: right;
        width: calc(100% - #{360px});
        &.search-active {
            position: relative;
        }
    }
    @media (max-width: 1500px) {
        padding-left: 10px;
        &-holder {
            width: calc(100% - #{215px});
        }
    }
    @media (max-width: 1380px) {
        padding-right: 50px;
        &-holder {
            width: calc(100% - #{215px});
        }
    }
}

.popin-home {
    height: 0 !important;
}

.page {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
}

// @name Top of content
.top-of-content {
    background: $bg-heading;
    &-white,
    &-blue {
        background: $color-white;
        .tools {
            .button-2 {
                border: 1px solid $color-2--2;
                &:before {
                    color: $color-2--2;
                }
            }
            svg {
                fill: $color-2--2;
            }
            .link-favorite {
                display: none;
            }
        }
        .tooltip-css {
            &:before {
                background: $color-2--2;
            }
            &:after {
                border-left-color: $color-2--2;
            }
        }
        .share-page .ddm__wrapper {
            background: $color-2--2;
        }
        .breadcrumb {
            p {
                a {
                    color: $color-3--3;
                    &:after {
                        color: $color-3--3 !important;
                    }
                }
            }
        }
    }
    &-blue {
        background: #deedf2;
    }
}

.top-of-content__wrapper {
    @extend .wrapper-1180;
    padding-top: 2.2em;
    padding-bottom: 2.2em;
}

// @name Main wrapper
.main {
    width: 100%;
    clear: both;
}

// @name Main section
.section-main {
}

// @name Main section wrappers
.section-main__wrapper {
    @extend .wrapper-940;
    padding-top: 5em;
    padding-bottom: 3em;
}

.section-main__wrapper-2 {
    display: table;
    width: 100%;
    > * {
        display: table-cell;
        vertical-align: top;
    }

    &_block {
        display: block;

        .section-main__content {
            display: block;
        }
    }
}

.section-main__wrapper-3 {
    @extend .wrapper-940;
    padding-top: 1em;
    padding-bottom: 3em;
}

// @name Main section content
.section-main__content {
    width: 100%;

    .content {
        > * {
            &:first-child {
                margin-top: 0 !important;
            }
        }
    }
}

// @name Main section aside
.section-main__aside {
    > .column-bloc {
        *:first-child {
            margin-top: 0;
        }
    }
}

// @name Footer
.footer {
    background: $bg-footer;
    margin-top: auto;
    padding-top: 45px;
    padding-bottom: 20px;
}

.page {
    min-height: 100vh;
}

@include breakpoint(medium down) {
    // @name Main section aside
    /*
    .section-main__aside {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0 -2%;
      > .column-bloc {
        flex: 0 1 46%;
        margin-left: 2%;
        margin-right: 2%;
        *:first-child {
          margin-top: 0;
        }
      }
    }
    */

    .header {
        padding-left: 20px;
        padding-right: 139px;
        &-holder {
            position: static !important;
            .menu-main {
                display: none;
            }
        }
    }

    // @name Main section wrappers
    .section-main {
        &__wrapper-2 {
            display: block;
            > * {
                display: block;
            }
        }
    }
    .section-main__aside {
        overflow: hidden;
        margin: 0 -10px 30px;
        display: block;
    }

    // @name Footer
    .footer {
        padding-bottom: 49px;
    }
}

@include breakpoint(small only) {
    // @name Main section wrappers

    .section-main {
        padding: 0;
    }

    .section-main__wrapper {
        padding-bottom: 20px;
    }

    .section-main__wrapper-2 {
        display: block;
    }

    .section-main__content {
        margin: 0 0 30px;
    }

    .header {
        padding: 20px 10px;
        &-holder {
            display: none;
        }
    }

    .top-of-content-blue,
    .top-of-content-white {
        display: none;
    }

    // @name Footer
    .footer {
        padding-top: 50px;
        padding-bottom: 60px;
    }

    .wrapper-1224 {
        padding: 0 10px;
    }

    .top-of-content__wrapper {
        padding-top: 2.2em;
        padding-bottom: 2.2em;
    }

    .section-main__wrapper {
        padding: 40px 10px 40px;
    }
}
