.back-link {
    font-size: 1.3rem;
    line-height: 1.2;
    font-weight: 700;
    float: left;
    text-transform: uppercase;
    color: $color-white;
    margin: 16px 0 0 3%;
    display: inline-block;
    vertical-align: top;
    &:focus {
        outline-color: $color-white;
    }
}

@include breakpoint(medium down) {
    .back-link {
        margin-left: 10px;
    }
}
