.flash-info {
    background: $color-1--2;
    padding: 27px 0 18px;
    color: $color-white;
    &-wrap {
        position: relative;
        max-width: 960px;
        padding: 0 50px 0 10px;
        margin: 0 auto;
    }
    &-logo {
        float: left;
        margin: 10px 15px 0 0;
        width: 188px;
        &-text {
            border-right: 1px solid $color-white;
            width: 116px;
            padding: 0 20px 0 0;
            color: $color-white;
            font-size: 3rem;
            line-height: 0.9;
            font-weight: 700;
            float: left;
            margin: 0 13px 0 -10px;
            text-align: right;
            text-transform: uppercase;
        }
        &-img {
            float: left;
        }
    }
    &-text {
        overflow: hidden;
        padding: 0 0 10px;
        p {
            font-size: 2rem;
            line-height: 1.2;
            font-weight: 400;
            margin: 0 0 8px;
            strong {
                font-weight: 700;
            }
        }
        a {
            font-size: 1.2rem;
            line-height: 1.2;
            font-weight: 700;
            color: $color-white;
            position: relative;
            text-transform: uppercase;
            padding: 0 0 0 10px;
            &:after {
                position: absolute;
                top: 3px;
                left: 0;
                border-left: 6px solid $color-white;
                border-top: 4px solid transparent;
                border-bottom: 4px solid transparent;
                content: '';
            }
            &:hover,
            &:focus {
                text-decoration: underline;
            }
            &:focus {
                outline-offset: -1px;
                outline-color: $color-white;
            }
        }
    }
    &-close {
        position: absolute;
        top: 45%;
        right: 0;
        width: 40px;
        height: 40px;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        overflow: hidden;
        &:focus {
            outline-offset: -1px;
            outline-color: $color-white;
        }
        &:after,
        &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 31px;
            height: 1px;
            background: $color-white;
            -webkit-transform: translate(-50%, -50%) rotate(-43deg);
            -ms-transform: translate(-50%, -50%) rotate(-43deg);
            transform: translate(-50%, -50%) rotate(-43deg);
            content: '';
        }
        &:before {
            -webkit-transform: translate(-50%, -50%) rotate(43deg);
            -ms-transform: translate(-50%, -50%) rotate(43deg);
            transform: translate(-50%, -50%) rotate(43deg);
        }
    }
}

@include breakpoint(medium down) {
    .flash-info {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@include breakpoint(small only) {
    .flash-info {
        &-wrap {
            padding: 0 10px;
        }
        &-logo {
            float: none;
            margin: 0 auto 20px;
            &:after {
                content: "";
                display: block;
                clear: both;
            }
        }
        &-close {
            top: 0;
            -webkit-transform: translate(0, 0);
            -ms-transform: translate(0, 0);
            transform: translate(0, 0);
        }
    }
}
