.menu-table-calendar {
    padding: 0 0 56px;
    &-carousel {
        width: 76%;
        margin: 0 auto;
        position: relative;
    }

    .item {
        font-weight: 700;
        font-size: 2.5rem;
        color: $color-3--4;
        text-align: center;
        padding: 0 26px;
    }

    .prevCaroussel3,
    .nextCaroussel3 {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .prevCaroussel3 {
        left: 0;
        right: auto;
    }

    .nextCaroussel3 {
        right: 0;
        left: auto;
    }
}

@include breakpoint(medium down) {
    .menu-table-calendar {
        padding: 0 0 35px;
        .item {
            font-weight: 700;
            font-size: 2rem;
            color: $color-3--4;
            text-align: center;
        }
    }
}

@include breakpoint(small only) {
    .menu-table-calendar {
        padding: 0 0 20px;
        &-carousel {
            width: 100%;
        }
        .item {
            font-weight: 700;
            font-size: 1.6rem;
            color: $color-3--4;
            text-align: center;
        }
    }
}

