// @name Go so far
// @description Sections content below the content

.go-so-far {
    background: $color-white url("#{$path-images}ddm-content-bg-1.png") repeat 0 0;
    padding: 3.8em 0;
}

.go-so-far__title {
    text-align: center;
    margin-top: 0;
    font-size: 3em;
    color: $color-3--4;
}

.go-so-far__wrapper {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    margin: 0 -2%;
    > * {
        flex: 1;
        margin: 2.3em 2% 0 11.7%;
        &:last-child {
            margin: 2.3em 2% 0 0%;
        }
    }
}

.go-so-far__item {

}

@include breakpoint(medium down) {
    // @name Go so far
    // @description Sections content below the content

    .go-so-far__wrapper {
        flex-wrap: nowrap;
        > * {
            flex-basis: 0;
            margin: 2.3em 0.5% 0 4%;
        }
    }
    .go-so-far__title {
        padding: 0 15%;
    }
}

@include breakpoint(small only) {
    // @name Go so far
    // @description Sections content below the content

    .go-so-far__wrapper {
        flex-wrap: wrap;
        > * {
            flex-basis: 100%;
        }
    }
    .go-so-far {
        padding: 2.8em 0;
        &__title {
            padding: 0;
            font-size: 2.5rem;
            margin: 0 0 30px;
        }
        &__item {
            ul {
                margin: 0;
            }
            margin: 0 0 0 8px;
            &:last-child {
                margin: 0 0 0 8px;
                ul {
                    margin: 0;
                }
            }
        }
    }
}
