// @name bloc contact

// @name Bloc contact in column
.column-bloc.bloc-contact {
  img {
    max-width: 100%;
    height: auto;
    margin-bottom: 2em;
  }
  .bloc-contact__title {
    $bloc-contact__title__font-size: 1.8;
    font-family: $typo-3;
    font-weight: $bold;
    font-size: #{$bloc-contact__title__font-size}em;
    margin: 0 0 em(0.3, $bloc-contact__title__font-size);
    color: $color-black;
    a {
      color: $color-black;
      &:hover, &:focus {
        color: $color-1--2;
      }
    }
    span{
      display: block;
      color: $color-2--2;
      line-height: 1.1;
      &:last-child{
        text-transform: uppercase;
      }
    }
  }
  .list-infos {

  }
  .list-infos__wrapper {
    display: block;
    flex-wrap: inherit;
    justify-content: inherit;
    > * {
      flex: inherit;
    }
    .list-infos__listitems + .list-infos__listitems {
      border-left: 0;
      padding-left: 0;
      margin-left: 0;
    }
    .list-infos__comunications{
      font-size: 1.4em;
      color: $color-3--3;
      margin: 0 0 12px;
    }
  }
  .button-2 {
    margin-top: em(2, $button-2-font-size);
    display: block;
    padding: 14px 10px 14px 10px;
    font-size: 1.3em;
    text-align: center;
    font-weight: $normal;
    font-family: $typo-5;
    &:before{
      content:"";
    }
    span{
      display: inline-block;
      vertical-align: top;
      position: relative;
      padding: 0 0 0 20px;
      text-decoration: none;
      color: $color-white;
      &:before {
        content: "\e04d";
        left: -15px;
        font-family: "icons-default";
        font-size: 1em;
        position: absolute;
        top: 0;
        right: inherit;
        bottom: inherit;
        left: 0;
        line-height: 1;
      }
    }
  }
}
