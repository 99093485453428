.timeline {
    max-width: 1280px;
    padding: 0 10px 50px;
    margin: 0 auto;
    &-menu {
        padding: 0 46px;
        position: relative;
        margin: 0 0 46px;
        .prevCaroussel3,
        .nextCaroussel3 {
            position: absolute;
            top: 36px;
            left: 13px;
        }
        .nextCaroussel3 {
            left: auto;
            right: 13px;
        }
    }
    &-year {
        display: block;
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 1.2;
        color: $color-black;
        margin: 0 0 6px;
    }
    &-month {
        border-top: 2px solid $color-4--2;
        border-bottom: 2px solid $color-4--1;
        padding: 8px 0;
        margin: 0 -2px;
        &:after {
            content: "";
            display: block;
            clear: both;
        }
        li {
            float: left;
            width: 8.33%;
            padding: 0 2px;
        }
        a {
            display: block;
            border: 1px solid $color-3--7;
            padding: 7px 5px;
            font-size: 1.2rem;
            line-height: 1.2;
            color: $color-black;
            text-transform: uppercase;
            font-weight: 700;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &.disabled {
                pointer-events: none;
                text-decoration: none;
            }
        }
    }
    &-block {
        &-wrap {
            position: relative;
            padding-top: 4px;
            &:before {
                position: absolute;
                top: 0;
                left: 50%;
                bottom: 0;
                width: 1px;
                -webkit-transform: translate(-50%, 0);
                -ms-transform: translate(-50%, 0);
                transform: translate(-50%, 0);
                background: $color-3--3;
                content: '';
            }
            &:after {
                content: "";
                display: block;
                clear: both;
            }
        }
        &-month {
            padding: 0 0 32px;
            overflow: hidden;
            &-title {
                text-align: center;
                position: relative;
                margin: 0 0 32px;
                strong {
                    font-size: 1.2rem;
                    line-height: 1.2;
                    color: $color-black;
                    background: $color-4--1;
                    text-transform: uppercase;
                    display: inline-block;
                    vertical-align: top;
                    padding: 5px 15px;
                }
            }
        }
    }
    &-day {
        display: block;
        font-weight: 700;
        font-size: 1.2rem;
        line-height: 1.2;
        color: #1d1d1b;
        text-transform: uppercase;
        padding: 0 0 42px;
        position: relative;
        text-align: center;
        &:after,
        &:before {
            position: absolute;
            bottom: 7px;
            left: 50%;
            -webkit-transform: translate(-50%, 0);
            -ms-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
            font-family: icons-default;
            content: '\e018';
            font-size: 16px;
            line-height: 1;
            color: $color-3--2;
            font-weight: 400;
        }
        &:before {
            bottom: 17px;
        }
    }
    &-box {
        width: 50%;
        &-left {
            float: left;
            padding-right: 20px;
        }
        &-right {
            display: table;
            padding-left: 20px;
            .timeline-item {
                border-left-width: 0;
                border-right: 10px solid $color-4--1;
                float: left;
                margin-top: 96px;
            }
            .timeline-img-without-right {
                border-left: 10px solid $color-4--1;
                border-right-width: 0px;
            }
            .timeline-item-img-without-right {
                border-right-width: 0px;
            }
            .timeline-img {
                -webkit-order: 1;
                order: 1;
            }
            .timline-content {
                -webkit-order: 0;
                order: 0;
            }
            .agenda-block-caption {
                &:before {
                    right: auto;
                    left: -49px;
                }
                &:after {
                    right: auto !important;
                    left: -34px;
                    -webkit-transform: rotate(180deg);
                    -ms-transform: rotate(180deg);
                    transform: rotate(180deg);
                }
            }
        }
    }
    article {
        &:nth-of-type(2n+1) {
            margin-left: auto;
        }
        &:nth-of-type(1) {
            .timeline-item {
                margin-top: 0 !important;
            }
        }
    }
    &-item {
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        border-left: 10px solid $color-4--1;
        position: relative;
        float: right;
        width: 100%;
        &.timeline-item-small {
            max-width: 530px;
            .agenda-block {
                &-caption {
                    font-size: 1.2rem;
                    padding-bottom: 11px;
                    margin-bottom: 13px;
                }
                &-title {
                    font-size: 2.2rem;
                    margin-bottom: 8px;
                }
                &-time {
                    font-size: 1.6rem;
                }
                &-text {
                    font-size: 1.2rem;
                }
            }
            .date-1 {
                margin-bottom: 1.3rem;
                time {
                    padding: 0.6rem 1.4rem 1.1rem;
                }
                .day {
                    font-size: 3.7rem;
                }
                .month {
                    font-size: 1.2rem;
                }
            }
            .timeline-content {
                min-height: 258px;
            }
        }
    }
    &-item-img-without-left {
        border-left-width: 0;
        float: right;
        .timeline-img-without-left {
            border-right: 10px solid $color-4--1;
        }
    }
    &-img {
        width: 48%;
        position: relative;
        overflow: hidden;
        border-left-width: 0;
        border-right-width: 0;
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            min-width: 100%;
            min-height: 100%;
            max-width: none;
        }
    }
    &-content {
        width: 52%;
        border: 1px solid $color-3--7;
        min-height: 296px;
        padding: 18px 25px;
    }
    .date-1 {
        margin-bottom: 1.8rem;
        time {
            padding: 0.7rem 1.6rem 1.2rem;
        }
        .day {
            font-size: 4.2rem;
        }
        .month {
            font-size: 1.4rem;
        }
    }
    .agenda-block {
        &-caption {
            font-size: 1.4rem;
            padding-bottom: 13px;
            margin-bottom: 15px;
            line-height: 1.25;
            position: relative;
            &:after {
                font-family: icons-project;
                font-style: normal;
                content: '\e008';
                font-size: 20px;
                line-height: 1;
                position: absolute;
                bottom: -16px;
                right: -34px;
                padding: 5px 0;
                background: $color-white;
                color: $color-4--1;
            }
            &:before {
                background: $color-4--2;
                width: 7px;
                height: 7px;
                position: absolute;
                bottom: -5px;
                right: -50px;
                margin: -1px 0 0;
                border-radius: 50%;
                content: '';
            }
        }
        &-title {
            font-size: 2.5rem;
            margin-bottom: 11px;
            a {
                &:after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 2;
                    content: '';
                }
            }
        }
        &-time {
            font-size: 1.8rem;
        }
        &-text {
            font-size: 1.4rem;
        }
    }
}

@include breakpoint(medium down) {
    .timeline {
        padding: 0 20px 50px;
        &-menu {
            padding: 0 74px;
            margin-bottom: 50px;
            .prevCaroussel3 {
                top: 52px;
                left: 43px;
            }
            .nextCaroussel3 {
                top: 53px;
                right: 43px;
            }
        }
        &-month {
            padding-bottom: 2px;
            li {
                width: 16.66%;
                margin-bottom: 6px;
            }
        }
        &-block {
            &-month {
                padding: 0 0 29px;
            }
        }
        &-item {
            max-width: 297px;
            display: block;
            border: none !important;
            &.timeline-item-small {
                max-width: 233px;
                .timeline-img {
                    border-left-width: 7px;
                }
                .date-1 {
                    margin-bottom: 1.3rem;
                    time {
                        min-width: 63px;
                        padding: 0.6rem 1.2rem 1rem;
                    }
                    .day {
                        font-size: 3.3rem;
                    }
                    .month {
                        font-size: 1.1rem;
                    }
                }
                .timeline-content {
                    padding: 12px 25px;
                    min-height: 221px;
                }
                .agenda-block {
                    &-caption {
                        font-size: 1.1rem;
                    }
                    &-title {
                        font-size: 2rem;
                    }
                    &-time {
                        font-size: 1.5rem;
                    }
                    &-text {
                        font-size: 1.2rem;
                    }
                }
            }
        }
        &-img {
            width: 100%;
            border-left: 10px solid $color-4--1;
            img {
                position: static;
                -webkit-transform: translate(0, 0);
                -ms-transform: translate(0, 0);
                transform: translate(0, 0);
                min-width: 0;
                min-height: 0;
                display: block;
                width: 100%;
                height: auto;
            }
        }
        &-box {
            &-right {
                .timeline-item {
                    margin-top: 221px;
                    &.timeline-item-small {
                        .timeline-img {
                            border-left-width: 0;
                            border-right-width: 7px;
                        }
                    }
                }
                .timeline-img {
                    border-left-width: 0;
                    border-right: 10px solid $color-4--1;
                }
            }
        }
        &-content {
            width: 100%;
            min-height: 280px;
        }
        .date-1 {
            margin-bottom: 1.5rem;
            .day {
                font-size: 4rem;
            }
            .month {
                font-size: 1.3rem;
            }
        }
        .agenda-block {
            &-caption {
                font-size: 1.3rem;
            }
            &-title {
                font-size: 2.4rem;
            }
            &-time {
                font-size: 1.7rem;
            }
            &-text {
                font-size: 1.3rem;
            }
        }
    }
}

@include breakpoint(small only) {
    .timeline {
        padding: 0 16px 30px;
        &-menu {
            padding: 0;
            margin: 0 0 63px;
            .prevCaroussel3 {
                left: -8px;
                top: 1px;
            }
            .nextCaroussel3 {
                right: -8px;
                top: 1px;
            }
        }
        &-month {
            li {
                width: 33.33%;
            }
        }
        &-year {
            margin: 5px 0 21px;
            text-align: center;
        }
        &-day {
            text-align: left;
            margin: 0 -5px;
            &:before,
            &:after {
                -webkit-transform: translate(0, 0);
                -ms-transform: translate(0, 0);
                transform: translate(0, 0);
                left: -2px;
            }
        }
        &-box {
            padding: 0 0 0 20px !important;
            float: none !important;
            width: 100% !important;
            display: block !important;
        }
        &-item {
            max-width: none !important;
            margin: 0 0 23px !important;
            float: none !important;
            display: block !important;
            &-small {
                width: auto !important;
                margin: 0 35px 23px 0 !important;
            }
        }
        &-block {
            &-wrap {
                padding-top: 28px;
                &:before {
                    left: 0;
                    -webkit-transform: translate(0, 0);
                    -ms-transform: translate(0, 0);
                    transform: translate(0, 0);
                }
            }
            &-month {
                overflow: visible;
                padding: 0 0 7px;
                &:after {
                    content: "";
                    display: block;
                    clear: both;
                }
                &-title {
                    text-align: left;
                    padding: 0 1px;
                }
            }
        }
        &-content {
            padding: 23px 25px 15px;
            min-height: 274px;
        }
        .date-1 {
            margin-bottom: 1.5rem;
            .day {
                font-size: 3.8rem;
            }
            .month {
                font-size: 1.2rem;
            }
        }
        .agenda-block {
            &-caption {
                font-size: 1.2rem;
                &:before {
                    right: auto;
                    left: -49px;
                    bottom: -4px;
                }
                &:after {
                    right: auto !important;
                    left: -34px;
                    bottom: -17px;
                    -webkit-transform: rotate(180deg);
                    -ms-transform: rotate(180deg);
                    transform: rotate(180deg);
                }
            }
            &-title {
                font-size: 2.3rem;
            }
            &-time {
                font-size: 1.6rem;
            }
            &-text {
                font-size: 1.2rem;
            }
        }
    }
}

