.partners-gallery {
	.listItems {
		display: flex;
		justify-content: center;
		padding: 0 20px;
		.item {
			display: flex;
			align-items: center;
			&:not(:first-child) {
				padding: 0 0 0 130px;
				position: relative;
				&:after {
					position:absolute;
					top:50%;
					left:15px;
					transform: translate(0, -50%) rotate(-70deg);
					width: 100px;
					height: 2px;
					background: #bfbfbf;
					content: '';
				}
			}
			a {
				display: block;
				img {
					display: block;
					max-width: 100%;
					height: auto;
				}
			}
		}
	}
}
@include breakpoint(small only) {
.partners-gallery {
	padding: 0 38px;
	position: relative;
	.listItems {
		padding: 0;
		.item {
			text-align: center;
			height: 100%;
			height: 121px;
			&:after {
				display:inline-block;
				vertical-align:middle;
				height: 100%;
				content: '';
			}
			&:not(:first-child) {
				padding: 0;
				&:after {
					display: none;
				}
			}
			a {
				display:inline-block;
				vertical-align:middle;
			}
		}
	}
	.prevCaroussel3,
	.nextCaroussel3 {
		position:absolute;
		top:50%;
		transform: translate(0, -50%);
		left:0;
	}
	.nextCaroussel3 {
		left: auto;
		right: 0;
	}
}
}
