// @name Shame
// @description Because no project is perfect, you can add some code here if you don't know where to add it.
.popup__container {
    .list-infos_title {
        margin-top: 0;
    }
}

.map-content {
    .map__popup {
        display: none;
        &.map__popup--active {
            display: table-cell;
        }
    }
}
