// @name bloc event

// @name Bloc event in column
.column-bloc.bloc-event {
  .title-3{
    letter-spacing: -1px;
  }
  img {
    max-width: 100%;
    height: auto;
    margin-bottom: 2em;
  }
  .bloc-event__wrapper-date {
    margin-bottom: 2.4em;
    text-align: center;
  }
  .bloc-event__title {
    $bloc-event__title__font-size: 1.6;
    font-family: $typo-3;
    font-weight: $bold;
    font-size: #{$bloc-event__title__font-size}em;
    margin: 0 0 em(1.5, $bloc-event__title__font-size);
    color: $color-black;
    a {
      color: $color-black;
      &:hover, &:focus {
        color: $color-1--2;
      }
    }
  }
  .date-1 {
    margin-bottom: 2rem;
    a{
      color:$color-black;
      }
    span:first-child + time {
        margin-left: 0;
    }
  }
  .agenda-block-caption{
    padding-bottom: 6px;
    margin-bottom: 20px;
    a{
      color: $color-3--3;
    }
  }
  .link-view__feature{
    padding: 16px 12px 16px 15px;
    margin: 0 0 0 18px;
    &:after{
      color: $color-4--1;
    }
  }
}
