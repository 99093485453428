.ddm.ddm__content + .ddm.ddm__content {
    margin-top: -3.9em;
}

.ddm.ddm__content {
    margin: 6em 0 4em 0;
    p {
        &:first-child {
            /* color: $color-3--3; */
        }
    }
    .ddm__title {
        margin-bottom: 0;
    }
    .ddm__sub-level {
        position: static;
        top: inherit;
        right: inherit;
        z-index: inherit;
        display: block;
        height: auto;
        overflow: hidden;
        max-height: 0;
        transition: all ease .2s;
        padding: 0 4em;
        h4 {
            margin: 1.5em 0 0.6em 0;
        }
        .subtitle {
            color: $color-2--6;
            font-size: 1.6em;
            font-weight: $medium;
            text-transform: none;
            font-family: $typo-5;
            display: block;
            margin: 1em 0 0.7em;
        }
        .sub-text {
            color: $color-2--6;
            font-family: $typo-5;
            font-weight: $light;
            margin-bottom: 1.3em;
        }
        ol {
            margin-bottom: 0.7em;
            color: $color-2--6;
            font-family: $typo-5;
            li {
                &:before {
                    color: $color-2--6;
                }
                ol {
                    color: $color-3--3;
                    font-size: 1em;
                }
                li {
                    &:before {
                        color: $color-3--3;
                    }
                }
            }
        }
    }
    &.ddm--active {
        .ddm__sub-level {
            max-height: 170em;
            transition: all ease-in-out 1s;
            padding: 2em 4em 1em;
        }
    }
}

.ddm__content {
    .ddm__button {
        display: block;
        width: 100%;
        text-align: left;
        font-size: em(2.5, 1.4);
        font-family: $typo-3;
        font-weight: $normal;
        line-height: 1em;
        padding: em(1, 2.2) em(4.8, 2.2) em(1.4, 2.2) em(2.8, 2.2);
        position: relative;
        transition: all ease .3s;
        white-space: normal;
        background: $bg-ddm-btn url("#{$path-images}ddm-content-bg-1.png") repeat 0 0;
        color: $color-ddm-btn;
        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 48px;
            background: transparent;
            z-index: 1;
        }
        @include default-icons-absolute-before('\e08a', em(1.8, 2.2), $color-black, 50%, em(0.6, 1), inherit, inherit);
        &:before {
            z-index: 2;
            transform: translateY(-50%);
        }
    }
    &.ddm--active {
        border: 1px solid $color-3--7;
        .ddm__button {
            background: $bg-ddm-btn-active;
            color: $color-ddm-btn-active;
            &:before {
                content: "\e023";
                color: $color-white;
            }
            &:after {
                //background: $color-1--3;
                border-left: 4px solid $color-1--3;
            }
        }
    }
}

@include breakpoint(small only) {
    .ddm.ddm__content {
        margin: 3em 0 4em;
        .ddm__sub-level {
            padding: 0 2.4em 0 2.4em;
            h4 {
                margin: 1em 0 0.7em;
                line-height: 1.2;
            }
        }
        &.ddm--active {
            .ddm__sub-level {
                padding: 2em 2.4em 1em 2.4em;
            }
        }
    }
    .ddm__content {
        .ddm__button {
            padding: 15px 35px 20px 15px;
            line-height: 0.8;
        }
    }
}
