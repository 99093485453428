// Newsletter inscription
.newsletter {
	float: left;
	width: 246px;
	form {
		margin: 0;
	}
	.newsletter__title {
		font-size: 1.6rem;
		line-height: 1.2;
		font-weight: 300;
		font-style: italic;
		margin: 0 0 11px;
		color: $color-white;
		text-transform: uppercase;
	}
	tr {
        display: block;

        td:last-child {
            display: block;
            position: relative;
            padding: 0;
            margin: 0 0 9px;
            background: $color-3--5;
        }
    }
    label {
        color: $color-white;
    }
    input {
        font-size: 1.3rem;
        font-weight: 400;
        color: $color-white;
        padding: 0 16px;
        height: 34px;
        background: none;
        border: none;
        &:focus {
            outline-color: $color-white;
            outline-offset: -1px;
        }
    }
	&__button-wrapper {
		position: absolute;
		top: 0;
		right: 0;
		button {
			background: none !important;
			padding: 0 !important;
			border: none;
			width: 42px;
			height: 34px;
			svg {
				fill: $color-2--2 !important;
				width: 18px;
				height: 16px;
			}
			&:focus {
				outline-color: $color-white;
				outline-offset: -1px;
			}
		}
	}
	&__links {
		margin: 10px 0 0 -17px;
		font-size: 1.2rem;
		line-height: 1.2;
		font-weight: 300;
		text-transform: uppercase;
		li {
			display: inline-block;
			vertical-align: top;
			margin: 0 0 0 17px;
		}
		a {
			display: block;
			color: $color-white;
			padding: 0 0 0 20px;
			position: relative;
			&:after {
				position: absolute;
				top: 1px;
				left: 1px;
				color: $color-2--2;
				font-family: icons-project;
				font-size: 13px;
				line-height: 1;
				content: '\e008';
			}
			&:focus {
				outline-color: $color-white;
				outline-offset: -1px;
			}
		}
	}
}

@include breakpoint(small only) {
.newsletter {
	float: none;
	width: 100%;
	padding: 0 27px;
}
}
