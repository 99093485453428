.hero {
    position: relative;

    &__container {
        max-width: 2560px;
    }

    &__list-item {
        width: 100%;
    }

    .slider-actions {
        @include absolute(null, null, 100px, 200px);
        @include size(auto, 50px);
        align-items: center;
        background-color: $color-1--2;
        display: inline-flex;
        justify-content: flex-start;
        padding: 0 10px 0 20px;
        z-index: 120;

        @include breakpoint(medium down) {
            bottom: 175px;
            left: 110px;
        }

        @include breakpoint(small down) {
            bottom: 130px;
            left: 50%;
            height: 28px;
            padding: 0 5px 0 10px;
            transform: translateX(-50%);
        }
    }

    .modeplay {
        @include size(40px);
        align-items: center;
        cursor: pointer;
        display: flex;
        justify-content: center;

        @include breakpoint(small down) {
            @include size(28px);
        }

        &:before {
            @include trs;
            color: $color-white;
            content: '\e075';
            font-family: 'icons-default';
            font-size: 1.8rem;
        }

        &--pause:before {
            content: '\e076';
        }

        @include on-event {
            &:before {
                color: $color-4--2;
            }
        }
    }

    .pagerCarrousel1 {
        align-items: center;
        display: flex;

        li {
            &.cycle-pager-active {
                button {
                    background: $color-4--2;
                    border-color: $color-4--2;
                    border-radius: 5px;
                    width: 26px;
                }
            }
        }

        button {
            @include size(10px);
            border: 1px solid $color-white;
            border-radius: 50%;
            color: transparent;
            cursor: pointer;
            margin: 3px;
            display: block;
            position: relative;
            line-height: 0;
            font-size: 0;
            transition: all 300ms ease;

            @include on-event {
                background: $color-4--2;
                border-color: $color-4--2;
            }
        }
    }

    .prevCarrousel1,
    .nextCarrousel1 {
        @include absolute(50%);
        transform: translateY(-50%);
        z-index: 120;

        button {
            @include trs;
            @include size(50px);
            align-items: center;
            color: $color-white;
            display: flex;
            font-size: 5rem;
            justify-content: center;

            @include breakpoint(medium down) {
                font-size: 4rem;
            }

            @include fa-icon-style(false) {
                color: inherit;
                text-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
            }

            @include on-event {
                background-color: $color-1--2;
            }
        }
    }

    .prevCarrousel1 {
        left: 60px;

        @include breakpoint(medium down) {
            left: 30px;
        }
    }

    .nextCarrousel1 {
        right: 60px;

        @include breakpoint(medium down) {
            right: 30px;
        }

        .has-quick-links & {
            right: 380px;

            @include breakpoint(medium down) {
                right: 30px;
            }
        }
    }
}

.hero-item {
    $this: &;

    min-width: 100%;
    position: relative;

    &__media {
        max-height: 100vh;
        min-height: 460px;
        overflow: hidden;
        position: relative;

        @include breakpoint(medium down) {
            min-height: auto;
        }

        @include breakpoint(small down) {
            max-height: none;
        }

        .has-video & {
            @include responsive-ratio(1920, 460, 'before');

            @include breakpoint(medium down) {
                @include responsive-ratio(768, 440, 'before');
                max-height: 440px;
            }

            @include breakpoint(small down) {
                @include responsive-ratio(360, 360, 'before');
                max-height: 360px;
            }
        }
    }

    &__picture {
        display: block;
        height: 100%;
        position: relative;

        &::after {
            @include absolute(null, null, 0, 0);
            @include size(100%, 340px);
            background-image: linear-gradient(rgba($color-black, 0) 0%, $color-black 100%);
            content: "";
            opacity: 0.8;

            @include breakpoint(medium down) {
                height: 210px;
            }
        }

        img {
            @include object-fit;
            @include size(100%, 460px);
            display: block;

            @include breakpoint(medium down) {
                height: 440px;
            }

            @include breakpoint(small down) {
                height: 360px;
            }
        }
    }

    video {
        @include object-fit;
        @include absolute(0, 0, 0, 0);
        @include size(100%);
        background-color: $color-black;
        margin: auto;
        max-width: 100%;
    }

    &__link {
        @include absolute(null, null, 150px, 200px);
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        max-width: 402px;
        text-decoration: none;
        z-index: 2;

        @include breakpoint(medium down) {
            bottom: 225px;
            left: 110px;
        }

        @include breakpoint(small down) {
            align-items: center;
            bottom: 160px;
            left: 50%;
            text-align: center;
            transform: translateX(-50%);
        }

        &::after {
            @include trs;
            @include absolute(0, null, null, 0);
            @include size(0, calc(100% + 5px));
            background-color: $color-1--2;
            content: '';
            z-index: -1;

            @include breakpoint(small down) {
                @include size(100%, 0);
            }
        }

        @include on-event {
            text-decoration: none;

            &::after {
                width: calc(100% + 1px);

                @include breakpoint(small down) {
                    height: calc(100% + 5px);
                }
            }
        }
    }

    &__category {
        background-color: $color-1--2;
        color: $color-white;
        display: inline-flex;
        font-family: $typo-1;
        font-size: 1.6rem;
        font-weight: $normal;
        line-height: 1.187;
        margin: 0 0 4px;
        padding: 12px 24px;
        text-decoration: none;
        text-transform: uppercase;

        @include breakpoint(medium down) {
            font-size: 1.2rem;
            padding: 10px 15px;
        }

        @include breakpoint(small down) {
            font-size: 1rem;
        }
    }

    &__title {
        color: $color-white;
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        font-family: $typo-1;
        font-size: 4.5rem;
        font-weight: $bold;
        line-height: 1.1;
        margin: 0;
        text-decoration: none;

        @include breakpoint(medium down) {
            font-size: 2.5rem;
        }

        @include breakpoint(small down) {
            align-items: center;
            font-size: 2.2rem;
        }

        span {
            display: block;
            padding: 2px 24px;
            position: relative;
            width: max-content;

            @include breakpoint(medium down) {
                padding: 2px 15px;
            }

            &::before {
                @include trs;
                @include absolute(50%, null, null, 50%);
                @include size(100%, calc(100% + 10px));
                background-color: $color-1--2;
                content: '';
                transform: translate(-50%, -50%);
                z-index: -1;
            }
        }
    }
}
