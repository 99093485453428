.hero-section {
    margin: 0 auto;
    max-width: 2560px;
    position: relative;

    .quicklinks {
        @include size(320px, 100%);
        @include absolute(0, 0);
        z-index: 101;

        @include breakpoint(medium down) {
            @include size(100%, auto);
            bottom: 0;
            left: 0;
            right: auto;
            top: auto;
        }
    }
}
