.actualites-block {
    $this: &;
    max-width: 1440px;
    margin: 0 auto;
    padding: 36px 10px 50px;
    &-mosaic {
        overflow: hidden;
        margin: 0 -12px 6px;
    }
    &-mosaic-center {
        overflow: hidden;
        margin: 0 -12px 6px;
        flex-wrap: wrap;
        display: flex;
        justify-content: center;
    }
    &-col {
        float: left;
        width: 33.33%;
    }
    &-img {
        position: relative;
        &:after {
            content: '';
            background: #c4302b;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: .3s;
        }
    }
    &-item {
        padding: 0 12px 24px;
        float: left;
        text-decoration: none !important;
        &:hover,
        &:focus {
            .actualites-block-img {
                &:after {
                    opacity: 0.2;
                }
            }
            .actualites-block-item-title {
                text-decoration: none;
                color: $color-2--2;
            }
        }
        &:focus {
            outline-offset: -1px;
        }
        &-large {
            color: $color-black;
            width: 100%;
        }
        &-small {
            width: 50%;
            color: $color-black;
        }
        .no-image {
            width: 100%;

            &::before {
                content: fa-content($fa-var-images);
                font-family: $font-awesome;
                top: 50%;
                left: 50%;
                color: $color-3--2;
                font-size: 5rem;
                opacity: 1;
                transform: translate(-50%, -50%);
                transition: opacity ease 0.3s;
                position: absolute;
            }

            &::after {
                padding-top: 52%;
                display: block;
                background: lighten($color-black, 40%);
                height: 100%;
                width: 100%;
                position: static;
                opacity: 1;
            }
        }
        img {
            display: block;
            width: 100%;
            height: auto;
        }
        &-content {
            background: $color-white url(#{$path-images}bg/bg-decor-01.png);
            padding: 10px 10px;
            text-align: center;
            min-height: 80px;
            display: flex;
            align-items: flex-start;
            justify-content: center;
        }
        &-title {
            font-size: 2rem;
            line-height: 1.2;
            color: $color-black;
            display: block;
            font-weight: 700;
            transition: .3s;
            &:hover {
                text-decoration: none;
            }
        }
    }

    &.actualites-block-with-focus {
        #{$this}__focus,
        #{$this}__wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0 -4px;
        }

        #{$this}__focus {
            #{$this}-item {
                padding: 0 4px 7px;
                width: 50%;

                @include breakpoint(medium down) {
                    width: 100%;
                }
            }
        }

        #{$this}__wrapper {
            #{$this}-item {
                padding: 0 4px 7px;
                width: 25%;

                @include breakpoint(medium down) {
                    width: 50%;
                }

                @include breakpoint(small down) {
                    width: 100%;
                }
            }
        }

        .button-list {
            margin-top: 40px;
        }
    }
}

@include breakpoint(medium down) {
    .actualites-block {
        padding: 36px 20px 30px;
        &-col {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
        }
        &-item {
            background: url(#{$path-images}bg/bg-decor-01.png);
            background-clip: content-box;
            &-large {
                width: 72.6%;
            }
            &-small {
                height: 50%;
                width: 100%;
                &-wrap {
                    display: flex;
                    flex-wrap: wrap;
                    width: 27.4%;
                }
                .actualites-block-item-title {
                    font-size: 1.6rem;
                }
                .actualites-block-item-content {
                    height: auto !important;
                    min-height: 68px;
                    background: none;
                }
            }
        }
        &-threecolumns {
            .actualites-block-item {
                &-large {
                    width: 100%;
                }
            }
        }
    }
}

@include breakpoint(small down) {
    .actualites-block {
        padding-bottom: 30px;
        padding-left: 10px;
        padding-right: 10px;
        //padding-bottom: 33px;
        &-col {
            float: none;
            width: 100%;
        }
        &-mosaic {
            margin: 0 -8px 15px;
        }
        &-item {
            padding: 0 8px 13px;
            &-title {
                font-size: 1.8rem;
            }
            &-content {
                min-height: 75px;
                padding: 10px 5px;
            }
            &-large {
                width: 100%;
            }
            &-small {
                width: 50%;
                height: 100%;
                .actualites-block-item-title {
                    font-size: 1.4rem;
                }
                .actualites-block-item-content {
                    min-height: 60px;
                }
                &-wrap {
                    width: 100%;
                }
            }
        }
    }
}
