.language {
	float: left;
	&-opener {
		height: 49px;
		font-size: 1.3rem;
		line-height: 1.2;
		color: $color-lang;
		position: relative;
		padding: 0 15px 2px 21px;
		font-weight: 700;
		font-family: Lato, sans-serif;
		&:after {
			font-family: icons-default;
			font-size: 9px;
			line-height: 1;
			position:absolute;
			top:50%;
			left:8px;
			-webkit-transform: translate(0, -50%);
			-ms-transform: translate(0, -50%);
			transform: translate(0, -50%);
			content: '\e014';
            color: $color-picto-1;
		}
		&:hover,
		&:focus {
			background: $bg-lang;
		}
		&:focus {
			outline-color: $color-white;
			outline-offset: -1px;
		}
		span {
			display: none;
		}
	}
	&-slide {
		position:absolute;
		top:100%;
		left:0;
		background: $bg-lang;
		a {
			display: block;
			padding: 8px 10px;
			color: $color-white;
			text-decoration: none;
			font-size: 1.4rem;
			line-height: 1.2;
			&:hover,
			&:focus {
				color: $color-2--1;
			}
			&:focus {
				outline-color: $color-white;
				outline-offset: -1px;
			}
		}
	}
	&.active {
		.language-opener {
			background: $bg-lang;
		}
	}
}

.back-link + .language {
	position: absolute;
	right: 0;
	top: 0;
	float: none;
	margin: 0;
}
@include breakpoint(small only) {
.language {
	float: none;
	margin: 0 -20px;
	border-bottom: 1px solid $color-3--3;
	&-opener {
		height: 96px;
		width: 100%;
		color: $color-2--1;
		font-weight: 700;
		font-size: 1.8rem;
		text-transform: uppercase;
		span {
			display: inline;
		}
		&:after {
			display: none;
		}
		&:before {
			font-family: icons-default;
			content: '\e014';
			font-size: 15px;
			font-weight: 400;
			line-height: 1;
			display:inline-block;
			vertical-align:top;
			padding: 4px 5px 0 0;
		}
	}
	&-slide {
		position: static;
		padding-bottom: 10px;
		a {
			font-size: 1.8rem;
			text-transform: uppercase;
			font-weight: 400;
			text-align: center;
			padding: 12px 10px;
		}
	}
}
}
