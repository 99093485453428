@include breakpoint(small only) {
    .button-list {
        li {
            padding: 0 10px 11px;
        }
    }

    .button-menu {
        li {
            padding: 0 10px 12px;
        }
    }
}
