.last-update {
    background: url(#{$path-images}bg/bg-decor-01.png);
    padding-top: 43px;
    padding-bottom: 45px;
    &-list {
        margin: 0 -14px 36px;
        overflow: hidden;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        li {
            flex: 1 1 25%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: nowrap;
            padding: 0 14px 0 55px;
            font-size: 0;
            line-height: 0;
            background: url("#{$path-images}bg/update-ico.png") no-repeat 0 50%;
            min-height: 94px;
            a:hover, a:focus {
                color: $color-black;
                .last-update-title {
                    color: $color-3--4;
                }
                .last-update-text {
                    p {
                        color: $color-2--2;
                    }
                }
            }
        }
    }
    &-title {
        font-size: 1.3rem;
        line-height: 1.2;
        color: $color-2--2;
        font-weight: 700;
        text-transform: uppercase;
        display: block;
        vertical-align: middle;
    }
    p {
        color: $color-3--4;
        font-size: 1.4rem;
        line-height: 1.2;
        margin: 0;
        font-weight: 400;
    }
}

@include breakpoint(medium down) {
    .last-update {
        padding-top: 38px;
        &-list {
            li {
                margin-bottom: 35px;
            }
        }
    }
}

@include breakpoint(small only) {
.last-update {
	padding-top: 43px;
	padding-bottom: 37px;
	&-list {
		max-width: none;
		margin: 0 0 7px;
        display: block;
		li {
			margin-bottom: 22px;
		}
	}
}
}
