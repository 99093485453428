// @name site informations
.site-infos {
	float: left;
	width: 360px;
	color: $color-white;
	form {
		margin: 0 !important;
	}
	.site-infos__img-wrap {
		float: left;
		margin: -4px 51px -20px 0;
	}
	&__wrapper {
		font-style: normal;
	}
	p {
		font-size: 1.2rem;
		line-height: 1.2;
		margin: 0 0 12px;
		font-weight: 300;
		strong {
			font-size: 1.4rem;
			line-height: 1.2;
			display: block;
			font-weight: 700;
			margin: 0 0 2px;
		}
	}
	ul {
		margin: 0 0 19px;
		font-size: 1.2rem;
		line-height: 1.2;
		color: $color-white;
		font-weight: 300;
	}
	a {
		color: $color-white;
		&:focus {
			outline-color: $color-white;
			outline-offset: -1px;
		}
	}
}

@include breakpoint(medium down) {
    .site-infos {
        margin: 0 39px 0 0;
    }
}

@include breakpoint(small only) {
// @name site informations
.site-infos {
  float: none;
  width: 100%;
  margin: 0 0 33px;
  &:after{
  	content:"";
  	display:block;
  	clear:both;
  }
  .site-infos__wrapper {
	margin: -4px 0 0;
  }
  .site-infos__img-wrap {
  	overflow: hidden;
  	height: 148px;
  	margin: 0 15px 0 0;
  }
}
}
