.list-type-2--publications {
	.category {
		margin: 2px 0 0;
	}
	.list-type-2__picture--2{
		display: block;
		position: relative;
		img{
			position: absolute;
			bottom: 10px;
			left: 0;
			right: 0;
		}
	}
}

@include breakpoint(small only) {
    // @name Liste type 2 publications
    .list-type-2--publications {
        .list-type-2__listitems {
            .list-paginated__wrapper {
                margin: 0 -10px;
                width: auto;
                > * {
                    flex-basis: 50%;
                    margin: 0 0 20px;
                    padding: 0 10px;
                }
            }
            .list-type-2__picture--2{
                img{
                    max-height: 140px;
                }
            }
        }
    }

}
