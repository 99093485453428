// @name Videos
.content {
    .videos {
        margin: 6em 0 4.6em;
    }
    .video__title {
        $video__title__font-size: 1.6;
        font-family: $typo-2;
        text-align: right;
        font-weight: $normal;
        font-size: #{$video__title__font-size}em;
        color: $color-3--3;
        margin-bottom: em(1.5, $video__title__font-size);
    }
    .videos__item {
        position: relative;
        width: 100%;
        margin: 0 auto 13px;
        video {
            width: 100%;
        }
        .iframe,
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .iframe {
            &.cookies-denied {
                @include responsive-ratio(700, 373, 'after');
                align-items: center;
                background-color: #333;
                border: 0;
                border-radius: 0;
                cursor: pointer;
                display: flex;
                font-family: $typo-1;
                font-size: 1.4rem;
                justify-content: center;
                padding: 0;

                &::before {
                    content: none;
                }

                &::after {
                    @include relative();
                }

                span {
                    //@include absolute(50%, null, null, 50%);
                    background-color: #1b870b;
                    border-radius: 4px;
                    display: inline-block;
                    margin: 10px;
                    max-width: calc(100% - 20px);
                    padding: 5px;
                    position: absolute;
                    //transform: translate(-50%, -50%);
                    //white-space: nowrap;
                }
            }
        }
    }
    .videos__ratio {
        display: block;
        width: 100%;
        height: auto;
    }
    .ce-textpic {
        .videos,
        .videos__item {
            margin: 0;
        }
    }
}

// @name Videos for popin
// @description if only one, no margin
.popin {
    #content {
        .content,
        .setPos {
            &:only-of-type {
                .videos {
                    margin: 0;
                    .videos__item {
                        &:only-of-type {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}

@include breakpoint(small only) {
    // @name Videos
    .content {
        .videos {
            margin: 2em 0 2em;
        }
        .videos__item {
            margin: 0 auto 4px;
        }
    }
}
