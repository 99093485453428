@include breakpoint(small only) {
    .user-menu {
        position:absolute;
        top:0;
        left:0;
        z-index: 999;
        background: $color-3--5;
        width: 100%;
        -webkit-transform: translate(100%, 0);
        -ms-transform: translate(100%, 0);
        transform: translate(100%, 0);
        -webkit-transition:-webkit-transform 0.3s linear;
        transition:transform 0.3s linear;
        padding: 18px 18px 27px;
        &-opened {
            .user-menu {
                -webkit-transform: translate(0, 0);
                -ms-transform: translate(0, 0);
                transform: translate(0, 0);
                &-overlay {
                    display: block;
                }
            }
        }
        &-opener {
            float: right;
            background: $color-3--4;
            width: 36px;
            height: 36px;
            position: relative;
            display: block;
            span {
                position:absolute;
                top:54%;
                left:50%;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            }
        }
        .close {
            position:absolute;
            top:18px;
            right: 8px;
            width: 40px;
            height: 40px;
            border: none;
            background: none;
            text-indent:-9999px;
            overflow:hidden;
            z-index: 10;
            &:after,
            &:before {
                width: 2px;
                height: 21px;
                background: $color-white;
                position:absolute;
                top:50%;
                left:50%;
                -webkit-transform: translate(-50%, -50%) rotate(-45deg);
                -ms-transform: translate(-50%, -50%) rotate(-45deg);
                transform: translate(-50%, -50%) rotate(-45deg);
                content: '';
            }
            &:before {
                -webkit-transform: translate(-50%, -50%) rotate(45deg);
                -ms-transform: translate(-50%, -50%) rotate(45deg);
                transform: translate(-50%, -50%) rotate(45deg);
            }
        }
        .order-form {
            margin: 0;
            &-title {
                &-head {
                    padding-left: 36px;
                    margin: 0 30px 38px 0;
                    background: url(#{$path-images}pictos/ico-user2.svg) no-repeat 2px 50%;
                    background-size: 16px 16px;
                }
            }
        }
        &-overlay {
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 998;
            background: rgba(0,0,0,0.7);
            display: none;
        }
    }
}
