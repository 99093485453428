.social-network {
    $this: &;

    padding: 46px 0 20px;

    @include breakpoint(small only) {
        padding-top: 30px;
    }

    h2 {
        margin: 0 0 39px;
    }

    &__list {
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        list-style: none;
    }

    &__item {
        margin: 0 20px 20px;

        a {
            align-items: center;
            color: $color-black;
            display: flex;
            flex-direction: column;

            @include on-event() {
                text-decoration: none;

                #{$this}__title {
                    text-decoration: underline;
                }
            }
        }
    }

    &__icon {
        @include size(74px);
        align-items: center;
        background-color: #5b5b5b;
        border-radius: 50%;
        color: $color-white;
        display: flex;
        font-family: $font-awesome;
        font-size: 3.1rem;
        justify-content: center;

        &.-facebook {
            background-color: #3b5998;
        }

        &.-twitter {
            background-color: #00acee;
        }

        &.-youtube {
            background-color: #c4302b;
        }

        &.-instagram {
            background-image: linear-gradient(134deg, #fec35e 0%, #d42d76 46%, #4d61d3 100%);
        }

        &.-linkedin {
            background-color: #0077b5;
        }
    }

    &__title {
        font-size: 1.4rem;
        font-weight: 400;
        margin-top: 14px;
        text-align: center;
        text-transform: uppercase;
    }
}
