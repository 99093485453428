.add-nav {
    float: right;
    > ul {
        float: left;
        > li {
            float: left;
            margin: 0 0 0 2px;
            position: relative;
            &.active {
                > a {
                    background: $color-3--5;
                }
                .add-nav-drop {
                    display: block;
                }
            }
            > a {
                display: block;
                font-size: 1.3rem;
                line-height: 1.2;
                color: $color-text;
                font-weight: 700;
                text-transform: uppercase;
                padding: 16px 22px 16px 27px;
                position: relative;
                text-decoration: none;
                &:after {
                    color: $color-picto-1;
                    font-family: icons-default;
                    font-size: 8px;
                    line-height: 1;
                    position: absolute;
                    top: 50%;
                    left: 13px;
                    font-weight: 400;
                    -webkit-transform: translate(0, -50%);
                    -ms-transform: translate(0, -50%);
                    transform: translate(0, -50%);
                    content: '\e014';
                }
                &:hover,
                &:focus {
                    background: $color-3--5;
                }
                &.order-link {
                    color: $color-text-active;
                    background: $color-3--5;
                    &:after {
                        content: '\e024';
                    }
                    &-connecte {
                        color: $color-white;
                        text-transform: none;
                        font-weight: 700;
                        font-size: 1.3rem;
                        line-height: 1.2;
                        padding: 9px 40px 11px 69px;
                        min-width: 259px;
                        span {
                            display: block;
                            font-size: 1.1rem;
                            font-weight: 300;
                            text-transform: uppercase;
                        }
                        &:after {
                            content: '\e014';
                            color: $color-white;
                            left: auto;
                            right: 20px;
                        }
                        &:before {
                            background: url(#{$path-images}pictos/ico-user2.svg) no-repeat;
                            width: 16px;
                            height: 16px;
                            position: absolute;
                            top: 48%;
                            left: 27px;
                            -webkit-transform: translate(0, -50%);
                            -ms-transform: translate(0, -50%);
                            transform: translate(0, -50%);
                            content: '';
                        }
                    }
                }
                &:focus {
                    outline-offset: -1px;
                    outline-color: $color-white;
                }
            }
        }
    }
    &-drop {
        position: absolute;
        top: 100%;
        left: 0;
        background: $color-3--5;
        padding: 22px 23px;
        display: none;
        z-index: 10;
        width: 310px;
        overflow: hidden;
        box-shadow: 0 5px 8px -5px rgba(0, 0, 0, 0.6) inset;
        &:after,
        &:before {
            width: 60%;
            height: 20px;
            left: -10%;
            position: absolute;
            top: -24px;
            box-shadow: 0 6px 15px 0 $color-black;
            -webkit-transform: rotate(-5deg);
            -ms-transform: rotate(-5deg);
            transform: rotate(-5deg);
            content: '';
        }
        &:before {
            left: auto;
            right: -10%;
            -webkit-transform: rotate(5deg);
            -ms-transform: rotate(5deg);
            transform: rotate(5deg);
        }
        &-type-2 {
            width: 242px;
        }
        &-type-3 {
            width: 330px;
            padding: 47px 22px 22px;
        }
    }
    &-sublevel {
        li {
            margin: 0 0 5px;
        }
        a {
            position: relative;
            color: $color-white;
            padding: 0 0 0 12px;
            font-size: 1.6rem;
            line-height: 1.2;
            font-weight: 300;
            &:after {
                position: absolute;
                top: 7px;
                left: 0;
                border-left: 6px solid $color-2--2;
                border-top: 4px solid transparent;
                border-bottom: 4px solid transparent;
                content: '';
            }
            &:focus {
                outline-offset: -1px;
                outline-color: $color-white;
            }
        }
    }
}

@include breakpoint(medium down) {
    .add-nav {
        > ul {
            > li {
                > a {
                    &.order-link {
                        &-connecte {
                            padding: 9px 20px 11px 30px;
                            min-width: 0;
                            &:before {
                                left: 8px;
                            }
                            &:after {
                                right: 6px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include breakpoint(small only) {
    .add-nav {
        &-item {
            border-bottom: 1px solid $color-3--3;
            margin: 0 -20px;
            &.active {
                .add-nav-opener {
                    background: $color-white;
                    &:before {
                        display: none;
                    }
                    &:after {
                        background: $color-3--5;
                    }
                }
                .add-nav-title {
                    background: $color-3--5;
                }
            }
        }
        &-title {
            padding: 0 46px 0 0;
            position: relative;
            a {
                display: block;
                font-size: 1.6rem;
                line-height: 1.2;
                color: $color-white;
                font-weight: 700;
                padding: 35px 20px 35px 31px;
                position: relative;
                text-transform: uppercase;
                &:before {
                    font-family: icons-default;
                    content: '\e014';
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 1;
                    position: absolute;
                    top: 50%;
                    left: 16px;
                    font-weight: 700;
                    -webkit-transform: translate(0, -50%);
                    -ms-transform: translate(0, -50%);
                    transform: translate(0, -50%);
                    color: $color-2--1;
                }
            }
        }
        &-opener {
            width: 28px;
            height: 28px;
            position: absolute;
            top: 50%;
            right: 18px;
            -webkit-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            transform: translate(0, -50%);
            border: 1px solid $color-white;
            text-indent: -9999px;
            overflow: hidden;
            &:before,
            &:after {
                width: 1px;
                height: 11px;
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                background: $color-white;
                content: '';
            }
            &:after {
                width: 11px;
                height: 1px;
            }
        }
        &-drop {
            position: static;
            width: 100%;
            box-shadow: none;
            padding: 0 20px 25px;
            &:after,
            &:before {
                display: none;
            }
        }
        &-sublevel {
            li {
                margin: 0 0 10px;
            }
        }
    }
}
