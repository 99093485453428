.logo {
    float: left;
    margin: 26px 0 0;
    a {
        display: block;
    }
    img {
        max-width: 100%;
        height: auto;
    }
    @media (max-width: 1500px) {
        width: 200px;
        margin-top: 33px;
    }
    @media (max-width: 1380px) {
        //width: 200px;
        //margin-top: 33px;
        //img {
        //	display: block;
        //	width: 100%;
        //	height: auto;
        //}
    }
}

@include breakpoint(medium down) {
    .logo {
        width: 304px;
        margin-top: 26px;
        margin-bottom: 25px;
    }
}

@include breakpoint(small only) {
    .logo {
        margin: 4px 0 2px;
        width: 178px;
    }
}
