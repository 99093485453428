.partners-block {
	padding-top: 45px;
	padding-bottom: 80px;
}
@include breakpoint(small only) {
.partners-block {
	padding-top: 15px;
	padding-bottom: 30px;
}
}
