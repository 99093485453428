// @name List
.list {
    position: relative;
}

// @name List informations
.list__infos {
    margin: 0 0 3em 0;
    font-size: 1.6em;
    font-family: $typo-2;
    font-weight: $light;
    border-bottom: 1px solid $color-4--2;
    padding-bottom: em(2.5, 1.6);
    padding-right: em(4, 1.6);
    color: $color-3--3;
    &:first-of-type {
        margin: 0.6em 0 3em 0;
    }
}

// @name List RSS
.list__rss {
    position: absolute;
    float: right;
    position: static;
    width: 43px;
    height: 43px;
    margin: -12px 0 0 10px;
    svg {
        fill: $color-1--2;
    }
    &-heading {
        float: right;
        position: static;
        width: 42px;
        height: 42px;
        margin: 0 0 0 10px;
    }
}

// @name List letter
.list__title-1 {
    background: $color-1--2;
    color: $color-white;
    font-size: 2em;
    font-weight: $bold;
    padding: em(.5, 2) em(1, 2);
    margin-bottom: em(3, 2);
}

.button-list {
    text-align: center;
    font-size: 0;
    line-height: 0;
    li {
        display: inline-block;
        vertical-align: top;
        padding: 0 10px;
    }
}

.button-menu {
    margin: 0 -5px;
    text-align: center;
    font-size: 0;
    line-height: 0;
    li {
        display: inline-block;
        vertical-align: top;
        padding: 0 5px 10px;
        //width: 268px;
    }
    a {
        display: block;
    }
    &-social {
        margin: 0 -12px;
        li {
            width: 249px;
            padding: 0 12px 24px;
        }
    }
}
