// @name Main section heading
// @description Default css
$heading__h1__font-size: 4.5;
.heading {
    padding: 6em 0 5em;

    .heading__wrapper {
        @extend .wrapper-940;

        > *:last-child {
            margin-bottom: 0;
        }
    }

    &__wrapper-figure {
        flex-shrink: 0;
    }

    .heading__figure {
        margin-right: 3em;
        display: table;
    }

    // right image
    .heading__wrap {
        + .heading__wrapper-figure {
            .heading__figure {
                margin-right: 0;
                margin-left: 2em;
            }
        }
    }

    .heading__picture {
        display: block;
        margin-bottom: .5em;

        img {
            max-width: 100%;
            height: auto;
        }
    }

    .heading__caption {
        display: table-caption;
        caption-side: bottom;

        &:after {
            color: $color-1--2;
        }
    }

    .heading__wrapper-buttons {
        overflow: hidden;

        h1 {
            overflow: hidden;
        }

        .filters__header {
            float: right;

            .ddm__title {
                display: none;
            }

            .button-1 {
                float: left;
            }
        }
    }

    &__category {
        margin-bottom: 7px;
    }

    h1 {
        font-family: $typo-3;
        font-weight: $bold;
        font-size: #{$heading__h1__font-size}em;
        line-height: em(5.2, $heading__h1__font-size);
        color: $color-text-1;
    }

    h1 + .teaser-2 {
        margin-top: em(2.4, $teaser-2__font-size);
    }

    .subtitle {
        $category__font-size: 1.8;
        font-size: #{$category__font-size}em;
    }

    .subtitle + h1 {
        margin-top: em(1.2, $heading__h1__font-size);
    }

    &__author {
        font-size: 1.4rem;
    }

    &__buttons {
        margin-top: 5rem;

        @include breakpoint(small down) {
            margin-top: 3rem;
        }

        > * {
            margin-bottom: 2rem;
            margin-right: 2rem;

            &:last-child {
                margin-bottom: 0;
                margin-right: 0;
            }
        }
    }

    .status {
        $status__font-size: 1.5;
        background: $color-white;
        font-size: #{$status__font-size}em;
        margin-top: em(0.5, $status__font-size);
        display: inline-block;

        &.status--new {
            background: $color-1--2;
            color: $color-white;

            &:before {
                content: "\e093";
                color: $color-white;
            }
        }

        &.status--in-progress {
            background: $color-2--2;
            color: $color-white;

            &:before {
                content: "\e093";
                color: $color-white;
            }
        }
    }

    &__white {
        background: $color-white;
        padding: 2.6em 0 5.7em;

        + .section-main__wrapper {
            padding-top: 0;
        }
    }

    &__green {
        background: $bg-heading;
    }

    &__purpure {
        background: $color-1--2;

        .heading__wrapper {
            ul.list-content-2 {
                > li {
                    margin: .125em 0;

                    &:before, > a {
                        color: $color-white;
                    }
                }
            }

            .caption-text-right {
                float: left;
                color: $color-white;
            }

            h1 {
                width: 100%;
                color: $color-white;
                line-height: 0.75em;
            }
        }

        .heading__wrapper-figure {
            margin-top: 3em;
            width: 35% !important;
            float: left !important;

            .caption-text-right {
                margin-bottom: 21px;
            }
        }

        .go-so-far__wrapper {
            width: 65%;
            float: right;
        }
    }

    &__purple {
        background: $color-1--2;
        color: $color-white;
        padding-top: 4.4em;
        padding-bottom: 4.9em;

        h1 {
            color: $color-white;
            font-size: 3.4rem;

            + .teaser-2 {
                margin-top: 19px;
            }
        }

        .teaser-2 {
            font-size: 1.8rem;
            line-height: 1.2;
            font-style: italic;
            color: $color-white;
        }

        .heading__wrapper {
            .caption-text-right {
                color: $color-white;
            }
        }
    }

    &__text-wrap {
        overflow: hidden;
    }

    &__wrapper-figure-r {
        float: right;

        .heading__figure {
            margin: 5px 0 0 40px;
        }
    }

    &__list-btn {
        float: right;

        @include breakpoint(small down) {
            float: none;
        }
    }
}

// @name Heading single
// @dependency .heading
.heading--single {
    .heading__wrapper {
        display: flex;
        flex-wrap: nowrap;
    }

    .heading__media {
        padding-top: 4px;
        margin-bottom: 22px;

        .date, .media {
            font-size: 1.2rem;
        }

        .media {
            font-weight: $bold;
            border: none;

            & + .date {
                &:before {
                    content: " - ";
                    display: inline;
                    font-size: 1.2em;
                    color: $color-3--3;
                }
            }
        }
    }
}

// @name Heading single news
// @dependency .heading
.heading--single-news {
    .heading__picture {
        img {
            width: 280px;
            max-width: inherit;
            height: auto;
        }
    }
}

// @name Heading single event
// @dependency .heading
.heading--single-event {
    .heading__wrapper {
        display: block;
        flex-wrap: inherit;
    }

    .heading__figure {
        margin-right: 0;
    }

    .heading__wrapper-figure, .heading__wrapper-date, .heading__wrap {
        display: table-cell;
        vertical-align: top;
    }

    .heading__picture {
        img {
            width: 280px;
            max-width: inherit;
            height: auto;
        }
    }

    .heading__wrapper-date {
        width: 16em;
        border-right: 1px solid $color-4--2;
    }

    .heading__wrap {
        padding-left: 2em;
    }

    .heading__modify-button {
        border-color: $color-3--2;
        font-weight: 300;
        margin: 0 8px;
        padding: 25px;

        &::before {
            display: none;
        }

        @include breakpoint(small down) {
            margin: 8px 0;
        }
    }

    .heading__buttons {
        display: flex;
        justify-content: flex-end;

        .all-dates__item-dates {
            margin: 0 8px;
            width: auto;
        }

        @include breakpoint(small down) {
            flex-direction: column;
            align-items: center;
        }
    }
}

// @name Heading single publications
// @dependency .heading
.heading--single-publications, .heading--single-public-market {
    .heading__picture {
        img {
            width: 220px;
            max-width: inherit;
            height: auto;
        }
    }
}

// @name Heading single directory
// @dependency .heading
.heading--single-directory {
    margin-bottom: -80px;

    .list-infos {
        margin-top: 2em;
    }

    .list-infos__wrapper {
        .list-infos__listitems:first-child {
            flex: 1 1 0;
        }
    }

    .list-infos__item {
        padding-left: em(2, 1.6);
        font-size: 1.6em;
    }

    .heading__picture {
        img {
            max-width: none;
        }
    }
}

.heading__content {
    padding: 2.5em 0 5em;

    h1 {
        margin: 0 0 20px;
    }

    h1 + .teaser-2 {
        float: left;
        width: 61.7%;
        margin: 0 2% 0 0;
    }

    .heading__wrapper-figure {
        float: right;
        width: 32%;

        .heading__figure {
            margin-right: 0;
        }
    }

    &_wrapper_text {
        display: flex;
    }

    .heading__wrapper-figure {
        width: 46%;
        margin: 0 0 0 15px;
    }

    &_wrapper_text {
        display: flex;

        .heading__wrapper-figure {
            width: 46%;
        }

        .teaser-2 {
            width: 100%;
        }
    }
}

.heading-box {
    position: relative;

    $this: &;

    &__picture {

        img {
            display: block;
            width: 100%;
            height: auto;
        }

        & + #{$this}__title {
            position: absolute;
            bottom: 0;
            left: 0;
            margin-bottom: 0;
            background-color: rgba($color-4--1, .75);
            padding: 3rem 4rem;

            h1 {
                margin-bottom: 0;
            }
        }
    }

    &__title {
        position: static;
        width: 100%;
    }

}

.heading-box-content {
    .teaser-2 {
        font-family: $typo-1;
        font-size: 1.8rem;
        line-height: 2.6rem;
        font-weight: $normal;
    }
}

@include breakpoint(medium down) {
    // @name Main section heading
    // @description Default css
    .heading {
        .heading__figure {
            width: auto;
            margin-right: 2em;
        }

        // right image
        .heading__wrap + .heading__wrapper-figure {
            margin-top: 0;

            .heading__figure {
                margin-left: 2em;
            }
        }

        &--single-news {
            .heading__picture {
                img {
                    width: 100% !important;
                }
            }
        }

        .heading__wrapper-figure {
            padding-top: 15px;
        }

        .heading__wrapper-figure-r {
            padding-top: 0;

            .heading__figure {
                margin: 5px 0 0 30px;
            }
        }
    }
    .heading__content {
        h1 {
            margin: 0 0 12px;
        }

        h1 + .teaser-2 {
            width: 50%;
        }
    }

    // @name Heading single
    // @dependency .heading
    .heading--single {
        .heading__wrapper {
            flex-direction: row;
        }
    }

    // @name Heading single news
    // @dependency .heading
    .heading--single-news {
        .heading__picture {
            img {
                width: auto;
                max-width: 100%;
                height: auto;
            }
        }
    }

    // @name Heading single event
    // @dependency .heading
    .heading--single-event {
        .heading__wrapper-figure, .heading__wrapper-date, .heading__wrap {
            display: table-cell;
            vertical-align: top;
        }

        .heading__figure {
            margin-right: 0;
        }

        .heading__wrapper-date {
            width: 16em;
            display: table-cell;
            border-bottom: 0;
            border-right: 1px solid $color-4--2;
            margin-bottom: 0;

            .date-1, .hour-place {
                display: block;
                vertical-align: inherit;
                width: auto;
                padding-bottom: 0;
            }

            .date-1 {
                text-align: center;

                time:nth-of-type(1) {
                    margin-left: 1em;
                }
            }
        }

        .heading__wrap {
            padding-left: 2em;
        }
    }
}

@include breakpoint(small only) {
    // @name Main section heading
    // @description Default css
    .heading {
        min-height: 40px;
        padding: 2.6rem 0;

        .heading__wrapper {
            padding: 0 10px;

            &-buttons {
                padding: 0 55px 0 10px;
            }
        }

        &__category {
            margin: 0;
        }

        &-wrap {
            .ddm {
                position: static;
            }

            .ddm__button {
                overflow: hidden;
            }

            position: relative;
        }

        .heading__figure {
            width: 100%;
            float: none;
            margin-right: 0;
            margin-bottom: 2em;
        }

        // right image
        .heading__wrap + .heading__wrapper-figure {
            margin-top: 2em;

            .heading__figure {
                margin-left: 0;
            }
        }

        .heading__wrapper-figure {
            padding-top: 35px;

            &-full {
                width: 100%;
            }
        }

        h1 {
            font-size: 2.8rem;
            color: $color-black;
            line-height: 1.28;
            margin-bottom: 22px;
        }

        &__white {
            padding: 2.6em 0;
        }

        .heading__wrapper-figure-r {
            float: none;
            margin-bottom: 10px;
            padding: 0;

            .heading__figure {
                margin: 0;
            }
        }

        &__purple {
            background: $color-1--2;
            color: $color-white;
            padding-top: 3em;
            padding-bottom: 3em;

            h1 {
                color: $color-white;
                font-size: 2.8rem;
                margin-bottom: 10px;

                + .teaser-2 {
                    margin-top: 10px;
                }
            }
        }
    }

    // @name Heading single
    // @dependency .heading
    .heading--single {
        .heading__wrapper {
            flex-direction: column;
        }
    }

    // @name Heading single event
    // @dependency .heading
    .heading--single-event {
        padding: 4em 0 1em;

        .heading__wrapper-figure, .heading__wrapper-date, .heading__wrap {
            display: block;
            vertical-align: inherit;
        }

        .heading__picture {
            img {
                max-width: 100%;
                width: 100%;
            }
        }

        .heading__wrapper-date {
            width: 100%;
            border-right: 0;
            display: table;
            border-bottom: 1px solid $color-4--2;
            margin-bottom: 2em;

            .date-1, .hour-place {
                display: table-cell;
                vertical-align: middle;
                width: 50%;
                padding-bottom: 1em;
            }

            .date-1 {
                text-align: left;

                time:nth-of-type(1) {
                    margin-left: 0;
                }
            }
        }

        .heading__wrap {
            padding-left: 0;
        }

        .heading__wrapper-figure {
            padding-top: 0;
        }
    }

    // @name Heading single directory
    // @dependency .heading
    .heading--single-directory {
        .heading__wrapper-figure {
            display: none;
        }

    }

    .heading__content {
        padding: 1em 0 1em;

        h1 + .teaser-2 {
        }

        &_wrapper_text {
            display: block;

            .heading__wrapper-figure {
                width: 100%;
                text-align: left;
            }

            .caption {
                display: inline-block;
                vertical-align: middle;
                text-align: left;
            }
        }
    }

    .heading__title + .date-2 {
        margin-top: -18px;
    }

    .heading-box {

        $this: &;

        &__picture {

            & + #{$this}__title {
                padding: 1rem;

                h1 {
                    font-size: 2.2rem;
                }
            }
        }
    }
}
