// @name List infos
.list-infos {
    font-family: $typo-2;
}

.list-infos__wrapper {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    > * {
        flex: 1 1 0;
    }
    .list-infos__listitems:first-child {
        flex: 1.9 1 0;
    }
    .list-infos__listitems + .list-infos__listitems {
        border-left: 1px solid $color-3--3;
        padding-left: 5%;
        margin-left: 5%;
    }
}

.list-infos__item {
    $list-infos__item__font-size: 1.4;
    margin: .5em 0;
    font-size: #{$list-infos__item__font-size}em;
    color: $color-black;
    font-weight: $light;
    @include default-icons-absolute-before('\e053', em(1.4, $list-infos__item__font-size), $color-1--2, em(0.2, 1.4), inherit, inherit, 0);
    padding-left: em(2, $list-infos__item__font-size);
    a {
        text-decoration: none;
        color: $color-black;
        font-weight: $light;
        &:hover, &:focus {
            text-decoration: underline;
        }
    }
    &.list-infos__address {
        &:before {
            content: "\e053";
        }
    }
    &.list-infos__website {
        &:before {
            content: "\e089";
        }
    }
    &.list-infos__phone {
        a {
            text-decoration: none;
            &:hover, &:focus {
                text-decoration: underline;
            }
        }
        &:before {
            content: "\e02b";
        }
    }
    &.list-infos__fax {
        a {
            text-decoration: none;
            &:hover, &:focus {
                text-decoration: underline;
            }
        }
        &:before {
            content: "\e088";
        }
    }
    &.list-infos__email {
        &:before {
            content: "\e02a";
        }
    }
    &.list-infos__infos {
        &:before {
            content: "\e083";
        }
    }
    &.list-infos__hours {
        &:before {
            content: "\e05b";
        }
    }
    &.list-infos__contact-person {
        &:before {
            content: "\e02e";
        }
    }
}

@include breakpoint(small only) {
    // @name List infos
    .list-infos__wrapper {
        flex-direction: column;
        .list-infos__listitems:first-child {
            flex: 0 1 100%;
        }
        .list-infos__listitems + .list-infos__listitems {
            border-left: 0;
            border-top: 1px solid $color-3--3;
            padding-left: 0;
            margin-left: 0;
            padding-top: 5%;
            margin-top: 5%;
        }
    }
}
