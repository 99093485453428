// @name Cross menu
.footer {
    .menu-cross {
        width: calc(100% - #{606px});
    }
}

.menu-cross {
    text-transform: uppercase;
    overflow: hidden;
    float: left;
    text-align: center;
    padding: 0 10px;

    ul {
        display: inline-block;
        vertical-align: top;
        text-align: left;
        max-width: 350px;
    }

    li {
        vertical-align: top;
        display: inline-block;
        width: 49%;
        padding: 2px 0 7px;
        a,
        button {
            color: $color-white;
            font-family: $typo-1;
            font-size: 1.2rem;
            line-height: 1.2;
            font-weight: $light;
            text-transform: uppercase;
            position: relative;
            padding: 0 0 0 20px;
            &:after {
                position: absolute;
                top: 1px;
                left: 1px;
                color: $color-2--2;
                font-family: icons-project;
                font-size: 13px;
                line-height: 1;
                content: '\e008';
            }
            &:focus {
                outline-color: $color-white;
                outline-offset: -1px;
            }
        }
        &.active {
            a,
            button {
                text-decoration: underline;
            }
        }
    }
}

@include breakpoint(medium down) {
    .menu-cross {
        width: auto !important;
        float: none;
        padding: 0 0 50px;
    }
}

@include breakpoint(small only) {
    .menu-cross {
        width: 100% !important;
        float: none;
        padding-bottom: 48px;
        ul {
            display: block;
            max-width: none;
        }
    }
}
