.quicklinks {
    $this: &;

    background-color: rgba($color-1--2, 0.5);
    opacity: 1 !important;
    visibility: visible !important;

    &__wrapper {
        align-items: center;
        display: flex;
        justify-content: center;
        height: 100%;
        padding: 70px 30px;

        @include breakpoint(medium down) {
            padding: 35px 17px;
            width: 100%;
        }

        @include breakpoint(small down) {
            display: block;
            margin: 0 auto;
            max-width: 240px;
            padding: 15px 0;
            width: calc(100% - 150px);
        }
    }

    &__slider {
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 40px 10px;
        opacity: 1 !important;
        visibility: visible !important;

        @include breakpoint(medium down) {
            flex-wrap: nowrap;
            justify-content: space-between;
            gap: 0 12px;
        }

        @include breakpoint(small down) {
            gap: 0;
        }
    }

    &__slider-item {
        opacity: 1 !important;
        visibility: visible !important;
        position: static;

        @include breakpoint(medium down) {
            display: flex;
            justify-content: center;
        }

        @include breakpoint(small down) {
            padding: 0 6px;
            max-width: 120px;
        }
    }

    .cycle-carousel-wrap {
        top: 50% !important;
        transform: translateY(-50%);
    }

    .prevCaroussel3,
    .nextCaroussel3 {
        @include absolute(30px);

        button {
            color: $color-white;
        }
    }

    .prevCaroussel3 {
        left: 35px;
    }

    .nextCaroussel3 {
        right: 35px;
    }
}

.quicklinks-item {
    $this: &;

    @include size(80px);
    align-items: center;
    display: flex;
    flex-direction: column;

    @include breakpoint(medium down) {
        @include size(71px);
    }

    @include breakpoint(small down) {
        @include size(auto);
    }

    &__svg {
        @include size(36px);
        flex-shrink: 0;
        margin-bottom: 8px;

        @include breakpoint(small down) {
            @include size(32px);
        }

        svg {
            @include size(100%, auto);
            display: block;
            fill: $color-white;
        }
    }

    &__text {
        color: $color-white;
        display: -webkit-box;
        font-family: $typo-1;
        font-size: 1.2rem;
        font-weight: $normal;
        line-height: 1.25;
        overflow: hidden;
        margin: auto 0;
        text-align: center;
        text-overflow: ellipsis;
        white-space: normal;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;


        @include breakpoint(small down) {
            font-size: 1.4rem;
        }
    }

    &__link {
        color: inherit;
    }
}

