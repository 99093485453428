// @name Liste type 1
// @description Basic list
.list-type-1 {
    margin-bottom: 5em;
    @extend .clear-fix;
    .date-2 {
        color: $color-2--2;
        margin-bottom: 14px;
    }
}

.list-type-1__item {
    padding: 2em 0;
    @extend .clear-fix;
    &:first-child {
        padding-top: 0;
    }
    &:last-child {
        padding-bottom: 0;
    }
    .results-highlight {
        background: yellow;
    }
}

.list-type-1__picture {
    display: block;
    float: left;
    img {
        margin-right: 2em;
        max-width: 220px;
        height: auto;
    }
}

.list-type-1__wrapper {
    overflow: hidden;
}

.list-type-1__title {
    font-family: $typo-3;
    color: $color-black;
    font-size: 2em;
    font-weight: $bold;
    margin: 0 0 .8em;
    a {
        color: $color-black;
    }
}

@include breakpoint(medium down) {
    // @name Liste type 1
    // @description Basic list
    .list-type-1__item {
        padding: 2em 0;
    }
    .list-type-1__picture {
        float: left;
        margin-bottom: 0;
        img {
            max-width: 120px;
        }
    }

    .list-type-1__title {
        font-size: 1.6em;
    }
}

@include breakpoint(small only) {
    // @name Liste type 1
    // @description Basic list
    .list-type-1__item {
        padding: 2em 0;
    }

    .list-type-1__picture {
        float: none;
        margin-right: 0;
        margin-bottom: 2em;
        img {
            max-width: 100%;
        }
    }

    .list-type-1__title {
        font-size: 1.6em;
    }
}


