//@name Page content

// max width for content element
$content__max-width: ($smartphone/10) - $column-bloc__width - $column-bloc__padding-left;

// @name Title h2
$content-h2__font-size: 3.4;
.content h2, .h2 {
    font-family: $typo-3;
    font-size: #{$content-h2__font-size}em;
    line-height: 1em;
    font-weight: $bold;
    color: $color-title-2;
    margin: em(6, $content-h2__font-size) 0 em(3, $content-h2__font-size) 0;
    a {
        color: $color-title-2;
        &:hover, &:focus {
            color: $color-black;
        }
    }
}

// @name Title h3
$content-h3__font-size: 2.5;
.content h3, .h3 {
    font-family: $typo-3;
    font-size: #{$content-h3__font-size}em;
    line-height: 1em;
    font-weight: $bold;
    color: $color-title-3;
    margin: em(5, $content-h3__font-size) 0 em(1, $content-h3__font-size) 0;
    a {
        color: $color-title-3;
        &:hover, &:focus {
            color: $color-3--3;
        }
    }
}

// @name Title h4
$content-h4__font-size: 2;
.content h4, .h4 {
    font-family: $typo-3;
    font-size: #{$content-h4__font-size}em;
    line-height: 1em;
    color: $color-title-4;
    margin: em(4, $content-h4__font-size) 0 em(1.6, $content-h4__font-size) 0;
    a {
        color: $color-title-4;
        &:hover, &:focus {
            color: $color-3--3;
        }
    }
}

// @name Title h5
$content-h5__font-size: 1.7;
.content h5, .h5 {
    font-family: $typo-3;
    font-size: #{$content-h5__font-size}em;
    line-height: 1em;
    color: $color-3--3;
    margin: em(4, $content-h5__font-size) 0 em(1.8, $content-h5__font-size) 0;
    a {
        color: $color-3--3;
        &:hover, &:focus {
            color: $color-black;
        }
    }
}

// @name Title h6
$content-h6__font-size: 1.5;
.content h6, .h6 {
    font-family: $typo-3;
    font-size: #{$content-h6__font-size}em;
    line-height: 1em;
    color: $color-black;
    margin: em(2, $content-h6__font-size) 0 em(1, $content-h6__font-size) 0;
    a {
        color: $color-black;
        &:hover, &:focus {
            color: $color-3--3;
        }
    }
}

// Reset first element of the page
.section-main__content span.anchor:first-of-type + .colPos0 .ce-bodytext > *:first-child,
.section-main__content span.anchor:first-of-type + .colPos0 .blocHeader > *:first-child,
.section-main__content span.anchor:first-of-type + .colPos0 > *:first-child,
    // Single view
.heading--single + .section-main__wrapper .section-main__content > .rte > *:first-child {
    margin-top: 0;
}

$content-list__font-size: 1.4;
.content {
    // @name Link
    a {
        color: $color-picto-text;
        text-decoration: underline;
        font-weight: $bold;
        &:hover, &:focus {
            text-decoration: none;
        }
    }

    a[href="http://openlayers.org/"] {
        color: $color-black;
        font-weight: $normal;
    }

    //  @name External link
    a[href^="http://"]:not([href*="#{$domain}"]),
    a[href^="https://"]:not([href*="#{$domain}"]),
    a[href^="//"]:not([href*="#{$domain}"]) {
        @include default-icons-after('\e086', 0 0 0 5px, 8px, $color-black, middle);
    }

    // @name Paragraph
    $content-paragraph__font-size: 1.7;
    p {
        font-size: #{$content-paragraph__font-size}em;
        font-weight: $light;
        line-height: 1.37;
        margin: em(.5, $content-paragraph__font-size) 0 em(1, $content-paragraph__font-size) 0;
        sup {
            color: $color-1--2;
        }
    }

    // @name Content chapeau
    // @description Specific style for paragraph
    .chapeau {
        font-size: 1.4em;
        color: $color-3--3;
        a {
            color: $color-3--3;
        }
    }

    // @name Content encadre
    // @description Specific style for paragraph
    .encadre {
        $encadre__font-size: 1.6;
        padding: em(1.9, $encadre__font-size) em(3, $encadre__font-size);
        font-size: #{$encadre__font-size}em;
        line-height: 1.3;
        color: $color-3--3;
        font-weight: $normal;
        background: $color-white;
        border: 1px solid $color-3--2;
        margin: em(1.8, $encadre__font-size) 2.6em em(1.3, $encadre__font-size) 0.8em;
        @include default-icons-absolute-before('\e008', em(1, 1.4), $color-2--2, 50%, inherit, inherit, em(-.5, 1.4), 'icons-project');
        &:before {
            padding-top: em(0.5, 1.4);
            padding-bottom: em(0.5, 1.4);
            background: $color-white;
            transform: translateY(-50%);
        }
        a {
            color: $color-3--3;
            font-weight: $bold;
        }
    }

    .external {
        display: inline-block;
        vertical-align: top;
        position: relative;
        &:after {
            content: '\e06d';
            margin: 0 0 0 5px;
            font-size: 14px;
            color: $color-picto-text;
            vertical-align: middle;
            display: inline;
            line-height: 1;
            text-decoration: none;
            font-family: "icons-default";
            font-weight: $normal;
        }
    }

    // @name Blockquote
    $content-blockquote__font-size: 2;
    $content-blockquote-icon-1__font-size: 5.7;
    blockquote {
        font-size: #{$content-blockquote__font-size}em;
        @include default-icons-absolute-before('\e06f', em($content-blockquote-icon-1__font-size, $content-blockquote__font-size), $color-3--2, 38%, inherit, inherit, em(-9, $content-blockquote-icon-1__font-size));
        &:before {
            transform: translateY(-50%);
            font-style: normal;

        }
        padding: em(0.5, $content-blockquote__font-size) 0 em(0.5, $content-blockquote__font-size) em(1, $content-blockquote__font-size);
        font-weight: $light;
        font-style: italic;
        margin: em(2.5, $content-blockquote__font-size) 0 em(1.5, $content-blockquote__font-size) em(9, $content-blockquote__font-size);
        color: $color-3--3;
        &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: -1.5em;
            width: 1px;
            height: 70px;
            background: $color-3--1;
            transform: rotate(15deg) translateY(-50%);
            transform-origin: 50% 50%;
        }
        p {
            font-size: 1em;
            font-weight: $light;
            line-height: 1.2;
        }
    }

    ul.attribution-links {
        display: flex;
        align-items: center;

        > li {
            a {
                &::before,
                &::after {
                    display: none !important;
                }
            }
        }
    }

    // @name Unordered list
    ul:not(.attribution-links):not(.carousel-galerie__pager),
    .show-hide__content ul:not(.attribution-links) {
        margin: em(1.6, $content-list__font-size) 0 em(1.5, $content-list__font-size) em(2.5, $content-list__font-size);
        list-style: none;
        font-size: #{$content-list__font-size}em;
        color: $color-black;
        padding: 0;
        font-weight: $light;
        > li {
            @include default-icons-absolute-before('\e01b', em(1, $content-list__font-size), $color-1--2, em(.5, 1.4), inherit, inherit, 0);
            padding-left: em(1.5, $content-list__font-size);
            margin-bottom: 0.6em;
            button {
                font-size: 1em;
            }
            > ul { // for specificity selector
                margin: 0 0 em(1, $content-list__font-size) em(.8, $content-list__font-size);
                list-style: none;
                padding: em(.1, $content-list__font-size) 0 em(.1, $content-list__font-size) 0;
                font-size: 1em;
                > li {
                    @include default-icons-absolute-before('\e007', em(1, $content-list__font-size), $color-picto-text, em(.5, 1), inherit, inherit, 0);
                    padding-left: em(1.5, $content-list__font-size);
                    margin-bottom: 0;
                    color: $color-3--3;
                }
            }
            > ol:first-child { // for specificity selector
                margin: 0 0 em(1, $content-list__font-size) em(1.5, $content-list__font-size);
                padding: em(.1, $content-list__font-size) 0 em(.3, $content-list__font-size) 0;
                font-size: 1em;
                > li {
                    padding: 0;
                    &:before {
                        font-family: none;
                        content: none;
                    }
                }
            }
        }
    }

    // @name Ordered list
    ol, .show-hide__content ol {
        margin: em(1.6, $content-list__font-size) 0 em(1.5, $content-list__font-size) em(2.5, $content-list__font-size);
        counter-reset: item;
        list-style: none;
        font-size: #{$content-list__font-size}em;
        font-weight: $light;
        color: $color-black;
        > li {
            padding: 0;
            margin-bottom: 0.5em;
            &:before {
                content: counter(item) ". ";
                counter-increment: item;
                color: $color-1--2;
                font-weight: 700;
                margin: 0 3px 0 0;
            }
            > ol:first-child { // for specificity selector
                margin: 0.3em 0 em(1, $content-list__font-size) em(2.4, $content-list__font-size);
                padding: em(.1, $content-list__font-size) 0 em(.1, $content-list__font-size) 0;
                font-size: 1em;
                > li {
                    padding: 0;
                    margin-bottom: 0;
                    line-height: 1.2;
                    color: $color-3--3;
                    &:before {
                        color: $color-picto-text;
                    }
                }
            }
            > ul:first-child { // for specificity selector
                margin: 0 0 em(1, $content-list__font-size);
                list-style: none;
                padding: em(.1, $content-list__font-size) 0 em(.3, $content-list__font-size) 0;
                font-size: 1em;
                > li {
                    @include default-icons-absolute-before('\e06c', em(.25, $content-list__font-size), $color-black, em(.25, 0.25), inherit, inherit, 0);
                }
            }
        }
    }

    // @name Reset list
    // @description for reset list ul or ol in content
    .reset__list {
        margin: auto;
        padding: 0;
        list-style: none;
        font-size: 1em;
        > li {
            padding: 0;
            &:before {
                content: "";
                margin: auto;
            }
            > ol, > ul {
                padding: 0;
                > li {
                    padding: 0;
                    &:before {
                        content: "";
                        margin: auto;
                    }
                }
            }
        }
    }

    // @name Table
    .table-wrapper {
        margin: 3em 0;
    }
    .table-wrapper-inner {
        // max-width: #{$content__max-width}em;
        overflow-y: auto;
        width: 100%;
        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(0, 0, 0, .5);
            -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
        }
    }
    table {
        width: 100%;
        font-size: 1em;
        ul {
            font-size: 1em;
        }
        caption {
            $table__caption_font-size: 1.2;
            font-family: $typo-2;
            text-align: right;
            font-weight: $normal;
            font-size: #{$table__caption_font-size}em;
            color: $color-3--3;
            margin-bottom: em(0.9, $table__caption_font-size);
            &:after {
                content: '\e018';
                margin: 0 0 0 8px;
                font-size: 10px;
                color: $color-picto-text;
                vertical-align: middle;
                display: inline;
                line-height: 1;
                text-decoration: none;
                font-family: "icons-default";
                font-style: normal;
            }
        }
        th, td {
            padding: 12px 14px;
        }
        th {
            font-size: 1.3em;
            text-transform: uppercase;
            font-family: $typo-3;
            font-weight: $bold;
            color: $color-2--3;
            text-align: left;
        }
        thead th {
            font-size: 1.3em;
            background: $color-white;
            font-weight: $bold;
            color: $color-3--3;
            border-top: 1px solid $color-3--7;
            border-bottom: 4px solid $color-gallery-table-picto;
            &.th--blue {
                border-color: $color-2--1;
            }
            &:first-child {
                border-top: none;
                border-bottom: 4px solid $color-2--1;
            }
        }
        tbody th {
            text-align: right;
            color: $color-black;
        }
        tbody {
            th, td {
                border-bottom: 1px solid $color-3--2;
            }
        }
        td {
            font-size: 1.4em;
            color: $color-black;
            background: $color-white;
        }
    }

    // @name column 2
    .column--2, .column--3 {
        margin: 4em 0 2em;
        column-gap: 2em;
    }
    .column--2 {
        columns: 2;
    }
    .column--3 {
        columns: 3;
    }

    .title {
        text-transform: uppercase;
        font-size: 3em;
        color: $color-3--4;
        font-family: $typo-3;
        font-weight: $light;
    }
}

.downloads {
    margin: 0 0 64px;
    .downloads-block {
        padding: 32px 20px 26px;
        width: 100%;
        background: $color-white url("#{$path-images}ddm-content-bg-1.png") repeat 0 0;
    }
    .downloads-list {
        margin: 0;
        padding: 0 3% 0 3%;
        display: block;
        vertical-align: top;
        position: relative;
        &:after {
            content: "";
            position: absolute;
            width: 1px;
            height: 100%;
            background: #bfcac9;
            left: 50%;
            top: 0;
            -webkit-transform: translate(-50%, 0);
            -ms-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
        }
        li {
            position: relative;
            font-size: 1.6rem;
            line-height: 1.5;
            margin: 0 0 21px 0;
            display: inline-block;
            padding: 0 15% 0 0;
            width: 56%;
            &:before {
                display: none;
            }
            &:nth-child(2n) {
                padding: 0;
                width: 42%;
            }
            &:nth-last-child(2),
            &:last-child {
                margin: 0;
            }
            a {
                padding-left: 41px;
                display: block;
                text-decoration: none;
                color: $color-black;
                font-weight: 300;
                font-family: $typo-5;
                &:hover {
                    text-decoration: underline;
                }
                &:focus {
                    outline: 1px dashed $color-black;
                    outline-offset: -1px;
                }
            }
            img {
                position: absolute;
                top: 4px;
                left: 0;
            }
            span {
                font-size: 1.2rem;
            }
        }
    }
}

.catalog {
    .title {
        margin: 0 0 0.4em 0;
    }
    margin: 0 0 16px;
    overflow: hidden;
    &-block {
        padding: 22px 20px 16px;
        margin: 0 0 20px;
        background: $color-white url("#{$path-images}ddm-content-bg-1.png") repeat 0 0;
        &:after {
            content: "";
            display: table;
            clear: both;
            width: 100%;
        }
        .catalog-contacts-holder {
            position: relative;
            h4 {
                margin-bottom: 21px;
            }
            .col-2 {
                padding-left: 10px;
            }
        }
        .catalog-contacts {
            margin: 0;
            float: left;
            width: 50%;
            ul {
                margin: 0;
                padding: 5px 0 0 0;
                li {
                    padding-left: 0;
                    font-size: 1.4rem;
                    margin: 0 0 7px;
                    &:before {
                        display: none;
                    }
                    a {
                        color: $color-3--4;
                        text-decoration: none;
                        font-weight: $light;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                    span {
                        font-weight: $bold;
                        color: $color-3--4;
                    }
                    &:last-child {
                        margin: 0 0 16px;
                    }
                }
            }
        }

        .barType1 {
            width: 156px;
            float: right;
            padding-top: 0;
            a {
                span {
                    &:after {
                        color: $color-2--2;
                    }
                }
                &.view-site-tourisme {
                    background: $color-2--2;
                }
            }
        }
    }
    &-block-form {
        margin: 0 0 16px;
    }
    &-form {
        position: relative;
        overflow: visible;
        margin-bottom: 0;
        fieldset {
            margin: 0 !important;
            background: none !important;
            padding: 0 !important;
            position: static !important;
        }
        legend {
            margin-top: 0;
            margin-bottom: 20px;
            color: $color-black;
            font-size: 1.6rem;
            font-weight: 300;
            text-align: left;
            font-style: normal;
            line-height: 1;
            span {
                position: relative;
                top: 3px;
            }
        }
        &-col {
            display: inline-block;
            vertical-align: top;
            width: 42.9%;
            margin-right: 2.4%;
            margin-bottom: 0;
            input {
                &::-webkit-input-placeholder {
                    color: $color-black !important;
                    opacity: 1 !important;
                }

                &::-moz-placeholder {
                    color: $color-black !important;
                    opacity: 1 !important;
                }

                &:-ms-input-placeholder {
                    color: $color-black !important;
                    opacity: 1 !important;
                }
            }
        }
        .btn {
            width: 47px;
            height: 47px;
            background: $color-1--2;
            position: absolute;
            bottom: -2px;
            right: 0;
            padding: 0;
            margin: 0;
            border: none;
            font-size: 0;
            &:focus,
            &:hover {
                text-decoration: none;
            }
            &:before {
                content: '\e033';
                font-family: "icons-default";
                color: $color-white;
                font-size: 16px;
                margin: 0;
                position: absolute;
                top: 50% !important;
                left: 50% !important;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                -o-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            }
        }
        select {
            background: $color-white url("#{$path-images}form/select_right.png") no-repeat 100% 50%
        }
    }
}

@include breakpoint(medium down) {
    // max width for content element
    $content__max-width: ($tablet/10) - $column-bloc__width - $column-bloc__padding-left;

    .content {
        .encadre {
            margin: 1.125em 4em 0.8125em 0.8em;
        }
        .locate {
            margin-bottom: 4.4em;
        }
    }
}

@include breakpoint(small only) {
    .content {
        // @name Table
        .table-wrapper {
            position: relative;
            margin: 2em 0;
        }
        .table-wrapper-fade {
            position: absolute;
            right: 0;
            width: 30px;
            height: 100%;
            background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .8) 100%);
        }
        table {
            caption {
                text-align: left;
            }
            thead {
                th {
                    padding: 10px 14px;
                }
            }
            tbody {
                th {
                    padding: 8px 15px 8px 42px;
                }
                td {
                    min-width: 155px;
                }
            }
        }
        .encadre {
            margin: 1.5em 1em 1.1em 0.8em;
            padding: 0.4875em 1.975em 0.4875em 1.875em;
        }
        ul:not(.attribution-links) {
            margin: 0.5em 0 1.07143em 1.78571em;
        }
        blockquote {
            padding: 0.25em 2em 0.25em 0.5em;
            margin: 0.7em 0 0.75em 4.5em;
            &:before {
                top: 20%;
            }
            &:after {
                top: 22%;
            }
        }
        .locate {
            margin: 4.8em 0 4.4em;
            .sidebar-info {
                position: static;
                width: 100%;
                padding: 9% 24% 5% 25%;
            }
        }
    }
    .content h2, .h2 {
        font-size: 3rem;
        line-height: 1em;
        margin: 0 0 35px;
    }
    .content h3, .h3 {
        margin: 3em 0 0.7em 0;
    }
    .content h4, .h4 {
        margin: 1.4em 0 0.8em 0;
    }
    .downloads {
        margin: 0 0 50px;
        .downloads-block {
            padding: 23px 0 26px 30px;
        }
        .downloads-list {
            &:after {
                display: none;
            }
            li {
                width: 100%;
                padding: 0;
                margin: 0 0 20px;
                &:nth-child(2n) {
                    width: 100%;
                    padding: 0;
                }
                &:nth-last-child(2) {
                    margin: 0 0 20px;
                }
            }
        }
    }

    .catalog-block {
        padding: 17px 10px 16px 20px;
        &-form {
            padding: 13px 10px 16px;
            margin: 0 0 40px;
        }
        .catalog-form-col {
            width: 100%;
            margin: 0 0 20px;
        }
        .catalog-form .btn {
            position: relative;
            display: block;
            margin: 0 auto;
        }
        .catalog-contacts {
            width: 100%;
            ul {
                li {
                    &:last-child {
                        margin-bottom: 2px;
                    }
                }
            }
        }
        .catalog-contacts-holder .col-2 {
            padding-left: 0;
            ul {
                margin-bottom: 24px;
            }
        }
        .catalog-contacts-holder h4 {
            margin-bottom: 24px;
        }
        .barType1 {
            margin: 0 auto;
            padding-top: 20px;
        }
    }
}

