// @name Skip menu
// @description SKip menu for accessibility

.menu-skip {
  $menu-ship__font-size: 1.3;
  width: 100%;
  a {
    position: absolute;
    top: -200px;
    left: 0;
    transition: all 0.3s ease;
    color: color-contrast($color-3--2);
    font-size: #{$menu-ship__font-size}em;
    &:focus {
      top: 0;
      display: block;
      padding: em(1.5, $menu-ship__font-size);
      background: $color-3--2;
      width: 100%;
      z-index: 9999;
    }
  }
  .desktop {
    display: block;
  }
  .smartphone {
    display: none;
  }
}
@include breakpoint(small only) {
// @name Skip menu
// @description SKip menu for accessibility

.menu-skip {
  .desktop {
    display: none;
  }
  .smartphone {
    display: block;
  }
}
}
