// Focus
a, button, input[type="button"] {
    &:focus {
        &::after {
            outline: 2px solid currentColor !important;
            outline-offset: 3px;
        }
    }
}

// @name link-bloc
// @description Create an ergonomic link
.link-bloc__context {
    position: relative;

    .link-bloc {
        &:after {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            content: "";
            speak: none;
            z-index: 10;
        }

        &:focus {
            outline: none;

            &::after {
                outline: 2px solid currentColor !important;
                outline-offset: 3px;
            }
        }
    }
}

// @name Link view
// @description Link under feature (RSS of the feature + list of the feature)
.link-view {
    $link-view__item__font-size: 1.2;
    .button-1 {
        &:before {
            content: "\e027";
        }
    }
}

.column-bloc {
    .link-view {
        margin-top: 2em;
    }
}

.simple-link {
    position: relative;
    color: $color-3--4;
    font-weight: $normal;
    font-size: 1em;
    margin: 0 .7em;
    padding-left: 1.6em;
    &::before {
        content: '\e057';
        font-family: icons-default;
        position: absolute;
        top: 1px;
        left: 0;
        font-size: 14px;
        color: $color-2--2;
    }
    &--leave {
        &::before {
            content: '\e086';
        }
    }
    &--delete {
        &::before {
            content: '\e058';
        }
    }
}
