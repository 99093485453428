.page-heading {
	padding-top: 24px;
	padding-bottom: 10px;
	.decor-title {
		text-align: left;
	}
}

@include breakpoint(medium down) {
    .page-heading {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 2px;
        padding-bottom: 7px;
    }
}

@include breakpoint(small only) {
.page-heading {
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 51px;
}
}
