// @name Title 1
// @description Styling for title 1
$title--1__font-size: 4.4;
.title-1 {
    font-family: $typo-3;
    font-size: #{$title--1__font-size}em;
    line-height: 1em;
    font-weight: $normal;
    text-transform: uppercase;
    margin: em(3, $title--1__font-size) 0 em(2, $title--1__font-size);
}

// @name Title 2
// @description Styling for title 2
$title--2__font-size: 1.8;
.title-2 {
    font-family: $typo-3;
    font-size: #{$title--2__font-size}em;
    font-weight: $normal;
    text-transform: uppercase;
    margin: em(2.5, $title--2__font-size) 0 em(1.5, $title--2__font-size);
}

.decor-title {
    color: $color-3--4;
    font-size: 3.4rem;
    line-height: 1.1;
    font-weight: 300;
    margin: 0 0 35px;
    text-align: center;
    position: relative;
    text-transform: uppercase;
    &:before {
        display: inline-block;
        vertical-align: 2px;
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 1.2;
        color: $color-3--4;
        letter-spacing: 3px;
        margin: 0 4px 0 0;
        content: '//';
    }
    &-purple {
        &:before {
            color: $color-gallery-table-picto;
        }
    }
    &-blue {
        &:before {
            color: $color-2--2;
        }
    }
    &-green {
        &:before {
            color: $color-picto-2;
        }
    }
}

// @name Title 3
// @description Styling for title 3
$title--3__font-size: 2.5;
.title-3 {
    font-family: $typo-3;
    font-size: #{$title--3__font-size}em;
    line-height: 1em;
    font-weight: $light;
    text-transform: uppercase;
    color: $color-title-module;
    text-align: left;
    margin: 0 0 13px;
    padding: em(1, $title--3__font-size) 0 em(1, $title--3__font-size);
    &:before {
        font-size: 0.7em;
        margin: 0;
    }
}

// @name Title 4
// @description Styling for title 4
$title--4__font-size: 1.3;
.title-4 {
    font-family: $typo-3;
    font-size: #{$title--4__font-size}em;
    line-height: 1em;
    font-weight: $light;
    text-transform: uppercase;
    color: $color-3--4;
    text-align: left;
    margin: 0 0 13px;
    padding: em(1, $title--4__font-size) 0 em(1, $title--4__font-size);
    &:before {
        font-size: 0.7em;
        margin: 0;
    }
}

@include breakpoint(small only) {
    // @name Title 1
    // @description Styling for title 1
    .title-1 {
        $title--1__font-size: 3.2;
        font-size: #{$title--1__font-size}em;
        margin: em(2.5, $title--1__font-size) 0 em(1.5, $title--1__font-size);
    }

    .decor-title {
        font-size: 2.8rem;
        &:before {
            font-size: 2rem;
        }
    }
}
