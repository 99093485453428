.menu-table {
	&-list {
    	flex-wrap: wrap;
		margin: 0 -4px 14px;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
	}

	&-col {
		width: 20%;
		padding: 40px 4px 20px;

		&.empty {
			.menu-table-item {
				background: url(#{$path-images}bg/bg-empty.jpg);
				pointer-events: none;
			}
		}


		&-heading {
			padding-top: 0;
			min-height: 0;
			.menu-table-col-heading-title {
				font-size: 2.2rem;
				color: $color-3--4;
				font-weight: 700;
			}

			.menu-table-item {
				background: none;
				border-bottom: 2px solid $color-4--2;
				padding: 3px 5px;
				min-height: 0;

				&:after {
					display: none;
				}
			}
		}
	}

	&-item {
		display: block;
		position: relative;
		background: $color-1--2;
		color: $color-white;
		font-size: 1.4rem;
		text-align: center;
		white-space: nowrap;
		height: 100%;
		padding: 30px 0 15px;
		box-sizing: border-box;
		min-height: 186px;

		&:after {
			content: '';
			display: inline-block;
			vertical-align: middle;
			height: 100%;
		}

		a {
			color: $color-white;
		}

		ul {
			white-space: normal;
			display: inline-block;
			vertical-align: middle;
			padding: 10px 10px;

			li {
				line-height: 1.1;

				strong {
					text-decoration: underline;
				}
			}
		}
	}

	&-date {
		position: absolute;
		top: -39px;
		left: 50%;
		-webkit-transform: translateX(-50%);
		-ms-transform: translate(-50%);
		-o-transform: translate(-50%);
		transform: translate(-50%);
		width: 57px;
		height: 57px;
		background: $color-4--1;
		color: $color-black;
		font-size: 2.9rem;
		line-height: 57px;
		font-weight: 900;
	}
}

@include breakpoint(medium down) {
    .menu-table {
        &-item {
            font-size: 1.2rem;
        }
    }
}

@include breakpoint(small only) {
.menu-table {
	&-list {
		display: flex;
		flex-wrap: wrap;
	}

	&-col {
		width: 50%;
		padding: 38px 4px 8px;
		margin: 0 auto;

		&-heading {
			width: 100%;
			padding-bottom: 0;
			padding-top: 0;
			.menu-table-col-heading-title {
				font-size: 1.8rem;
			}
		}
	}

	&-date {
		top: -30px;
		width: 45px;
		height: 45px;
		font-size: 2rem;
		line-height: 45px;

	}
}
}
