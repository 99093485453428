// @name bloc savoir
// @name Bloc savoir in column
.column-bloc.bloc-savoir {
  .bloc-savoir__title{
    margin: 0 0 5px;
  }
  img {
    max-width: 100%;
    height: auto;
    margin-bottom: 2em;
  }
  .manage-list{
    margin: 0 0 10px;
    color: $color-3--3;
    li{
      padding: 14px 0 10px;
      margin: 0;
      border-top: 1px solid $color-3--7;
      font-size: 1.4em;
      font-weight: $light;
      a{
        color: $color-2--2;
        text-decoration: underline;
        font-weight: $bold;
        &:hover{
          text-decoration: none;
        }
      }
    }
  }
}
