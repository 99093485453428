// @name Search box
// @description Search module
.search-box {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	z-index: -1;
	form {
		margin: 0;
	}
	// Drop down menu
	.ddm {
		position: static;
		> .ddm__sub-level {
			position:absolute;
			top:0;
			left:0;
			right: -100px;
			height: 100%;
			padding: 0 102px 0 100px;
			background: $search-bg url(#{$path-images}bg/bg-decor-02.png) no-repeat 8px 0;
			background-size: 96px 100%;
		}
		&__title {
			position:absolute;
			top:0;
			right: 0;
			bottom: 0;
			margin: 0 0 0 -8px;
			border-left: 8px solid $search-bg;
			.ddm__button {
				padding: 62px 10px 15px;
				width: 100px;
				height: 100%;
				overflow: hidden;
				border: none;
				font-size: 1.1rem;
				line-height: 1.2;
				color: $color-white;
				text-transform: uppercase;
				position: relative;
				z-index: 1;
				background: $search-bg url(#{$path-images}bg/bg-decor-02.png) no-repeat 0 100%;
				background-size: 96px 100%;
				svg {
					position:absolute;
					top:25px;
					left:50%;
					margin: 0 0 0 -15px;
					width: 30px;
					height: 30px;
					fill: $color-icon;
				}
				&:focus {
					outline-offset: -1px;
					outline-color: $color-white;
				}
			}
		}
	}
	.ddm__sub-level {
		&-close {
			width: 40px;
			height: 40px;
			position:absolute;
			right: 17px;
			top: 8px;
			overflow: hidden;
			&:after,
			&:before {
				position:absolute;
				top:50%;
				left:50%;
				-webkit-transform: translate(-50%, -50%) rotate(-43deg);
				-ms-transform: translate(-50%, -50%) rotate(-43deg);
				transform: translate(-50%, -50%) rotate(-43deg);
				width: 1px;
				height: 23px;
				background: $color-white;
				content: '';
			}
			&:before {
				-webkit-transform: translate(-50%, -50%) rotate(43deg);
				-ms-transform: translate(-50%, -50%) rotate(43deg);
				transform: translate(-50%, -50%) rotate(43deg);
			}
			&:focus {
				outline-offset: -1px;
				outline-color: $color-white;
			}
		}
		&-icons {
			position:absolute;
			top:50%;
			left:37px;
			-webkit-transform: translate(0, -50%);
			-ms-transform: translate(0, -50%);
			transform: translate(0, -50%);
		}
	}
	&__fields-wrapper {
		/* overflow: hidden;
		margin: 32px 0 0; */
		position: absolute;
		top: 50%;
		right: 102px;
		left: 100px;
		transform: translateY(-50%);
		label {
			font-weight: 400;
			font-size: 2rem;
			line-height: 1.2;
			color: $color-white;
			float: left;
			text-transform: uppercase;
			margin: 4px 34px 0 0;
		}
	}
	&__field-wrapper {
		overflow: hidden;
		border-bottom: 1px solid $color-white;
		padding: 0 30px 0 0;
		position: relative;
		input {
			background: none;
			border: none;
			color: $color-1--1;
			font-size: 20px;
			padding: 0;
			height: 34px;
			width: 100%;
			&:focus {
				outline-offset: -1px;
				outline-color: $color-white;
			}
		}
		button {
			position:absolute;
			right: 0;
			bottom: 0;
			width: 20px;
			height: 34px;
			background: none !important;
			border: none;
			padding: 0;
			color: $color-white;
			&:after {
				position:absolute;
				top:50%;
				left:50%;
				-webkit-transform: translate(-50%, -50%);
				-ms-transform: translate(-50%, -50%);
				transform: translate(-50%, -50%);
				font-family: icons-default;
				font-size: 17px;
				line-height: 1;
				content: '\e057';
			}
			&:focus {
				outline-offset: -1px;
				outline-color: $color-white;
			}
		}
	}
	@media (min-width: 961px) and (max-width: 1380px) {
		.ddm__title .ddm__button {
			width: 50px;
			font-size: 0;
			line-height: 0;
			padding: 0;
			svg {
				position: static;
				margin: 0;
			}
		}
	}
}

.search-active {
	.search-box {
		z-index: 1;
	}
}

// @name Search list
.pertinence {
	font-size: 1.2em;
	span {
		font-size: em(1, 1.2);
	}
}

@media all and (-ms-high-contrast:none) {
	*::-ms-backdrop,
	.pertinence span {
		font-size: 1.1em;
		font-weight: $bold;
	}
}

meter {
	-webkit-appearance: meter;
	-moz-appearance: meterbar;
	width: em(6, 1.2);
	height: em(1, 1.2);
	border: 1px solid darken($color-3--1, 20%);
	&::-webkit-meter-bar {
		background: darken($color-3--1, 20%);
		height: 100%;
		width: 100%;
	}
	&::-webkit-meter-optimum-value {
		height: 100%;
		background: $color-1--2;
	}
	&:-moz-meter-optimum {
		background: darken($color-3--1, 20%);
		border: none;
	}
	&:-moz-meter-optimum::-moz-meter-bar {
		background: $color-1--2;
	}
	&:-moz-meter-sub-optimum::-moz-meter-bar {
		background: antiquewhite;
	}
	&:-moz-meter-sub-sub-optimum::-moz-meter-bar {
		background: steelblue;
	}
}

@media all and (-ms-high-contrast:none) {
	*::-ms-backdrop,
	meter {
		display: none;
	}
}

@include breakpoint(medium down) {
    .search-box {
        .ddm {
            > .ddm__sub-level {
                right: 0;
            }
        }
        &__fields-wrapper label {
            display: none;
        }
    }
}

@include breakpoint(small only) {
// @name Search box
// @description Search module
.search-box {
  position: static;
  width: auto;
  margin: 0 -20px;
  .search-box__fields-wrapper, .search-box__button-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  .ddm__title,
  .ddm__sub-level-close {
    display: none !important;
  }
  .search-box__fields-wrapper {
    width: 100%;
  }
  .search-box__button-wrapper {
    padding-left: 1em;
  }
  // Drop down menu
  .ddm {
      > .ddm__sub-level {
        position: relative;
        right: 0;
        height: auto;
        padding: 28px 20px 35px 80px;
        display: block;
        background-position: 0 0;
      }
  }
  .search-box__fields-wrapper {
    display: block;
    margin: 0;
    position: static;
    transform: none;
    button {
      &:after {
        font-weight: 400;
        font-size: 15px;
      }
    }
  }
  .search-box__fields-wrapper label {
    display: none;
  }
  .search-box__field-wrapper input {
    font-size: 15px;
  }
  .ddm__sub-level-icons {
    left: 26px;
  }
}
}
