.mini-site {
    padding: 42px 0 30px;
    h2 {
        margin: 0 0 40px;
    }
    @media (max-width: 1200px) {
        .button-menu {
            max-width: 536px;
            margin: 0 auto;
        }
    }
}

@include breakpoint(medium down) {
    .mini-site {
        padding-top: 26px;
        padding-bottom: 20px;
    }
}

@include breakpoint(small only) {
    .mini-site {
        padding-top: 17px;
        h2 {
            margin: 0 0 36px;
        }
    }
}
