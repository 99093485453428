$message-font-size: 1.4;

$message-default-color: #02648e;
$message-default-bg: #f6f6f6;

$message-error-color: #ca000a;
$message-error-bg: #fcdcde;

$message-success-color: #278417;
$message-success-bg: #edfceb;

.form-message {
    background-color: $message-default-bg;
    font-size: #{$message-font-size}rem;
    font-weight: $light;
    margin-bottom: 1.5rem;
    padding: 4rem 2rem 3.5rem 7rem;

    @include breakpoint(medium down) {
        padding: 3rem;
    }

    @include breakpoint(small down) {
        padding: 3rem 1rem 3rem 1.5rem;
    }

    h2 {
        color: $message-default-color;
        font-size: em(2.2, $message-font-size);
        margin: 0 0 1.1em;
        padding-left: 1.25em;
        position: relative;

        @include icon-before($fa-var-info-circle);

        &::before {
            color: currentColor;
            @include absolute(0, null, null, 0);
        }
    }

    ul {
        font-size: 1em;
        list-style-type: none;
        margin-left: 1rem;
        padding: 0;

        li {
            padding-left: 1em;
            position: relative;
            @include icon-before($fa-var-chevron-right);

            &::before {
                @include absolute(0, null, null, 0);
                font-size: 0.75em;
                line-height: em(1.1, 0.75);
            }
        }
    }

    &.-error {
        background-color: $message-error-bg;

        h2 {
            color: $message-error-color;

            &::before {
                content: fa-content($fa-var-exclamation-circle);
            }
        }
    }

    &.-success {
        background-color: $message-success-bg;

        h2 {
            color: $message-success-color;

            &::before {
                content: fa-content($fa-var-check-square);
            }
        }
    }

    &.-center {
        text-align: center;
    }
}
