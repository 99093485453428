.site-info {
    .title-3 {
        font-size: 2.3rem;
        padding: 2px 0;
        margin: 0 0 25px;
    }
    &-title {
        display: block;
        text-align: center;
        font-size: 1.4rem;
        line-height: 1.2;
        font-weight: 700;
        color: $color-black;
        margin: 0 0 11px;
    }
    p {
        font-size: 1.4rem;
        line-height: 1.4;
        color: $color-black;
        font-weight: 300;
        margin: 0 0 20px;
    }
    &-buttons {
        padding-top: 5px;
        li {
            margin: 0 0 5px;
            &:last-child {
                margin-top: 25px;
            }
        }
        button {
            display: block;
            width: 100%;
        }
        a {
            text-align: center;
            display: block;
            width: 100%;
            &:hover, &:active {
                text-decoration: none;
            }
        }
    }
}

@include breakpoint(medium down) {
    .site-info {
        padding: 0;
        width: 100%;
        float: none !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        text-align: center;
        .title-3 {
            text-align: center;
        }
    }
}
