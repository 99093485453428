@if variable-exists(import-google-font-2) {
  @import url($import-google-font-2);
}

// @name Signature stratis
.stratis {
	position:absolute;
	bottom: 0;
	right: 0;
	font-size: 1rem;
	line-height: 1.1;
	font-family: $typo-4;
	color: $color-white;
	text-transform: uppercase;
	span {
		display: none;
	}
	a {
		display: inline-block;
		padding: 4px 5px;
		color: $color-white;
		background: #c70000;
		text-decoration: none;
		font-weight: 700;
		padding: 4px 10px;
		margin: 0 0 0 5px;
		&:hover, &:focus {
			color: #c70000;
			background: $color-white;
		}
	}
}
