// @name bloc directory

// @name Bloc directory in column
.column-bloc.bloc-directory {
  img {
    max-width: 100%;
    height: auto;
    margin-bottom: 2em;
  }
  .bloc-directory__title {
    $bloc-directory__title__font-size: 1.6;
    font-family: $typo-3;
    font-weight: $bold;
    font-size: #{$bloc-directory__title__font-size}em;
    margin: 0 0 em(1.5, $bloc-directory__title__font-size);
    color: $color-black;
    a {
      color: $color-black;
      &:hover, &:focus {
        color: $color-1--2;
      }
    }
  }
  .list-infos {

  }
  .list-infos__wrapper {
    display: block;
    flex-wrap: inherit;
    justify-content: inherit;
    > * {
      flex: inherit;
    }
    .list-infos__listitems + .list-infos__listitems {
      border-left: 0;
      padding-left: 0;
      margin-left: 0;
    }
  }
  .button-1 {
    &:before {
      content: "\e041";
    }
  }
}