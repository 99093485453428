.my-blocks {
	padding-top: 46px;
	padding-bottom: 50px;
	&-wrap {
		overflow: hidden;
		padding: 10px 30px 0;
		display: flex;
		flex-wrap: wrap;
	}
	&-col {
		width: 33.33%;
		padding: 0 10px 19px;
	}
	&-item {
		height: 100%;
		border: 1px solid $color-3--7;
		padding: 15px 19px 9px;
	}
	&-title {
		color: $color-1--2;
		font-size: 2rem;
		line-height: 1.1;
		text-transform: uppercase;
		margin: 0 0 26px;
		font-weight: 700;
		display: block;
		text-align: center;
	}
	&-list {
		color: $color-3--4;
		font-size: 1.4rem;
		line-height: 1.2;
		padding: 4px 3px;
		margin: 0 0 14px;
		li {
			margin: 0 0 10px;
		}
		a {
			padding: 0 0 0 12px;
			position: relative;
			color: $color-3--4;
			&:after {
				border-left: 6px solid $color-3--4;
				border-top: 4px solid transparent;
				border-bottom: 4px solid transparent;
				position:absolute;
				top:5px;
				left:0;
				content: '';
			}
		}
	}
	.button-menu {
		margin: -7px 0 7px;
		li {
			padding-bottom: 8px;
			display: block;
			margin: 0 auto;
			max-width: 268px;
			width: 100%;
			.btn-fild {
				white-space: nowrap;
			}
		}
		&-social {
			li {
				max-width: 220px;
				padding-bottom: 2px;
			}
			.btn-fild {
				font-size: 1.4rem;
				padding: 13px 10px;
			}
		}
	}
	.last-update {
		&-list {
			margin: 0;
			display: table-cell;
			li {
				float: none;
				width: 100%;
				padding: 0;
				margin: 0 0 16px;
				&:last-child {
					margin-bottom: 0;
				}
				a{
					display: block;
					color: $color-black;
				}
			}
		}
		&-title {
			font-size: 1.6rem;
			width: 76px;
			padding-left: 35px;
		}
		&-sep {
			width: 28px;
			height: 65px;
			margin: 0 16px;
			&-ico {
				background: $color-white;
				padding: 2px 0;
				z-index: 3;
			}
		}
		&-text {
			width: calc(100% - #{137px});
			font-size: 1.2rem;
			line-height: 1.2;
			padding-left: 35px;
		}
	}
	.kiosk-block-description {
		float: none;
		width: auto;
		padding: 10px 0 33px;
		margin: 0 -5px;
		&-img {
			width: 38.7%;
			margin: 0 4.9% 0 0;
		}
		&-date {
			font-size: 1.8rem;
		}
		@media (max-width: 1200px) and (min-width: 961px) {
			&-img {
				float: none;
				width: 118px;
				margin: 0 auto 20px;
			}
		}
	}
	.button-list {
		margin: 0 -10px;
		li {
			padding: 0 10px 10px;
		}
		.btn-decor {
			padding: 17px 15px 16px;
		}
	}
	.content-box{
		a{
			display: block;
			color: $color-black;
		}
	}
	@media (max-width: 1200px) {
		&-wrap {
			padding: 0;
		}
	}
}
@include breakpoint(small only) {
.my-blocks {
	padding-top: 29px;
	padding-bottom: 21px;
	&-col {
		padding: 0;
		width: 100%;
		margin: 0 0 19px;
	}
	&-title {
		font-size: 1.8rem;
	}
	&-list {
		padding: 6px 0 0;
		margin: 0;
	}
	&-item {
		padding: 15px 10px 18px;
	}
	.button-menu {
		margin-bottom: 0;
	}
	.kiosk-block-description {
		padding-bottom: 17px;
		&-img {
			margin: 0 8px 0 0;
		}
		&-date {
			font-size: 1.4rem;
		}
	}
	.button-list {
		li {
			&:last-child {
				padding-bottom: 0;
			}
		}
	}
}
}
