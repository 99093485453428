.location-block {
	margin: -8px 5px 14px;
	&-map {
		margin: 0 0 27px;
		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}
}
@include breakpoint(small only) {
.location-block {
	margin: -8px 0 4px;
}
}
