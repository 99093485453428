.mes-favoris {
	margin-bottom: 66px;
	&-item {
		border-bottom: 1px solid $color-black;
		padding: 0 40px 12px 0;
		position: relative;
		font-size: 1.4rem;
		line-height: 1.2;
		margin: 0 0 43px;
	}
	&-path {
		font-size: 1.4rem;
		line-height: 1.2;
		color: $color-black;
		margin: 0 0 17px;
	}
	&-adresse {
		font-size: 1.4rem;
		line-height: 1.2;
		color: $color-black;
		margin: 0 0 17px;
		font-weight: 300;
		a {
			color: $color-black;
		}
	}
	&-title {
		font-size: 2.5rem;
		line-height: 1.2;
		font-weight: 700;
		color: $color-black;
		margin: 0 0 18px;
		a {
			color: $color-black;
		}
	}
	&-button {
		position:absolute;
		top:0;
		right: 0;
		li {
			margin: 0 0 14px;
		}
	}
	&-links {
		li {
			display: inline-block;
			vertical-align: middle;
			margin: 0 5px;
			&:first-child {
				margin-left: 0;
			}
		}
	}
}
@include breakpoint(small only) {
.mes-favoris {
	&-title {
		font-size: 2rem;
	}
	&-item {
		margin: 0 0 30px;
	}
}
}
