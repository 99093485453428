.content-box {
	&-img {
		margin: 0 0 15px;
		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}
	p {
		font-size: 1.6rem;
		line-height: 1.2;
		color: $color-3--4;
		margin: 0 0 10px;
		font-weight: 400;
	}
}
@include breakpoint(small only) {
.content-box {
	p {
		margin: 0;
	}
}
}
