.sg-title-2 {
  $sg-title-2__font-size: 3.5;
  font-size: #{$sg-title-2__font-size}em;
  color: $color-1--2;
  margin: em(4, $sg-title-2__font-size) 0 em(2, $sg-title-2__font-size);
}
.sg-title-3 {
  $sg-title-3__font-size: 2.9;
  font-size: #{$sg-title-3__font-size}em;
  color: $color-2--2;
  margin: em(3.5, $sg-title-3__font-size) 0 em(1.8, $sg-title-3__font-size);
}
.sg-title-4 {
  $sg-title-4__font-size: 2;
  font-size: #{$sg-title-4__font-size}em;
  color: $color-black;
  margin: em(3.2, $sg-title-4__font-size) 0 em(1.5, $sg-title-4__font-size);
}

.sg-code {
  margin: em(3, 1.4) 0 em(1, 1.4);
  padding: 15px 10px;
  background: $color-3--4;
  position: relative;
  display: block;
  white-space: pre;
  overflow: auto;
  max-width: 700px;
    @include breakpoint(small only) {
        white-space: normal;
    }
  &:before {
    position: absolute;
    top: 0;
    right: 0;
    padding: 2px 5px;
    background: $color-3--3;
    display: block;
    content: "Code";
    font-size: 1.2em;
    font-family: $typo-1;
    margin-bottom: 2px;
    color: $color-white;
    border-bottom-left-radius: 5px;
  }
  code {
    font-family: monospace;
    font-size: 1.4em;
    border-radius: 2px;
    color: $color-white;
    text-align: left;
  }
}

.sg-description {
  margin: em(1, 1.4) 0 em(.5, 1.4);
  background: darken($color-3--3, 10%);
  border-radius: 2px;
  padding: 10px;
  font-size: 1.4em;
  color: $color-white;
  &:before {
    display: block;
    content: "Description:";
    font-size: 1.2em;
    font-family: $typo-1;
    margin-bottom: 2px;
    color: $color-white;
    font-weight: $bold;
  }
  .sg-description__wrapper {

  }
}
