// @name Sitemap
.sitemap {
  margin: 2em 0 3em;
}
.sitemap__listitems {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 -1%;
  > * {
    flex: 0 1 31.3333%;
    margin: 0 1% 4em;
  }
}

.sitemap__header {
  border-bottom: 1px solid $color-3--3;
  margin-bottom: 1em;
}
.sitemap__title {
  font-size: 2em;
  font-weight: $bold;
  margin-bottom: em(1.5, 2);
  color: $color-black;
  a {
    color: $color-black;
    &:hover, &:focus {
      color: $color-1--2;
    }
  }
}
.sitemap__teaser {
  font-size: 1.4em;
  font-weight: $light;
  margin: em(.5, 1.4) 0 em(1, 1.4);
}

.sitemap__listitems2 {
  text-transform: uppercase;
  a {
    color: $color-black;
    &:hover, &:focus {
      color: $color-1--2;
    }
  }
}
.sitemap__item2 {
  @include default-icons-absolute-before('\e003', 1.2em, $color-1--2, em(.2, 1.4), inherit, inherit, 0);
  padding-left: 1.5em;
  margin: .5em 0;
  > a {
    font-size: 1.3em;
    font-weight: $light;
  }
  &-active{
    @include default-icons-absolute-before('\e004', 1.2em, $color-1--2, em(.2, 1.4), inherit, inherit, 0);
    a{
      font-weight: $bold;
    }
  }
}

.sitemap__listitems3 {
  margin-left: 1.5em;
  margin-bottom: 1.3em;
}
.sitemap__item3 {
  @include default-icons-absolute-before('\e003', 1.2em, $color-1--2, em(.2, 1.4), inherit, inherit, 0);
  padding-left: 1.5em;
  margin: .5em 0;
  > a {
    font-size: 1.2em;
    font-weight: $light;
  }
  &-active{
    @include default-icons-absolute-before('\e004', 1.2em, $color-1--2, em(.2, 1.4), inherit, inherit, 0);
    a{
      font-weight: $bold;
    }
  }
}

.sitemap__listitems4 {
  margin-left: 1.8em;
  margin-bottom: 1.3em;
}
.sitemap__item4 {
  @include default-icons-absolute-before('\e06c', 0.4em, $color-black, em(.55, .5), inherit, inherit, 0);
  padding-left: 1em;
  margin: .5em 0;
  > a {
    font-size: 1.1em;
    font-weight: $light;
  }
}

@include breakpoint(medium down) {
    // @name Sitemap
    .sitemap__listitems {
        flex-direction: row;
        > * {
            flex-basis: 31.3333%;
        }
    }
}

@include breakpoint(small only) {
// @name Sitemap
.sitemap__listitems {
  flex-direction: column;
  > * {
    flex: 0 1 100%;
  }
}
}
