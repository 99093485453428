.link-block {
	padding-top: 25px;
	padding-bottom: 60px;
	&-list {
		display: flex;
		margin: 0 -25px;
		font-size: 1.4rem;
		line-height: 1.2;
		font-weight: 700;
		color: $color-3--4;
		padding-top: 8px;
		ul {
			flex: 1;
			padding: 0 25px;
		}
		a {
			display:inline-block;
			vertical-align:top;
			padding: 0 0 0 10px;
			color: $color-3--4;
			position: relative;
			&:after {
				width: 6px;
				height: 6px;
				position:absolute;
				top:5px;
				left:0;
				background: $color-3--4;
				border-radius: 50%;
				content: '';
			}
		}
	}
}
@include breakpoint(small only) {
.link-block {
	padding-top: 10px;
	padding-bottom: 40px;
	&-list {
		flex-wrap: wrap;
		padding-top: 0;
		ul {
			flex: none;
			width: 100%;
			padding: 0 25px;
		}
		a {

		}
	}
}
}
