// @name All dates
.all-dates {
    margin-bottom: 3em;
    @extend .clear-fix;
}

$all-dates__title__font-size: 1.4;
.all-dates__title {
    font-family: $typo-2;
    font-size: #{$all-dates__title__font-size}em;
    font-weight: $light;
    text-transform: uppercase;
    white-space: nowrap;
    padding-top: 1.714em;
    margin-bottom: em(1, $all-dates__title__font-size);
}

.all-dates__title-wrapper {
    float: left;
    width: 13.5%;
    padding-top: 35px;
}

.all-dates__wrapper {
    float: right;
    width: 83%;
    padding-top: 30px;
    margin-left: 1%;
}

.all-dates__listitems {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 -2%;
}

.all-dates__item {
    margin: 0 2% 0;
    width: 28%;
    display: flex;
    align-items: center;
    position: relative;
    .date-1 {
        padding-right: 10px;
        border-right: 1px solid $color-4--2;
        margin-right: 20px;
    }
    &-dates {
        margin: 21px 0px 0px 47%;
        width: 32%;
    }
    .hour-place {
        text-align: left;
        color: $color-3--3;
        font-size: 1.6rem;
        font-weight: $light;
    }
    .hour {
        font-size: 1.6rem;
    }
    .place {
        font-size: 1.2rem;
        font-style: normal;
    }
    .button-1 {
        border-color: $color-3--2;
        font-weight: $light;
        padding: 25px 10px 25px 28px;
        white-space: normal;
        &:hover {
            background: $color-4--1;
            color: $color-black;
            border-color: $color-3--2;
            &:before {
                color: $color-black;
            }
        }
    }
    .ddm--all-dates {
        .ddm__sub-level {
            display: block;
            visibility: hidden;
            top: 100%;
            left: 0;
            right: 0;
            overflow: hidden;
            z-index: 2;

        }
        .ddm__wrapper {
            transform: translateY(-110%);
            transition: all ease .1s;
            background: $color-4--1;
            color: $color-black;
            padding: 1.5em;
        }
        &.ddm--active {
            .button-1 {
                background: $color-4--1;
                border-color: $color-4--1;
                color: $color-black;
                &:before {
                    color: $color-black;
                }
            }
            .ddm__sub-level {
                visibility: visible;
            }
            .ddm__wrapper {
                transform: translateY(0);
                transition: all ease .4s;
            }
        }
    }
    .add-to-calendar__title {
        font-family: $typo-2;
        font-size: 1.4em;
        color: $color-black;
        font-weight: $bold;
    }
    .add-to-calendar__item {
        font-size: 1.4em;
        color: color-contrast($search-bg);
        margin: em(.5, 1.4) 0;
        a {
            color: $color-black;
        }
    }
    .button-1:hover,
    .button-1:focus {
        background: none;
        color: $color-black;
        &:before {
            color: $search-bg;
        }
    }
}

@include breakpoint(medium down) {
    // @name All dates
    .all-dates__title {
        margin-bottom: em(1.5, $all-dates__title__font-size);
    }
    .all-dates__wrapper {
        width: 72%;
    }
    .all-dates__listitems {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding: 0;
    }
    .all-dates__item {
        width: 45%;
        &:last-child {
            justify-content: flex-start;
        }
    }

}

@include breakpoint(small only) {
    // @name All dates
    .all-dates__title {
        padding-top: 0;
    }
    .all-dates__title-wrapper {
        width: 100%;
        float: none;
        padding: 0 5px;
    }
    .all-dates__wrapper {
        width: 100%;
        float: none;
        padding-top: 0;
    }
    .all-dates__listitems {
        display: block;
        justify-content: inherit;
        flex-wrap: inherit;
        padding: 0;
    }
    .all-dates__item {
        width: 100%;
        &:last-child {
            justify-content: center;
        }
        &-dates {
            margin: 21px 0 0 0;
        }
    }

}
