.social-block {
	padding: 46px 0 20px;
	h2 {
		margin: 0 0 39px;
	}
}
@include breakpoint(small only) {
.social-block {
	padding-top: 30px;
}
}
