// # Global
$domain: "belfort.fr";

// ## Typography
$import-google-font-1: "https://fonts.googleapis.com/css?family=Lato:300,300i,400,400i,600,700,700i" !default;
$import-google-font-2: "https://fonts.googleapis.com/css?family=Oswald:300,700" !default;
$import-google-font-3: "https://fonts.googleapis.com/css?family=Kanit:300,400,700" !default;
$import-google-font-4: "https://fonts.googleapis.com/css?family=Open+Sans+Condensed:700" !default;


$typo-1: 'Lato', sans-serif !default;
$typo-2: $typo-1 !default;
$typo-3: $typo-1 !default;
$typo-4: 'Oswald', sans-serif !default;
$typo-5: 'Kanit', sans-serif !default;
$typo-6: 'Open Sans Condensed', sans-serif !default;


// ## color-1
$color-1--1: #cb95c1 !default;
$color-1--2: #7a1483 !default;
$color-1--3: #6c1074 !default;

// ## color-2
$color-2--1: #5ba0b7 !default;
$color-2--2: #2385a5 !default;
$color-2--3: #1d6d87 !default;
$color-2--4: #157493 !default;
$color-2--5: #1d6f8a !default;
$color-2--6: #003c4d !default;

// ## Color-3
$color-3--1: #f2f2f2 !default;
$color-3--2: #c5c5c5 !default;
$color-3--3: #767676 !default;
$color-3--4: #3c3c3c !default;
$color-3--5: #2d2d2d !default;
$color-3--6: #444f4f !default;
$color-3--7: #afafaf !default;
$color-3--8: #a18172 !default;

// ## color-4
$color-4--1: #acd64e !default;
$color-4--2: #c5df00 !default;
$color-4--3: #90b341 !default;
$color-4--4: #c1e07a !default;

$thin: 100;
$extralight: 200;
$light: 300;
$normal: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;
$black: 900;

// ## Path
$path-root: "../" !default;
$path-images: "#{$path-root}Images/";
$path-images-fancybox: "#{$path-root}Images/fancybox/";
$path-font: "#{$path-root}Fonts/";

$font-awesome: 'Font Awesome 6 Pro';
$font-awesome-brands: 'Font Awesome 6 Brands';
$fa-font-path: $path-font;

// ## Media queries
$smartphone: 767;
$tablet: 960;

// ## B&w
$color-black: #000 !default;
$color-white: #fff !default;
$color-red: #fe0000 !default;
$color-green: #278417 !default;
$color-pink: #f26382 !default;

// ## Alert & required
$alert-background: #fcdcde;
$alert-title: #ff000d;
$alert-color: $color-black;


$search-bg: $color-1--2 !default;
$color-icon: $color-white !default;
$bg-panel: $color-3--4 !default;
$color-picto-1: $color-2--1 !default;
$color-text: $color-white !default;
$color-text-active: $color-2--1 !default;
$bg-lang: $color-3--5 !default;
$color-lang: $color-2--1 !default;
$bg-title-banner: $color-1--2 !default;
$color-text-banner: $color-white !default;
$bg-heading: $color-4--4 !default;
$color-text-breadcrumb: $color-black !default;
$color-text-1: $color-black !default;
$color-title-2: $color-1--2 !default;
$color-title-3: $color-2--2 !default;
$color-title-4: $color-3--4 !default;
$color-picto-text: $color-2--2 !default;
$color-picto-2: $color-4--1 !default;
$color-gallery-table-picto: $color-1--2 !default;
$color-title-module: $color-3--4 !default;
$bg-ddm-btn: $color-white !default;
$color-ddm-btn: $color-black !default;
$bg-ddm-btn-active: $color-1--3 !default;
$color-ddm-btn-active: $color-white !default;

//$color-logo: $color-3--8 !default;
$color-actualite-bg: $color-white !default;
$bg-footer: $color-3--4  !default;
