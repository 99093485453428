// @name bloc news

// @name Bloc news in column
.column-bloc.bloc-news {
    .title-3 {
        margin: 0 0 5px;
    }
    img {
        max-width: 100%;
        height: auto;
        margin-bottom: 1em;
    }
    .bloc-news__listitems {
        background: $color-white url("#{$path-images}ddm-content-bg-1.png") repeat 0 0;
        text-align: center;
        padding: 0 0 3px;
    }
    .bloc-news__title {
        $bloc-news__title__font-size: 1.8;
        font-family: $typo-3;
        font-weight: $bold;
        font-size: #{$bloc-news__title__font-size}em;
        margin: 0 0 em(1.5, $bloc-news__title__font-size);
        color: $color-black;
        a {
            color: $color-black;
            &:hover, &:focus {
                color: $color-1--2;
            }
        }
    }
    .link-view {
        margin-top: 0.5em;
        a {
            padding: 16px 12px 16px 15px;
            margin: 0 0 0 6px;
            &:after {
                font-size: 11px;
            }
        }
    }
}

@include breakpoint(medium down) {
    // @name Bloc news in column
    .column-bloc.bloc-news {
        .link-view {
            a {
                font-size: 1.1rem;
            }
        }
    }
}
