.canteen {
	margin: 0 -5px;
	&-gallery {
		position: relative;
		padding: 0 45px;
		margin: -15px 0 0;
		&-wrap {
			max-width: 190px;
			margin: 38px auto 9px;
		}
		.item {
			.menu-table-item {
				padding-top: 25px;
				margin: 0 0 5px;
				min-height: 192px;
				ul {
					padding: 10px 12px;
					li {
						line-height: 1.15;
					}
				}
			}
		}
		.prevCaroussel3,
		.nextCaroussel3 {
			position:absolute;
			top:120px;
			left:18px;
		}
		.nextCaroussel3 {
			left: auto;
			right: 18px;
		}
	}
	.button-3 {
		font-size: 1.3rem;
		display: block;
		width: 100%;
	}
	p {
		color: $color-3--4;
		font-weight: 300;
		font-size: 1.2rem;
		line-height: 1.2;
		margin: 0;
		span {
			display:inline-block;
			vertical-align:top;
			margin: 0 10px 0 0;
		}
	}
}
@include breakpoint(small only) {
.canteen {
	margin: 0;
	&-gallery {
		.prevCaroussel3 {
			left: 9px;
		}
		.nextCaroussel3 {
			right: 9px;
		}
	}
	.menu-table {
		&-item {
			font-size: 1.4rem;
		}
		&-date {
			top: -39px;
			width: 57px;
			height: 57px;
			font-size: 2.9rem;
			line-height: 57px;
		}
	}
}
}
