.hero-video {
    position: relative;

    &__container {
        max-width: 2560px;
    }

    .hero-item {
        $this: &;

        max-height: 100vh;
        min-height: 460px;
        overflow: hidden;
        position: relative;

        @include breakpoint(medium down) {
            min-height: 440px;
        }

        @include breakpoint(small down) {
            max-height: 360px;
        }

        &__video {
            @include trs;
            @include size(100%);
            @include absolute(50%, null, null, 50%);
            transform: translate(-50%, -50%);
            overflow: hidden;

            @include on-event {
                .video-controls__action {
                    opacity: 1;
                }
            }

            &.js-is-playing {
                .video-controls__icon::before {
                    content: fa-content($fa-var-pause);
                }
            }
        }
    }

    video {
        @include object-fit;
        @include absolute(0, 0, 0, 0);
        @include size(100%);
        background-color: $color-black;
        height: 100%;
        margin: auto;
        position: relative;
    }

    .video-controls {
        @include absolute(50%, null, null, 50%);
        transform: translate(-50%, -50%);
        z-index: 1;

        @include breakpoint(small down) {
            bottom: 2rem;
            left: auto;
            right: 2rem;
            top: auto;
            transform: none;

            .has-quick-links & {
                bottom: 12rem;
            }
        }

        &__action {
            @include trs;
            @include size(5rem);
            background-color: $color-1--2;
            border: 0;
            border-radius: 50%;
            color: $color-white;
            cursor: pointer;
            display: block;
            font-size: 1.5rem;
            opacity: 0;
            padding: 0;

            @include breakpoint(small down) {
                @include size(4rem);
            }

            &:focus {
                opacity: 1;
            }
        }

        &__icon {
            @include icon-before($fa-var-play);
            pointer-events: none;
        }
    }
}
