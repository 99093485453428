// @name buttons

// @name button-1 (light)
// @state button-1--big - Big button-1
// @state button-1--small - Small button-1
// @state .button-1--no-icon - Button-1 without icon
// @state .button-1--svg - Button-1 with svg content
// @state .button-1--no-text-in-mobile - Indicates button-1 without content text in smartphone view

.button-column-2 > div {
    width: 50%;
    float: left;
}

$button-1-font-size: 1.4;
input.button-1, .content input.button-1 {
    padding-left: em(3.2, $button-1-font-size);
    &.button-1--small {
        padding-left: em(2.2, $button-1-font-size);
    }
    &.button-1--big {
        padding-left: em(3.2, $button-1-font-size);
    }
}

.button-1, .content .button-1 {
    font-family: $typo-2;
    display: inline-block;
    vertical-align: middle;
    transition: all ease .3s;
    text-decoration: none;
    font-weight: $normal;
    color: $color-black;
    text-transform: uppercase;
    background: $color-white;
    font-size: #{$button-1-font-size}em;
    line-height: 1em;
    height: auto;
    border: 1px solid $color-3--2;
    padding: em(1.15, $button-1-font-size) em(3.2, $button-1-font-size) em(1.25, $button-1-font-size) em(4.2, $button-1-font-size);
    @include default-icons-absolute-before('\e026', em(1.4, $button-1-font-size), $search-bg, 50%, inherit, inherit, em(2.2, 1.4));
    &:before {
        transform: translateY(-50%);
        transition: all ease .3s;
    }
    &[type=reset] {
        &:before {
            content: "\e002";
        }
    }
    &:hover, &:focus {
        text-decoration: none;
        background: $bg-ddm-btn-active;
        color: color-contrast($bg-ddm-btn-active);
        border-color: $search-bg;
        &:before {
            color: color-contrast($bg-ddm-btn-active);
        }
        svg {
            fill: color-contrast($bg-ddm-btn-active);
        }
    }
    &.filters__reset:before {
        left: 1.1em;
        font-size: 1.1rem;
    }
    // Big
    &.button-1--big {
        // Change font size reference
        $button-1-font-size: 1.9;
        font-size: #{$button-1-font-size}em;
        padding: em(1.9, $button-1-font-size) em(4, $button-1-font-size) em(1.9, $button-1-font-size) em(5, $button-1-font-size);
        &:before {
            left: em(2.4, $button-1-font-size);
        }
    }
    // Small
    &.button-1--small {
        // Change font size reference
        $button-1-font-size: 1.2;
        font-size: #{$button-1-font-size}em;
        padding: em(.7, $button-1-font-size) em(2.2, $button-1-font-size) em(.7, $button-1-font-size) em(3.2, $button-1-font-size);
        &:before {
            left: em(1.5, $button-1-font-size);
        }
    }
    // Very small
    &.button-1--very-small {
        // Change font size reference
        $button-1-font-size: 1;
        font-size: #{$button-1-font-size}em;
        padding: em(.7, $button-1-font-size) em(2.2, $button-1-font-size) em(.7, $button-1-font-size) em(3.2, $button-1-font-size);
        &:before {
            left: em(1.5, $button-1-font-size);
        }
    }
    // No icon
    &.button-1--no-icon {
        padding-left: em(2, $button-1-font-size);
        padding-right: em(2, $button-1-font-size);
        &:before {
            content: "";
            margin: 0;
        }
    }
    // No text
    &.button-1--no-text {
        text-align: left;
        text-indent: -9999px;
        white-space: nowrap;
        position: relative;
        padding: em(1.3, $button-1-font-size) em(1.9, $button-1-font-size);
        &:before {
            text-indent: 0;
            line-height: 0;
            display: block;
            top: 50%;
            left: 50%;
            transform: translate(-50%);
            margin: 0;
        }
    }
    // With svg
    &.button-1--svg {
        padding: em(1.2, $button-1-font-size) em(1.3, $button-1-font-size);
    }
    svg {
        fill: $color-2--2;
        width: em(1.2, $button-1-font-size);
        height: em(1.2, $button-1-font-size);
        vertical-align: middle;
    }
    // With calendar font-icon
    &.button-1--calendar {
        &:before {
            left: em(1, 1.4);
            content: "\e05c";
        }
    }
}

//  @name Extenral link for button-1
a.button-1[href^="http://"]:not([href*="#{$domain}"]),
a.button-1[href^="https://"]:not([href*="#{$domain}"]),
a.button-1[href^="//"]:not([href*="#{$domain}"]) {
    @include default-icons-after('\e086', 0 0 0 5px, 8px, $color-black, middle);
    &:after {
        transition: all ease .3s;
    }
    &:hover, &:focus {
        &:after {
            color: $color-white;
        }
    }
}

// @name button-2 (dark)
// @state button-2--big - Big button-2
// @state button-2--small - Small button-2
// @state .button-2--no-icon - Button-2 without icon
// @state .button-2--svg - Button-2 with svg content
// @state .button-2--no-text-in-mobile - Indicates button-2 without content text in smartphone view

$button-2-font-size: 1.4;
input.button-2, .content input.button-2 {
    padding-left: em(3.2, $button-2-font-size);
    &.button-2--small {
        padding-left: em(2.2, $button-1-font-size);
    }
    &.button-2--big {
        padding-left: em(3.2, $button-2-font-size);
    }
}

.button-2, .content .button-2 {
    font-family: $typo-2;
    display: inline-block;
    vertical-align: middle;
    transition: all ease .3s;
    text-decoration: none;
    font-weight: $bold;
    color: $color-white;
    text-transform: uppercase;
    //background: $color-1--2;
    background: $search-bg;
    font-size: #{$button-2-font-size}em;
    line-height: 1em;
    height: auto;
    border: 1px solid $search-bg;
    padding: 12px 19px 12px 37px;
    overflow: hidden;
    @include default-icons-absolute-before('\e057', em(1.4, $button-2-font-size), $color-white, 50%, inherit, inherit, em(2.2, 1.4));
    &:before {
        transform: translateY(-50%);
        transition: all ease .3s;
        font-weight: 400;
        font-size: 11px;
        left: em(2, 1.4);
    }
    &:hover, &:focus {
        text-decoration: none;
        background: $bg-ddm-btn-active;
        color: color-contrast($color-1--3);
        svg {
            fill: color-contrast($color-1--3);
        }
    }
    &.button-2-dark {
        background: $color-1--3;
    }
    // Big
    &.button-2--big {
        // Change font size reference
        $button-2-font-size: 1.9;
        font-size: #{$button-2-font-size}em;
        padding: em(1.9, $button-2-font-size) em(4, $button-2-font-size) em(1.9, $button-2-font-size) em(5, $button-2-font-size);
        &:before {
            left: em(2.4, $button-2-font-size);
            font-size: em(1.8, $button-2-font-size);
        }
    }
    // Small
    &.button-2--small {
        // Change font size reference
        $button-2-font-size: 1.2;
        font-size: #{$button-2-font-size}em;
        padding: em(.7, $button-2-font-size) em(2.2, $button-2-font-size) em(.7, $button-2-font-size) em(3.2, $button-2-font-size);
        &:before {
            left: em(1.5, $button-2-font-size);
        }
    }
    // No icon
    &.button-2--no-icon {
        padding-left: em(2, $button-2-font-size);
        padding-right: em(2, $button-2-font-size);
        &:before {
            content: "";
            margin: 0;
        }
    }
    // No text
    &.button-2--no-text {
        text-align: left;
        text-indent: -9999px;
        white-space: nowrap;
        position: relative;
        padding: em(1.3, $button-2-font-size) em(1.9, $button-2-font-size);
        &:before {
            text-indent: 0;
            line-height: 0;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%);
            margin: 0;
            font-size: em(1.4, $button-2-font-size);
        }
    }
    // With svg
    &.button-2--svg {
        padding: em(1.2, $button-2-font-size) em(1.3, $button-2-font-size);
    }
    svg {
        fill: $color-white;
        width: em(1.2, $button-2-font-size);
        height: em(1.2, $button-2-font-size);
        vertical-align: middle;
    }
    // With next icon
    &.button-2--next {
        &:before {
            content: "\e027";
        }
    }
    // With calendar font-icon
    &.button-2--calendar {
        &:before {
            left: em(1, 1.4);
            content: "\e05c";
        }
    }
}

//  @name Extenral link for button-2
a.button-2[href^="http://"]:not([href*="#{$domain}"]),
a.button-2[href^="https://"]:not([href*="#{$domain}"]),
a.button-2[href^="//"]:not([href*="#{$domain}"]) {
    @include default-icons-after('\e086', 0 0 0 5px, 8px, $color-white, middle);
    &:after {
        transition: all ease .3s;
    }
}

.btn-al-right {
    display: block;
    margin-left: auto !important;
}

.btn-al-center {
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
}

// @name Grey form
// @description Form with grey background
.form--3 {
    // @name button-1 (light)
    .button-1 {
        background: $color-white;
        //&:hover, &:focus {
        //    background: $color-1--3;
        //    text-decoration: none;
        //}
        &:hover, &:focus {
            text-decoration: none;
            background: $bg-ddm-btn-active;
            color: color-contrast($color-1--3);
            svg {
                fill: color-contrast($color-1--3);
            }
        }
    }
    // @name button-1 (light)
    .button-1 {
        background: $color-white;
        //&:hover, &:focus {
        //    background: $color-1--3;
        //    text-decoration: none;
        //}
        &:hover, &:focus {
            text-decoration: none;
            background: $bg-ddm-btn-active;
            color: color-contrast($color-1--3);
            svg {
                fill: color-contrast($color-1--3);
            }
        }
    }
}

.button-3 {
    display: inline-block;
    vertical-align: top;
    padding: 12px 17px 13px 14px;
    background: $color-1--2;
    color: $color-white;
    border-left: 5px solid $color-1--3;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.2;
    text-transform: uppercase;
    margin: 0 0 2px;
    transition: all ease .3s;
    &.button-3--2 {
        background: $color-4--2;
        color: $color-black;
        border-left-color: $color-4--2;
        &:hover, &:focus {
            color: $color-white;
            border-left-color: $color-1--2;
        }
    }
    &.button-3--3 {
        background: $color-1--1;
        color: $color-black;
        border-left-color: $color-1--1;
        &:hover, &:focus {
            color: $color-white;
            border-left-color: $color-1--2;
        }
    }
    span {
        font-size: 1rem;
        font-weight: 400;
    }
    &:before {
        display: inline-block;
        vertical-align: -1px;
        font-size: 10px;
        line-height: 1;
        font-family: icons-default;
        margin: 0 3px 0 0;
        font-weight: 400;
    }
    &:hover,
    &:focus {
        background: $color-1--3;
    }
    &-download {
        &:before {
            content: '\e063';
        }
    }
    &-archive {
        background: $color-2--2;
        border-color: $color-2--4;
        &:before {
            content: '\e06e';
            font-size: 12px;
        }
        &:hover,
        &:focus {
            background: $color-2--4;
        }
    }
    &-location {
        white-space: normal;
        &:before {
            content: '\e04d';
        }
    }
    &-phone {
        &:before {
            content: '\e08f';
            font-size: 13px;
            margin-left: -3px;
        }
    }
    &-blue {
        background: $color-2--2;
        border-color: $color-2--4;
        &:hover,
        &:focus {
            background: $color-2--4;
        }
    }
    &-medium {
        font-size: 1.3rem;
        padding: 12px 24px 13px;
        &:before {
            margin-right: 10px;
        }
    }
}

.button-4 {
    display: inline-block;
    vertical-align: top;
    color: $color-white;
    font-size: 1.3rem;
    line-height: 1.2;
    font-weight: 700;
    text-transform: uppercase;
    background: $color-1--2;
    border-left: 5px solid $color-1--3;
    padding: 12px 10px;
    &:hover,
    &:focus {
        background: $color-1--3;
    }
    &-blue {
        background: $color-2--2;
        border-left-color: $color-2--3;
        color: $color-black;
        &:hover,
        &:focus {
            background: $color-2--3;
        }
    }
}

.btn-decor {
    color: $color-3--3;
    display: inline-block;
    vertical-align: top;
    font-size: 1.2rem;
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: 700;
    border: 1px solid $color-3--7;
    padding: 17px 22px 16px;
    position: relative;
    &:after {
        position: absolute;
        top: 50%;
        left: -7px;
        color: $color-gallery-table-picto;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        font-family: icons-project;
        font-size: 13px;
        line-height: 1;
        content: '\e008';
    }
    &:before {
        position: absolute;
        top: 50%;
        left: -10px;
        width: 21px;
        height: 21px;
        background: $color-white;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        content: '';
    }
    &-green {
        &:after {
            color: $color-picto-2;
        }
    }
    &-blue {
        &:after {
            color: $color-2--2;
        }
    }
    &-white {
        color: $color-white;
        border-color: $color-white;
        &:before {
            background: $color-3--4;
        }
        &:after {
            color: $color-white;
        }
    }
}

.btn-fild {
    border: 7px solid $color-2--2;
    background: $color-2--3;
    font-size: 1.6rem;
    line-height: 1.2;
    display: inline-block;
    vertical-align: top;
    font-weight: 300;
    text-transform: uppercase;
    text-align: center;
    color: $color-white;
    padding: 17px 16px;
    &-ico {
        display: inline-block;
        vertical-align: middle;
        padding: 1px 30px 0 0;
        position: relative;
        height: 20px;
        width: 50px;
        &:after {
            position: absolute;
            top: 50%;
            right: 13px;
            -webkit-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            transform: translate(0, -50%);
            width: 1px;
            height: 16px;
            background: $color-2--2;
            content: '';
        }
    }
    &:hover,
    &:focus {
        text-decoration: none;
        .btn-fild-text {
            text-decoration: underline;
        }
    }
    &-text {
        display: inline-block;
        vertical-align: middle;
        padding-bottom: 1px;
    }
    &-fb {
        border-color: #4866a3;
        background: #3b5998;
        &:hover {
            color: #3b5998;
            svg {
                fill: #3b5998;
            }
            .btn-fild-ico {
                &:after {
                    background: rgba(59, 89, 152, 0.2);
                }
            }
        }
    }
    &-tw {
        border-color: #2ebcf3;
        background: #00acee;
        &:hover {
            color: #00acee;
            svg {
                fill: #00acee;
            }
            .btn-fild-ico {
                &:after {
                    background: rgba(0, 172, 238, 0.2);
                }
            }
        }
    }
    &-yt {
        border-color: #d83f3a;
        background: #c4302b;
        &:hover {
            color: #c4302b;
            svg {
                fill: #c4302b;
            }
            .btn-fild-ico {
                &:after {
                    background: rgba(196, 48, 43, 0.2);
                }
            }
        }
    }
    &-inst {
        border-color: #c7659f;
        background: #bf3280;
        &:hover {
            color: #c7659f;
            svg {
                fill: #c7659f;
            }
            .btn-fild-ico {
                &:after {
                    background: rgba(50, 92, 134, 0.2);
                }
            }
        }
    }
    &-news {
        border-color: $color-3--3;
        background: $color-3--4;
        &:hover {
            color: $color-3--3;
            svg {
                fill: $color-3--3;
            }
            .btn-fild-ico {
                &:after {
                    background: rgba($color-3--4, 0.2);
                }
            }
        }
    }
    &-fb,
    &-tw,
    &-yt,
    &-inst,
    &-news {
        color: $color-white;
        svg {
            fill: $color-white;
        }
        &:hover {
            background: none;
        }
        .btn-fild-ico {
            &:after {
                background: rgba($color-white, 0.2);
            }
        }
    }
    &-minisite {
        .ico-home {
            background-image: url("#{$path-images}pictos/ico-home.png");
            background-repeat: no-repeat;
        }
        .ico-sport {
            background-image: url("#{$path-images}pictos/ico-sport.png");
            background-repeat: no-repeat;
        }
        .ico-word {
            background-image: url("#{$path-images}pictos/ico-word.png");
            background-repeat: no-repeat;
        }
        .ico-ccas {
            background-image: url("#{$path-images}pictos/ico-ccas.png");
            background-repeat: no-repeat;
        }
        &:hover {
            background: none;
            color: $color-2--3;
            .btn-fild-ico {
                &:after {
                    background: rgba($color-2--3, 0.2);
                }
            }
            .ico-home {
                background-image: url("#{$path-images}pictos/ico-home-hover.png");
            }
            .ico-sport {
                background-image: url("#{$path-images}pictos/ico-sport-hover.png");
            }
            .ico-word {
                background-image: url("#{$path-images}pictos/ico-word-hover.png");
            }
            .ico-ccas {
                background-image: url("#{$path-images}pictos/ico-ccas-hover.png");
            }
        }
    }
}

.content .btn-view-news {
    display: block;
    text-align: center;
    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
    }
}

.content .btn-view-site {
    display: block;
    background: $color-1--2;
    text-align: center;
    padding: 12px 10px 12px 27px;
    text-transform: uppercase;
    color: $color-white;
    text-decoration: none;
    font-size: 1.3em;
    font-weight: $bold;
    margin: 0 0 15px;
    position: relative;
    span, .external {
        &:after {
            content: '';
            margin: 0;
        }
    }
    &:after {
        content: '' !important;
    }
    &:hover {
        span {
            text-decoration: underline;
        }
    }
    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 5px;
        background: $color-1--3;
    }
    span {
        position: relative;
        display: inline-block;
        &:before {
            content: "\e034";
            position: absolute;
            left: -19px;
            top: 50%;
            transform: translate(0, -50%);
            font-family: "icons-default";
            font-size: 1em;
            color: $color-white;
            font-weight: $normal;
        }
    }
}

.content .btn-contact {
    display: block;
    background: $color-2--2;
    text-align: center;
    padding: 12px 10px 12px 27px;
    text-transform: uppercase;
    color: $color-white;
    text-decoration: none;
    font-size: 1.3em;
    font-weight: $bold;
    margin: 0 0 15px;
    position: relative;
    &:hover {
        span {
            text-decoration: underline;
        }
    }
    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 5px;
        background: $color-2--4;
    }
    span {
        position: relative;
        display: inline-block;
        &:before {
            content: "\e02a";
            position: absolute;
            left: -19px;
            top: 50%;
            transform: translate(0, -50%);
            font-family: "icons-default";
            font-size: 1em;
            font-weight: $normal;
            color: $color-white;
            font-weight: $normal;
        }
    }
}

.btn-loaded {
    max-width: 940px;
    width: 100%;
    display: block;
    margin: 0 auto;
    border: 1px solid $color-3--7;
    color: $color-3--3;
    font-size: 1.3rem;
    line-height: 1.2;
    background: $color-white;
    position: relative;
    text-transform: uppercase;
    padding: 12px 10px 10px;
    &:before {
        font-family: icons-default;
        content: '\e05f';
        display: inline-block;
        vertical-align: middle;
        font-size: 13px;
        line-height: 1;
        padding: 0 5px 2px 0;
    }
    &:hover,
    &:focus {
        text-decoration: underline;
    }
}

.btn-top,
.btn-bottom,
.btn-left,
.btn-right {
    position: relative;
    width: 23px;
    height: 23px;
    border: none;
    background: none;
    font-size: 0;
    line-height: 0;
    &:after {
        font-family: icons-default;
        font-size: 26px;
        line-height: 1;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        color: $color-3--2;
        content: '\e019';
    }
}

.btn-bottom {
    &:after {
        content: '\e018';
    }
}

.btn-left {
    &:after {
        content: '\e01a';
    }
}

.btn-right {
    &:after {
        content: '\e01b';
    }
}

.btn-delete,
.btn-plus {
    position: relative;
    width: 23px;
    height: 23px;
    border: none;
    background: none;
    font-size: 0;
    line-height: 0;
    span {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
}

.button-search {
    background: $color-1--2;
    width: 40px;
    height: 40px;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    position: relative;
    &:after {
        font-family: icons-default;
        font-size: 15px;
        line-height: 1;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        color: $color-white;
        font-weight: 400;
        content: '\e033';
    }
}

.button-twocolumns {
    margin: 0 -1px;
    &.is-full-width {
        .button-twocolumns-col {
            width: auto;
        }
    }
    &:after {
        content: "";
        display: block;
        clear: both;
    }
    &-col {
        width: 50%;
        float: left;
        padding: 0 1px;
    }
    a {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        &.button-2,
        &.button-1 {
            font-size: 1.4rem;
            line-height: 1.2;
            padding: 11px 5px 10px 27px;
        }
    }
}

@include breakpoint(small only) {
    // @name button-1 (light) and button-2 (normal)
    // @state .button-1--svg - Indicates the of SVG
    // @state .button-1--no-text-in-mobile - Indicates button-1 without content text in smartphone view
    // @state .button-2--svg - Indicates the of SVG
    // @state .button-2--no-text-in-mobile - Indicates button-2 without content text in smartphone view
    .button-1, .content .button-1,
    .button-2, .content .button-2 {
        // @name button-1 without content text in smartphone view
        &.button-1--no-text-in-mobile, &.button-2--no-text-in-mobile {
            text-align: left;
            text-indent: -9999px;
            white-space: nowrap;
            position: relative;
            padding: em(1.3, 1.4) em(2.1, 1.4);
            &:before {
                text-indent: 0;
                line-height: 0;
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%);
                margin: 0;
                font-size: em(1.4, 1.4);
            }
        }
    }

    .btn-decor {
        padding: 17px 15px 16px;
    }

    .button-3 {
        padding: 12px 14px 13px 9px;
        margin-bottom: 2px;
    }
}
