// @name Tools
// @description Tools on page and sharing on social media network
.tools {
    display: inline-block;
    vertical-align: middle;
    width: 35%;
    text-align: right;
    svg {
        width: 1.6em;
        height: 1.6em;
        fill: $color-gallery-table-picto;
    }
    img {
        width: 1.6em;
        height: 1.6em;
        fill: $color-gallery-table-picto;
    }
    .button-2 {
        background: transparent;
        border: 1px solid $color-gallery-table-picto;
        padding: 8px 13px;
        overflow: hidden;
        &:before {
            color: $color-gallery-table-picto;
            content: "\e02d";
        }
    }
    .link-favorite {
        font-size: 1.2em;
        font-weight: $bold;
        display: inline-block;
        vertical-align: top;
        color: $color-gallery-table-picto;
        svg {
            margin: 0 0 -6px;
            fill: transparent;
            stroke: $color-gallery-table-picto;
        }
        &--active {
            svg {
                fill: $color-gallery-table-picto;
            }
        }
    }
}

.tools__listitems {
    margin: 0 -.4em;
}

.tools__item {
    display: inline-block;
    vertical-align: middle;
    margin: 0 .4em;
}

// @name Share page
.ddm.share-page {
    .ddm__sub-level {
        display: block;
        visibility: hidden;
        top: 100%;
        left: inherit;
        right: 0;
        overflow: hidden;
        z-index: 2;

    }
    .ddm__wrapper {
        transform: translateY(-110%);
        transition: all ease .1s;
    }
    &.ddm--active {
        .ddm__sub-level {
            visibility: visible;
        }
        .ddm__wrapper {
            transform: translateY(0);
            transition: all ease .4s;
        }
    }
}

.share-page {
    .ddm__sub-level {
        text-align: left;
        width: 13em;
    }
    .ddm__wrapper {
        background: $color-gallery-table-picto;
        color: color-contrast($color-gallery-table-picto);
        padding: 1em 1.5em;
        a {
            color: color-contrast($color-gallery-table-picto);
            &:hover {
                color: gray;
            }
        }
    }
}

.share-page__item {
    font-size: 1.3em;
    margin: .5em 0;
    @include default-icons-before('\e098', 0 5px 0 0, em(1.4, 1.4), color-contrast($color-gallery-table-picto), em(-.2, 1.4));
    &:hover {
        @include default-icons-before('\e098', 0 5px 0 0, em(1.4, 1.4), gray, em(-.2, 1.4));
    }
    &.email {
        &:before {
            content: "\e028";
        }
    }
    &.facebook {
        &:before {
            content: "\e098";
        }
    }
    &.twitter {
        &:before {
            font-family: 'Font Awesome 6 Brands';
            content: "\e61b";
        }
    }
    &.google {
        &:before {
            content: "\e0a4";
        }
    }
    &.instagram {
        &:before {
            content: "\e0a1";
        }
    }
    &.linkedin {
        &:before {
            content: "\e0a2";
        }
    }
    &.viadeo {
        &:before {
            content: "\e0a3";
        }
    }
    &.youtube {
        &:before {
            content: "\e09d";
        }
    }
}

@include breakpoint(small only) {
    .tools {
        display: block;
        width: 100%;
        .tools__listitems {
            li {
                display: none;
                &:first-child {
                    display: block;
                }
            }
        }
    }
}
