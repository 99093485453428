// @name Main menu
.menu-main {
    float: left;
    width: 99%;
    &__level-1 {
        float: left;
        display: flex;
        flex-wrap: nowrap;
        flex-wrap: wrap;
        min-height: 100px;
        > li {
            flex: 1 1 auto;
            &.active,
            &.focus {
                > a {
                    background: $color-3--4;
                    color: $color-white;
                }
                .menu-main__drop {
                    display: block;
                }
            }
            > a {
                display: block;
                min-height: 100%;
                padding: 30px 10px 30px 22px;
                color: $color-3--4;
                text-decoration: none;
                text-transform: uppercase;
                font-size: 1.6rem;
                line-height: 1.2;
                font-weight: 700;
                span {
                    position: relative;
                    &:before {
                        content: "//";
                        position: absolute;
                        top: 0;
                        left: -14px;
                        color: $color-2--2;
                        padding: 0 4px 0 0;
                        letter-spacing: 1px;
                    }
                }
                &:hover,
                &:focus {
                    background: $color-3--4;
                    color: $color-white;
                }
                &:focus {
                    outline-color: $color-white;
                    outline-offset: -1px;
                }
            }
            .active {
                background: $color-3--4;
                color: $color-white;
            }

            $menu-colors: (1:#87c0d3, 2:#607926, 3:$color-4--2, 4:#9835a0, 5:$color-1--1, 6:$color-3--5);

            @each $index, $color in $menu-colors {

                &:nth-child(#{$index + 1}) {

                    > a > span::before {
                        color: $color;
                    }

                    .menu-main__drop-title {
                        border-bottom-color: $color;
                    }

                    .menu-main__drop-list a::after {
                        border-left-color: $color;
                    }
                }
            }
        }
    }
    &__drop {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background: rgba(60, 60, 60, 0.9);
        padding: 27px 0 60px;
        display: none;
        &-wrap {
            max-width: 1412px;
            margin: 0 auto;
            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flex;
            display: -o-flex;
            display: flex;
            -webkit-flex-wrap: wrap;
            -moz-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            -o-flex-wrap: wrap;
            flex-wrap: wrap;
        }
        &-col {
            width: 20%;
            float: left;
            padding: 0 12px;
        }
        &-title {
            color: $color-white;
            font-size: 1.8rem;
            line-height: 1.2;
            font-weight: 700;
            display: block;
            padding: 16px 20px;
            margin: 0 0 21px;
            border-bottom: 3px solid $color-2--2;
            text-decoration: none;
        }
        &-title-active {
            text-decoration: underline;
        }
        &-list {
            font-size: 1.6rem;
            line-height: 1.2;
            font-weight: 300;
            padding: 0 0 0 46px;
            li {
                margin: 0 0 5px;
                &.no-bullet {
                    a {
                        &:after {
                            display: none;
                        }
                    }
                }
            }
            a {
                color: $color-white;
                position: relative;
                padding: 0 0 0 12px;
                display: inline-block;
                vertical-align: top;
                &:after {
                    position: absolute;
                    top: 7px;
                    left: 0;
                    border-left: 6px solid $color-2--2;
                    border-top: 4px solid transparent;
                    border-bottom: 4px solid transparent;
                    content: '';
                }
                &:hover,
                &:focus {
                    text-decoration: underline;
                    &:after {
                        border-left-color: $color-white;
                    }
                }
                &:focus {
                    outline-color: $color-white;
                    outline-offset: -1px;
                }
            }
        }
    }
    @media (min-width: 961px) and (max-width: 1380px) {
        .menu-main__level-1 > li > a {
            padding: 42px 5px 42px 14px;
            font-size: 1.3rem;
        }
        .menu-main__drop-list {
            padding: 0 0 0 20px;
        }
    }
}

@include breakpoint(medium down) {
    .menu-main {

        &__level-1 {
            max-height: 100%;
        }
    }
}

@include breakpoint(small only) {
    // @name Main menu
    .menu-main {
        .menu-main__level-2 {
            display: none;
        }
    }
}

