// Galerie classique
.content {
    .classical-gallery__title {
        $classical-gallery__title_font-size: 1.6;
        font-family: $typo-2;
        text-align: right;
        font-weight: $normal;
        font-size: #{$classical-gallery__title_font-size}em;
        color: $color-3--3;
        margin: em(3, $classical-gallery__title_font-size) 0 em(1, $classical-gallery__title_font-size) 0;
    }
    .classical-gallery {
        margin: 1em 0 7em;
        position: relative;
        z-index: 100;
        
        &:after {
            clear: both;
            display: block;
            content: "";
            overflow: hidden;
            visibility: hidden;
            height: 0;
            speak: none;
        }
        &__nb-items {
            display: none;
        }
    }
    .slider-galerie {
        width: 100%;
        height: 100%;
        position: relative;
        margin-bottom: 10px;
        .slider-galerie__item {
            width: 100%;
            height: 100%;
            z-index: 1 !important;
            border: none;
        }
        .infos-img {
            position: absolute;
            z-index: 1;
            top: 100%;
            left: 0;
            width: calc(100% - 500px);
            display: table;
            padding: 8px 15px 0 0;
            font-family: $typo-2;
            font-weight: $light;
            font-style: italic;
            color: $color-3--3;
            font-size: 1.2em;
            text-align: left;
            white-space: normal;
            &__nbItems {
                display: table-cell;
                vertical-align: bottom;
                white-space: nowrap;
                width: 70px;
            }
            &__wrap {
                display: table-cell;
                vertical-align: top;
            }
            &__legend {
                display: block;
                position: relative;
                &:before {
                    content: '\e019';
                    margin: 0 3px 0 0;
                    font-size: 8px;
                    color: $color-2--2;
                    vertical-align: middle;
                    display: inline;
                    line-height: 1;
                    text-decoration: none;
                    font-family: "icons-default";
                    font-style: normal;
                }
            }
        }
        a {
            display: block;
            width: 100%;
            height: auto;
            text-align: center;
            position: relative;
            white-space: nowrap;
            padding: 68.08510% 0 0 0; // 100/(940/640)
            figure {
                display: inline;
            }
        }
        img {
            display: block;
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
            position: absolute;
            top: 50%;
            bottom: 0;
            left: 50%;
            right: 0;
            transform: translateX(-50%) translateY(-50%);
        }
    }
    .sliderVideo {
        margin-bottom: 0;
        .infos-img {
            position: inherit;
            bottom: inherit;
            top: inherit;
            left: inherit;
            right: inherit;
        }
    }
    .carousel-galerie {
        width: 500px;
        display: inline-block;
        text-align: right;
        position: relative;
        z-index: 1;
        float: right;
        .item {
            border: none;
        }
    }
    .carousel-galerie__thumb {
        width: 340px;
        height: 74px;
        z-index: 2;
        margin-left: 80px;
        text-align: left;
        &.no-carousel {
            position: relative;
            .carousel-galerie__item {
                display: inline-block;
                margin: 0 3px;
            }
        }
    }
    .carousel-galerie__item {
        z-index: 1 !important;
        text-align: center;
        &.cycle-slide-active button, &.cycle-pager-active button, button:hover, button:focus {
            &:before {
                transition: all ease .2s;
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                @include background-opacity($color-gallery-table-picto, .5)
            }
        }
        button {
            position: relative;
            &:before {
                background: rgba(0, 0, 0, 0);
            }
            img {
                width: 74px;
                height: 74px;
            }
        }
    }
    .carousel-galerie__pager {
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
        height: 74px;
        width: 500px;
        z-index: 1;
    }
    .carousel-galerie__prev, .carousel-galerie__next {
        position: absolute;
        top: 0;
        padding: 0;
        button {
            overflow: hidden;
            width: 74px;
            height: 74px;
            background: $color-gallery-table-picto;
            text-indent: -9999px;
            transition: all ease .2s;
            @include default-icons-absolute-before('\e026', em(2, 1.3), $color-white, 50%, inherit, inherit, 50%);
            &:before {
                text-indent: 0;
                transform: translateY(-50%) translateX(-50%);
            }
            &:hover, &:focus {
                background: $color-1--3;
            }
        }
        &:before {
            content: "";
            speak: none;
        }
    }
    .carousel-galerie__prev {
        left: 0;
    }
    .carousel-galerie__next {
        right: 0;
        button {
            &:before {
                content: "\e027";
            }
        }
    }
}

@include breakpoint(medium down) {
    // Galerie classique (avec vignettes) + Galerie album
    .content {
        .classical-gallery {
            margin-right: 0;
            margin-left: 0;
        }
        .slider-galerie {
            a {
                padding: 65.08510% 0 0 0;
            }
        }
    }
}

@include breakpoint(small only) {
    // Galerie classique (avec vignettes) + Galerie album
    .content {
        .classical-gallery {
            margin-right: -10px;
            margin-left: -10px;
            margin-bottom: 6em;
            margin-top: 6em;
            position: relative;
            &__title {
                padding: 0 10px;
            }
            &__nb-items {
                display: block;
                padding: 0 10px;
                background: rgba(0, 0, 0, .8);
                color: $color-white;
                position: absolute;
                bottom: 0;
                right: 0;
                font-size: 3em;
                margin: 0;
            }
        }
        .slider-galerie {
            margin-bottom: 0;
            &__item {
                height: auto;
                display: none;
                &:first-child {
                    display: block;
                }
            }
            .infos-img {
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                margin: 0;
                padding: 8px 10px;
                display: block;
                &__wrap {
                    display: block;
                }
            }
            a {
                display: block;
                height: 100%;
                padding: 0;
                figure {
                    display: block;
                    position: relative;
                    height: 100%;
                }
            }
            img {
                position: static;
                display: block;
                width: 100%;
                height: auto;
                -webkit-transform: translate(0, 0);
                -ms-transform: translate(0, 0);
                transform: translate(0, 0);
                max-width: none;
                max-height: none;
            }
            &-img {
                overflow: hidden;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
            .cycle-sentinel {
                .slider-galerie-img {
                    position: static;
                }
            }

        }
        .carousel-galerie__thumb {
            display: none;
        }
        .carousel-galerie {
            width: 100%;
            position: static;
            float: none;
            &__pager {
                position: static;
                height: 0;
            }
            .carousel-galerie__next,
            .carousel-galerie__prev {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 13px;
                z-index: 22;
                margin: 0;
            }
            .carousel-galerie__next {
                left: auto;
                right: 0;
            }
            .carousel-galerie__next button,
            .carousel-galerie__prev button {
                width: 24px;
                height: 100%;
                margin: 0;
            }
        }
        .classical-gallery__nb-items {
            display: none;
        }
    }
}
