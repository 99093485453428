.my-actualites {
	padding-top: 36px;
	padding-bottom: 52px;
	&-list {
		padding-top: 3px;
		font-size: 0;
		line-height: 0;
		margin: 0 -10px 34px;
		display: flex;
		flex-wrap: wrap;
	}
	&-col {
		display:inline-block;
		vertical-align:top;
		padding: 0 10px;
		width: 20%;
	}
	&-item {
		height: 100%;
		display: block;
		background: $color-actualite-bg url(#{$path-images}bg/bg-decor-01.png);
		text-align: center;
		color: $color-black;
		img {
			display: block;
			width: 100%;
			height: auto;
		}
		&-content {
			min-height: 90px;
			padding: 5px 10px;
			display: flex;
			align-items: center;
		}
		&-title {
			font-size: 2rem;
			line-height: 1.2;
			color: $color-black;
			font-weight: 700;
			display: block;
		}
	}
}
@include breakpoint(small only) {
.my-actualites {
	padding-bottom: 26px;
	&-list {
		padding-top: 0;
		margin: 0 -7px 16px;
	}
	&-col {
		width: 50%;
		padding: 0 7px 14px;
		&:first-child {
			width: 100%;
			.my-actualites-item-title {
				font-size: 1.8rem;
			}
			.my-actualites-item-content {
				min-height: 74px;
			}
		}
	}
	&-item {
		&-title {
			font-size: 1.4rem;
		}
		&-content {
			min-height: 0;
			padding: 12px 10px;
		}
	}
	.button-list{
		padding: 0 20px;
	}
}
}
