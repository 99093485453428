// @name Pager list
.content {
    .pager {
        ul {
            > li:before {
                content: '';
                a, span {
                    padding: 10px;
                    width: 34px;
                    height: 34px;
                    line-height: 1em;
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }
    }
}

.pager {
    ul {
        font-family: $typo-2;
        clear: both;
        display: block;
        margin: 3em 0 3.6em 0;
        padding: 0;
        text-align: center;
        font-weight: 400;
        font-style: normal;
        text-transform: uppercase;
    }
    li {
        display: inline-block;
        vertical-align: middle;
        margin: 0 0 0 -1px;
        padding: 0;
        background: none;
        font-size: 1.4em;
        a, span {
            padding: 10px;
            width: 34px;
            height: 34px;
            line-height: 1em;
            display: inline-block;
            vertical-align: middle;
        }
        a {
            color: #4c4c4c;
            background: $color-3--1;
            text-decoration: none;
            transition: all ease .2s;
            &:hover, &:focus {
                color: color-contrast($bg-ddm-btn-active);
                background: $bg-ddm-btn-active;
            }
        }
        span {
            font-weight: 700;
            cursor: default;
            color: color-contrast($search-bg);
            background: $search-bg;
        }
        &.pager__prev, &.pager__next {
            margin-top: -3px;
            a, span {
                min-width: 140px;
                padding: 14px 24px 11px 20px;
                width: auto;
                height: auto;
                font-weight: 400;
            }
            // Inactive
            span {
                color: color-contrast($color-3--1);
                background: $color-3--1;
            }
        }
        &.pager__prev {
            float: left;
            a, span {
                float: left;
                @include default-icons-before('\e026', 0 em(.5, 1.4) 0 0, -1px, $search-bg, em(-.2, 1.4));
                &:hover:before, &:focus:before {
                    color: $color-white;
                }
            }
            span {
                color: $color-3--2;
                // Inactive
                &:before, &:hover:before, &:focus:before {
                    color: $color-3--2;
                }
            }
        }
        &.pager__next {
            float: right;
            a, span {
                padding: 14px 20px 11px 24px;
                float: right;
                @include default-icons-after('\e027', 0 0 0 em(.5, 1.4), -1px, $search-bg, em(-.2, 1.4));
                &:hover:after, &:focus:after {
                    color: $color-white;
                }
            }
            span {
                color: $color-3--2;
                // Inactive
                &:after, &:hover:after, &:focus:after {
                    color: $color-3--2;
                }
            }
        }
    }
}

// @name Pager infinite scroll

@keyframes spin {
    0% {
        transform: rotate(0deg) translateY(-50%);
    }
    100% {
        transform: rotate(-360deg) translateY(-50%);
    }
}

.pager-infinite {
    margin: 2em 0;
    text-align: center;
    // @dependence .button-2
    .pager-infinite__button {
        &:before {
            content: "\e05f";
        }
    }
    .pager-infinite__loading {
        &:before {
            animation: spin 1s infinite linear;
            content: "\e05f";
            transform-origin: top;
        }
    }
}

// @name Pager single
// @dependence .pager
.pager-single {
    .pager-single__back-to-list {
        a {
            background: $search-bg;
            color: color-contrast($search-bg);
            font-weight: $bold;
            padding: em(1.23, 1.4) em(2.4, 1.4);
            width: auto;
            height: auto;
            @include default-icons-before('\e02f', 0 em(.5, 1.4) 0 0, em(1.2, 1.4), color-contrast($search-bg), em(-.2, 1.4));
        }
    }
    ul {
        overflow: hidden;
    }
}

// @name Pager single title
// TODO

// @name Pager alphabet
.pager-alphabet {
    margin-bottom: 5em;
}

.pager-alphabet__title {
    font-size: 1.4em;
    font-weight: $bold;
    margin-bottom: em(1.5, 1.4);
}

.pager-alphabet__listitems {
    text-align: left;
    > li {
        display: inline-block;
        vertical-align: middle;
        margin: .2em 0.1em;
        &.pager-alphabet__item--current {
            > a {
                background: $search-bg;
                color: $color-white;
            }
        }
        > a, > span {
            $pager-alphabet__listitems__a__font-size: 1.4;
            font-size: #{$pager-alphabet__listitems__a__font-size}em;
            background: $color-3--1;
            color: $color-black;
            display: block;
            padding: em(.6, $pager-alphabet__listitems__a__font-size) em(1.1, $pager-alphabet__listitems__a__font-size);
        }
        > a {
            &:hover, &:focus {
                background: $color-1--3;
                color: $color-white;
            }
        }
        > span {
            background: $color-white;
            border: 1px solid darken($color-3--1, 3%);
            color: darken($color-3--1, 15%);
        }
    }
}

@include breakpoint(small only) {
    // @name Pager list
    .pager {
        li {
            &.pager__prev, &.pager__next {
                span, a {
                    text-indent: -9999px;
                    white-space: nowrap;
                    position: relative;
                    padding: 0;
                    width: 40px;
                    height: 40px;
                    min-width: 0;
                    &:before, &:after {
                        text-indent: 0;
                        line-height: 0;
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%);
                        margin: 0;
                    }
                }
            }
        }
        &-single {
            .pager-single__back-to-list {
                margin: -3px 0 0 -1px;;
                a {
                    max-width: 195px;
                    font-size: 1.2rem;
                    padding: 14px 10px;
                }
            }
        }
    }
}
