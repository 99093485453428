// @name Wrapper with wide 940px
.wrapper-940 {
    max-width: 960px;
    margin: 0 auto;
    padding: 0 10px;
    @extend .clear-fix;
}

// @name Wrapper with wide 1180px
.wrapper-1180 {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 10px;
    @extend .clear-fix;
}

.wrapper-1224 {
    max-width: 1244px;
    margin: 0 auto;
    padding: 0 10px;
    @extend .clear-fix;
}

.wrapper-fluid {
    @extend .clear-fix;
    margin: 0 auto;
    max-width: 1920px;
    padding: 0;
}

// @name Background-in-wrapper
// @description Displayed in grey background on the sides.
.background-in-wrapper {
    position: relative;
    background: $color-3--1;
    &:before, &:after {
        content: "";
        position: absolute;
        z-index: 1;
        top: 0;
        width: 999em;
        height: 100%;
        background: $color-3--1;
    }
    &:before {
        left: -9990px;
    }
    &:after {
        right: -9990px;
    }
    // @description Displayed in dark background on the sides.
    &.background-in-wrapper--dark {
        background: $color-3--3;
        &:before, &:after {
            background: $color-3--3;
        }
    }
}

// @name Column bloc
// @description Generic bloc in .section-main__aside
.section-main__aside {
    > div {
        margin-bottom: 4em;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

$column-bloc__width: 24;
$column-bloc__padding-left: 6;
.column-bloc {
    width: #{$column-bloc__width}em;
    padding-left: #{$column-bloc__padding-left}em;
}

.column-bloc-right {
    padding-left: 0;
    padding-right: #{$column-bloc__padding-left}em;
}

.column-bloc__wrapper {
    padding: 0;
}

@include breakpoint(medium down) {
    // @name Column bloc
    // @description Generic bloc in .section-main__aside
    .column-bloc {
        width: 23.2%;
        padding: 0;
        float: left;
        margin: 0 6px;
    }

    .wrapper-940 {
        padding: 0 20px;
    }

    .wrapper-1180 {
        padding-left: 20px;
        padding-right: 20px;
    }
    .section-main__wrapper {
        padding: 40px 20px 20px;
    }
}

@include breakpoint(small only) {
    // @name Wrapper with wide 940px
    .wrapper-940 {
        width: 100%;
        padding: 0 10px;
        //padding-right: 0;
        //padding-left: 0;
    }

    .wrapper-1180 {
        padding: 0 10px;
    }

    // @name Column bloc
    // @description Generic bloc in .section-main__aside
    .column-bloc {
        width: 180px;
        float: none !important;
        margin: 0 auto 40px !important;
        padding-left: 0;
        .decor-title {
            font-size: 2.5rem;
        }
    }
}
