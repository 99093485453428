.top-bar {
    background: $bg-panel;
    position: relative;
    z-index: 102;
}

.with-back-link .top-bar {
    padding-top: 24px;
}

@include breakpoint(small only) {
    .top-bar {
        display: none;
    }
}
