.hero-banner {
	background: url(#{$path-images}bg/bg-decor-01.png);
	padding: 0 0 10px;
	position: relative;
	overflow: hidden;
	img {
		display: block;
		width: 100%;
		height: auto;
	}
	.wrapper-1224 {
		position:absolute;
		bottom: 11px;
		left:50%;
		width: 100%;
		-webkit-transform: translate(-50%, 0);
		-ms-transform: translate(-50%, 0);
		transform: translate(-50%, 0);
	}
	&-text {
		float: left;
	}
	&-category {
		font-size: 1.5rem;
		line-height: 1.2;
		text-transform: uppercase;
		color: $color-white;
		display:inline-block;
		vertical-align:top;
		padding: 11px 10px 12px 18px;
		font-weight: 700;
		background: rgba(60,60,60,0.8);
		position: relative;
		&:after {
			position:absolute;
			top:50%;
			left:-5px;
			-webkit-transform: translate(0, -50%);
			-ms-transform: translate(0, -50%);
			transform: translate(0, -50%);
			font-family: icons-project;
			font-size: 13px;
			line-height: 1;
			content: '\e008';
		}
	}
	&-title {
		font-size: 3.8rem;
		line-height: 1.2;
		color: $color-text-banner;
		display:inline-block;
		vertical-align:top;
		padding: 5px 17px 11px;
		font-weight: 300;
		//background: rgba(132,38,140,0.8);
        @include background-opacity($search-bg, 0.8);
		strong {
			font-weight: 700;
		}
		&:hover{
			//background: rgba(132,38,140,1);
            background: $search-bg;
			text-decoration: underline;
		}
	}
	&-decor {
		position:absolute;
		bottom: -40px;
		left:50%;
		-webkit-transform: translate(-50%, 0);
		-ms-transform: translate(-50%, 0);
		transform: translate(-50%, 0);
		width: 95px;
		height: 95px;
		overflow: hidden;
		background: url(#{$path-images}bg/bg-decor-01.png);
		border-radius: 50%;
		&-circle {
			position:absolute;
			top:50%;
			left:50%;
			-webkit-transform: translate(-50%, -50%);
			-ms-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
			background: $color-white;
			width: 75px;
			height: 75px;
			&:after,
			&:before {
				position:absolute;
				top:19px;
				left:50%;
				font-size: 16px;
				line-height: 1;
				color: $color-3--2;
				-webkit-transform: translate(-50%, 0);
				-ms-transform: translate(-50%, 0);
				transform: translate(-50%, 0);
				font-family: icons-default;
				content: '\e018';
			}
			&:after {
				top: 11px;
			}
		}
	}
	@media (max-width: 1100px) {
		.wrapper-1224 {
			bottom: 65px;
		}
	}
}

.wide-image-banner {
	width: 100%;

	img {
		display: block;
		width: 100%;
		height: auto;
	}
}

@include breakpoint(medium down) {
    .hero-banner {
        .wrapper-1224 {
            padding: 0;
            bottom: 91px;
        }
    }
}

@include breakpoint(small only) {
.hero-banner {
	.wrapper-1224 {
		bottom: 87px;
		padding: 0 10px;
	}
	&-category {
		font-size: 1.1rem;
		padding: 7px 10px 8px 10px;
		&:after {
			left:-8px;
		}
	}
	&-title {
		font-size: 2.4rem;
		padding: 3px 8px 7px;
	}
}

.wide-image-banner {

	img {
		min-height: 20rem;
		object-fit: cover;
	}
}
}
