// @name Tooltip Full CSS
// @description Not for accessibility
// @markup <a href="#" class="tooltip-css" data-tooltip="Lorem ipsum">Lorem</a>
// @markup <span class="tooltip-css" tabindex="1" data-tooltip="Lorem ipsum">Lorem</span>

.tooltip-css {
    position: relative;

    &::before, &::after {
        position: absolute;
        display: block;
        z-index: -98;
    }

    &::before {
        transform: translateY(-100%);
        top: calc(100% + 10px);
        left: 0;
        content: attr(data-tooltip);
        speak: none;
        white-space: nowrap;
        color: $color-white;
        font-size: 1.2em;
        font-weight: $light;
        background: $color-gallery-table-picto;
        padding: 5px 10px;
        opacity: 0;

        @include breakpoint(small down) {
            font-size: 1em;
        }
    }

    &::after {
        opacity: 0;
        transform: translateY(-100%);
        top: calc(calc(100% + 10px) - 8px);
        left: 0;
        content: '';
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-right: 0 solid transparent;
        border-bottom: 0 solid transparent;
        border-left: 8px solid $color-gallery-table-picto;
    }

    &:hover, &:focus {
        text-decoration: none;

        &::before, &::after {
            z-index: 98;
            outline: none !important;
            opacity: 1;
            transform: translateY(0);
        }
    }

    &.tooltip-css--right {
        &::before, &:after {
            left: inherit;
        }

        &::before {
            right: -15px;
        }

        &::after {
            right: 0;
        }
    }

    &.tooltip-css--top {
        @include on-event {
            &::before,
            &::after {
                transform: translate(-50%, 0);
            }
        }

        &::before,
        &::after {
           transform: translate(-50%, 100%);
        }

        &::before {
            bottom: calc(100% + 10px);
            left: 45%;
            top: auto;
        }

        &::after {
            bottom: 100%;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 12px solid $color-1--2;
            left: 50%;
            top: auto;
            transform: none;
        }
    }
}
