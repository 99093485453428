.module-options {
	margin: 0 -9px;
	position: relative;
	&:after{
		content:"";
		display:block;
		clear:both;
	}
	&:before {
		background: #bebebe;
		width: 1px;
		position:absolute;
		top:0;
		bottom: 10px;
		left:50%;
		-webkit-transform: translate(-51%, 0);
		-ms-transform: translate(-51%, 0);
		transform: translate(-51%, 0);
		content: '';
	}
	&-title {
		font-weight: 700;
		display: block;
		font-size: 1.2rem;
		line-height: 1.2;
		margin: 0 0 8px;
		color: $color-3--3;
	}
	&-col {
		float: left;
		width: 50%;
		padding: 0 9px;
	}
	&-list {
		margin: 0 -9px;
		font-size: 0;
		line-height: 0;
		&-col {
			display:inline-block;
			vertical-align:top;
			padding: 0 9px;
			width: 49.93%;
		}
	}
	&-item {
		border: 1px solid $color-3--7;
		min-height: 175px;
		position: relative;
		padding: 12px 5px 45px;
		margin-bottom: 10px;
		text-align: center;
		&-title {
			color: $color-1--2;
			font-size: 1.2rem;
			line-height: 1.2;
			font-weight: 700;
			display: block;
			margin: 0 0 23px;
			min-height: 31px;
			text-transform: uppercase;
		}
	}
	&-button-list {
		position:absolute;
		bottom: 13px;
		left:-5px;
		right: -5px;
		text-align: center;
		li {
			display:inline-block;
			vertical-align:top;
			margin: 0 6px;
		}
	}
}
@include breakpoint(small only) {
.module-options {
	&:before {
		display: none;
	}
	&-col {
		width: 100%;
		float: none;
		margin-bottom: 10px;
		&:not(:first-child) {
			padding-top: 10px;
			border-top: 1px solid $color-3--7;
		}
	}
	&-list {
		&-col {
			width: 50%;
		}
	}
}
}
