.social {
	float: right;
	padding: 12px 3px 0 23px;
	li {
		float: left;
		border-right: 1px solid $color-3--3;
		&:last-child {
			border: none;
		}
		a {
            @include size(32px, 22px);
			display: block;
			line-height: 24px;
			text-align: center;
			position: relative;

			span[class*="fa-"] {
				&::before {
					color: $color-white;
					font-size: 16px;
				}
			}

			&:hover,
			&:focus {
				span[class*="fa-"] {
					&::before {
						color: $color-3--3;
					}
				}
			}
			
			&:focus {
				outline-offset: -1px;
				outline-color: $color-white;
			}
		}
	}
}

@include breakpoint(medium down) {
    .social {
        padding-top: 14px;
        padding-left: 13px;
    }
}

@include breakpoint(small only) {
.social {
	float: none;
	padding: 41px 20px;
	margin: 0 -20px;
	text-align: center;
	font-size: 0;
	line-height: 0;
	border-bottom: 1px solid $color-3--3;
	li {
		float: none;
		display:inline-block;
		vertical-align:top;
		border-right: 2px solid $color-3--3;
		a {
            @include size(43px, 32px);
			&.facebook {
				svg {
                    @include size(12px, 23px);
				}
			}
			&.twitter {
				svg {
                    @include size(22px, 16px);
				}
			}
			&.youtube {
				svg {
                    @include size(20px, 23px);
				}
			}
            &.snapchat {
                svg {
                    @include size(20px, 23px);
                }
            }
			&.instagramm {
				svg {
                    @include size(21px, 22px);
				}
			}
            &.inkedin {
                svg {
                    @include size(21px, 22px);
                }
            }
			&.rss {
				svg {
                    @include size(21px, 22px);
				}
			}
		}
	}
}
}

