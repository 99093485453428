.menu-print {
    padding: 0 0 23px;

    &:after {
        content: '';
        clear: both;
        display: table;
    }

    &-content {
        margin-left: 300px;
        text-align: right;
    }

    &-legend {
        display: inline-block;
        vertical-align: top;
        font-size: 1.6rem;
        color: $color-1--2;
        font-weight: 700;
        margin: 0 15px 0 0;
    }

    &-list {
        display: inline-block;
        vertical-align: top;
        text-align: left;
        max-width: 318px;

        &-holder {
            display: inline-block;
            vertical-align: top;
        }

        li {
            font-size: 1.6rem;
            color: $color-3--4;
            font-weight: 300;
        }

        &:first-child {
            margin-right: 44px;
            max-width: 210px;
        }

        &:last-child {
            max-width: 339px;
        }
    }

    &-btn {
        float: left;
    }

    &-logo {
        position: relative;
        overflow: visible;
        .menu-print-content {
            margin-left: 0;
            text-align: left;
            float: left;
        }

        .menu-logo {
            position: absolute;
            right: -203px;
            top: -123px;
        }
    }

    @media (max-width: 1440px) {
        &-logo {
            overflow: hidden;
            .menu-logo {
                position: static;
                float: right;

                svg {
                    height: 100px;
                }
            }
        }
    }

    @media (max-width: 1023px) {
        &-list {
            display: block;

            li {
                font-size: 1.4rem;
            }

            &:first-child {
                margin-right: 0px;
                max-width: 300px;
            }

            &:last-child {
                max-width: 300px;
            }
        }

        &-legend {
            font-size: 1.4rem;
        }
    }
}

@include breakpoint(medium down) {
    .menu-print {
        &-legend {
            font-size: 1.4rem;
        }

        &-list {
            display: block;
            text-align: left;
            max-width: 318px;

            &-holder {
                display: inline-block;
                vertical-align: top;
            }

            li {
                font-size: 1.4rem;
                color: $color-3--4;
                font-weight: 300;
            }

            &:first-child {
                margin-right: 44px;
                max-width: 210px;
            }

            &:last-child {
                max-width: 322px;
            }
        }

        .btn-decor {
            float: left;
        }
    }
}

@include breakpoint(small only) {
    .menu-print {
        &-btn {
            float: none;
            text-align: center;
            margin-bottom: 20px;
        }

        &-content {
            margin-left: 0;
            text-align: left;
        }

        &-legend {
            display: block;
        }

        &-list-holder {
            display: block;
        }

        &-list {
            &:first-child,
            &:last-child {
                max-width: 100%;
            }
        }

        &-logo {
            .menu-print-content {
                float: none;
                width: 100%;
                margin: 0 0 15px;
            }

            .menu-logo {
                width: 100%;
                float: none;
                text-align: center;
                svg {
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }
    }
}
