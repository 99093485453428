.fimu {
    a,
    button,
    input[type='button'] {
        &:focus {
            outline: 1px dashed $search-bg;
        }
    }

    .with-back-link {
        .top-bar {
            padding-top: 0;
        }
    }

    .heading__green {
        background: $color-white;
    }

    // top-bar
    .top-bar {
        background: $bg-panel;
    }
    .language {
        margin-top: 0;
        &-opener {
            color: $color-lang;
            &:after {
                color: $color-picto-1;
            }
        }
    }
    .back-link {
        color: $color-text;
    }
    .order-link {
        display: none;
    }
    .add-nav {
        > ul {
            > li {
                > a {
                    color: $color-text;
                    &:after {
                        //color: $color-text;
                        content: none;
                    }
                    &:hover,
                    &:focus {
                        color: $color-white;
                        &:after {
                            color: $color-white;
                        }
                    }
                }
            }
        }
    }
    //.social {
    //    li {
    //        a {
    //            svg {
    //                fill: $color-text;
    //            }
    //        }
    //    }
    //}
    .user-menu-opener {
        display: none !important;
    }

    // header
    .header {
        &:before {
            background-color: $color-white;
            background-image: none;
        }
    }

    .logo {
        img {
            max-width: 290px;
            max-height: 60px;
        }
    }

    .menu-main__drop-title {
        border-bottom: 3px solid $color-picto-2;
    }
    .menu-main__drop-list {
        a {
            &:after {
                border-left: 6px solid $color-picto-2;
                border-top: 4px solid transparent;
                border-bottom: 4px solid transparent;
            }
        }
    }
    .menu-main__level-1 {
        > li {
            $menu-colors: (
                1: $color-picto-2,
                2: $color-picto-2,
                3: $color-picto-2,
                4: $color-picto-2,
                5: $color-picto-2,
                6: $color-picto-2
            );

            @each $index, $color in $menu-colors {
                &:nth-child(#{$index + 1}) {
                    > a > span::before {
                        color: $color;
                    }

                    .menu-main__drop-title {
                        border-bottom-color: $color;
                    }

                    .menu-main__drop-list a::after {
                        border-left-color: $color;
                    }
                }
            }

            > a {
                padding: 4rem 3rem;
                color: $color-title-4;
                span {
                    &:before {
                        content: none;
                    }
                }
            }
            .active {
                color: $color-white;
            }
        }
    }

    .top-of-content {
        background: $color-white;
    }

    // hero-banner
    .hero-banner {
        background-image: none;
        &-decor {
            background: $color-white;
        }
    }

    // my-actualites
    .actualites-block-item-content {
        background-image: none;
        background-color: #f4e3d0;
    }
    .decor-title {
        &:before {
            content: '\E009';
            font-family: 'icons-project';
            font-size: 2.1rem;
        }
        &-blue {
            &:before {
                color: #a18071;
            }
        }
    }

    // my-agenda-block
    .agenda-block {
        &.my-agenda-block {
            background: none;
        }
        &-time {
            margin-bottom: 0.7rem;
        }
        &-caption {
            margin: 1.3rem 0 0;
            padding: 1.2rem 0 0;
            border-top: 0.2rem solid $color-picto-2;
            border-bottom: none;
            font-style: normal;
            font-weight: $bold;
            color: $color-lang;
        }
        &-title {
            font-size: 2.5rem;
            color: $color-text-1;
        }
        .artist-list {
            display: block;
            li {
                margin-right: 0;
                margin-bottom: 1rem;
            }
            .agenda-block-text {
                font-weight: $bold;
                color: $color-text-1;
            }
        }
    }
    .date-1 {
        time {
            & + span + time {
                background: #ed6a6d;
            }
        }
    }

    // footer

    .menu-cross {
        li {
            a {
                &:after {
                    color: #f4e3d0;
                }
            }
        }
    }
    .newsletter {
        display: none;
    }

    .side-menu-opener {
        &-img {
            svg {
                fill: #f4e3d0;
            }
        }
        span {
            &:after {
                background: #f4e3d0;
            }
        }
    }

    // side panel
    .side-menu3-opener {
        display: none;
    }

    .location-1 {
        font-size: 1.2rem;
        line-height: 1;
        font-weight: $bold;
        color: $color-lang;
        text-transform: uppercase;
        &:before {
            content: '\e04d';
            margin-right: 0.5rem;
            font-family: 'icons-default';
            font-size: 1.1rem;
            color: $color-picto-2;
        }
    }
    .location-2 {
        font-size: 1.4rem;
        font-weight: $light;
        color: $color-text-1;
        &:before {
            content: '\e094';
            margin-right: 0.4rem;
            font-family: 'icons-default';
            font-size: 1rem;
            color: $color-picto-2;
        }
    }
    .category,
    .category a {
        color: $color-lang;
    }

    // list and single

    .heading.heading--fimu {
        h1 {
            font-size: 3.4rem;
            font-weight: $light;
            color: $color-3--4;
            text-transform: uppercase;
            &:before {
                content: '\E009';
                font-family: 'icons-project';
                font-size: 2.1rem;
                margin-right: 1rem;
                color: $color-picto-2;
            }
        }
    }

    .filters--fimu {
        select {
            background: #fff url('#{$path-images}form/select_right--fimu.png') no-repeat 100% 50%;
        }

        form {
            .form__field-datewrapper {
                .date-picker-control {
                    &:before {
                        color: #4e3435;
                    }
                }
            }
        }
    }

    .heading--single--fimu {
        h1 {
            margin-bottom: 0.5rem;
        }
        .agenda-block-text {
            font-size: 2.3rem;
            font-weight: $bold;
            color: $color-black;
        }
        .heading__teaser {
            font-size: 1.8rem;
            //font-weight: $normal;
        }
        .location-1 {
            font-size: 1.4rem;
            text-align: center;
        }
        .hour-place {
            margin-bottom: 0.5rem;
            .hour2 {
                font-size: 1.4rem;
                color: #444f4f;
            }
        }
        .heading__picture {
            margin-right: 1rem;
        }
        &.heading--single-event {
            .heading__wrapper-date {
                padding-right: 2rem;
                width: 17rem;
                border-right: 1px solid $color-picto-2;
            }
        }
    }

    .list-type-1 {
        .list__infos {
            border-bottom: 1px solid $color-picto-2;
        }
        &.list-type-1--event {
            .list-type-1__wrapper-date {
                border-right: 1px solid $color-picto-2;
            }
        }
        .list-type-1__title {
            margin-bottom: 1rem;
        }
        .agenda-block-text {
            margin-bottom: 0;
            font-size: 1.6rem;
            font-weight: $bold;
            color: $color-black;
            a {
                color: $color-black;
            }
        }

        .location-1 {
            font-size: 1.4rem;
            text-align: center;
        }
        .hour-place {
            margin-bottom: 0.5rem;
            .hour2 {
                font-size: 1.4rem;
                color: #444f4f;
            }
        }
    }

    .artist-list {
        display: flex;
        li {
            margin-right: 2rem;
            a {
                color: $color-black;
            }
        }
    }

    .list-type-3 {
        &.list-type-3--artists {
            .list__infos {
                border-bottom: 1px solid $color-picto-2;
            }
            .list-type-3__item {
                background: none;
                padding: 0;
                &:before,
                &:after {
                    content: none;
                }
            }
            .heading__wrapper-figure {
                flex-basis: auto;
            }
            .heading__wrap {
                flex-basis: 50%;
            }
            .category {
                margin-bottom: 1rem;
            }
            .list-type-3__picture {
                float: none;
                img {
                    width: 100%;
                    max-width: 100%;
                }
            }
            .list-type-3__title {
                margin-bottom: 0;
            }
        }
    }

    .list__item-wrap {
        position: relative;
        &:before,
        &:after {
            content: '';
            speak: none;
            width: 0;
            height: 0;
            border-style: solid;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;
        }
        &:after {
            border-width: 38px 0 0 38px;
            border-color: transparent transparent transparent $color-picto-2;
        }
        &:before {
            border-width: 0 38px 38px 0;
            border-color: transparent $color-white transparent transparent;
        }
    }
    .list-type-3__wrapper {
        overflow: inherit;
    }

    .heading--single-artists {
        .heading__wrapper-figure {
            flex-basis: auto;
        }
        .heading__wrap {
            flex-basis: 50%;
        }
        h1 {
            margin-bottom: 1.5rem;
        }
        .location-2 {
            margin-bottom: 2rem;
        }
        .list-infos__wrapper {
            .list-infos__listitems + .list-infos__listitems {
                border-left: 1px solid $color-picto-2;
            }
        }
        .list-infos__item {
            &:before {
                color: $color-picto-2;
            }
        }
        .list-infos__website {
            text-decoration: underline;
        }
        .social {
            padding: 3rem 0 0;
            li {
                a {
                    svg {
                        fill: $color-lang;
                    }
                }
            }
        }
    }

    .timeline.timeline--fimu {
        .timeline {
            &-month {
                border-top: 2px solid $color-picto-2;
                border-bottom: 2px solid $color-picto-2;
            }
            &-box {
                &-right {
                    .timeline-item {
                        border-right: 10px solid $color-picto-2;
                        border-left-width: 0;
                    }
                    .timeline-img {
                        background: linear-gradient(to left, rgba($color-picto-2, 0.4), rgba($color-white, 0.4));
                        &.timeline-img-without-right {
                            border-left: 10px solid $color-picto-2;
                            background: none;
                        }
                    }
                    .timeline-item-img-without-right {
                        border-right-width: 0;
                        //border-right:: 10px solid transparent;
                    }
                }
            }
            &-item {
                border-left: 10px solid $color-picto-2;
            }
            &-item-img-without-left {
                .timeline-img-without-left {
                    border-right: 10px solid $color-picto-2;
                }
            }
            &-img {
                background: linear-gradient(to left, rgba($color-white, 0.4), rgba($color-picto-2, 0.4));
            }
        }
        .agenda-block-text {
            font-size: 1.4rem;
            font-weight: $bold;
            color: $color-black;
        }
        .timeline-block-month-title {
            strong {
                background: $color-picto-2;
            }
        }
        .agenda-block-time {
            margin-bottom: 0.5rem;
            font-size: 1.6rem;
        }
        .agenda-block-caption {
            padding-top: 1.3rem;
            margin-top: 1.5rem;
            margin-bottom: 0;
            padding-bottom: 0;
            border-top: 2px solid $color-picto-2;
            border-bottom: none;
            &:before {
                top: -0.5rem;
                bottom: auto;
                background: $color-picto-2;
            }
            &:after {
                top: -1.6rem;
                bottom: auto;
                color: $color-picto-2;
            }
        }
        .agenda-block-title a {
            color: $color-black;
        }
    }
}
