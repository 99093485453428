// @name Breadcrumb
.breadcrumb {
  $breadcrumb__font-size: 1.2;
  font-family: $typo-2;
  display: inline-block;
  vertical-align: middle;
  width: 65%;
  p {
    font-size: #{$breadcrumb__font-size}em;
    font-weight: $normal;
    color: $color-text-breadcrumb;
    .breadcrumb__here {
      color: $color-text-breadcrumb;
      @include ghost();
    }
    a {
      font-weight: $normal;
      color: $color-text-breadcrumb;
      margin: 0 7px 0 0;
      @include default-icons-after('\e027', 0 0 0 10px, em(.9, $breadcrumb__font-size), $color-black, em(-.1, .9));
      &:last-child {
        &:after {
          color: $search-bg;
        }
      }
    }
  }
}
@include breakpoint(small only) {
.breadcrumb{
	display: none;
}
}
