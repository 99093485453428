// Color contrast
// Function that calculates if color needs to be light or dark.
@function color-contrast($color) {
    @return if(lightness($color) < 51, $color-white, $color-black);
}

// Transform Hexa to RGBA
@mixin background-opacity($color, $opacity: 0.5) {
    background: $color; /* The Fallback */
    background: rgba($color, $opacity);
}

// Calcul em size depending of the context
// Use em(fontSizeDoIWantInEm, fontContextInEM)
// padding: 0 em(3.5, 2.2) 0 em(5, 2.2);
@function em($fontSize, $context) {
    @return #{$fontSize/$context}em;
}

// Reset font icon
@mixin resetIcons() {
    &:before {
        font-family: none;
        content: none;
        margin: auto;
    }
}

// Create fontFace
@mixin fontFace($fontname, $fontfile, $fontweight: normal, $fontstyle: normal) {
    @font-face {
        font-family: "#{$fontname}";
        src: url("#{$path-font}#{$fontfile}.eot");
        src: url("#{$path-font}#{$fontfile}.eot?#iefix") format("embedded-opentype"),
        url("#{$path-font}#{$fontfile}.woff") format("woff"),
        url("#{$path-font}#{$fontfile}.woff2") format("woff2"),
        url("#{$path-font}#{$fontfile}.woff") format("font-woff"),
        url("#{$path-font}#{$fontfile}.ttf") format("truetype"),
        url("#{$path-font}#{$fontfile}.svg##{$fontname}") format("svg");
        font-weight: $fontweight;
        font-style: $fontstyle;
    }
}

// Add inline icon before
@mixin default-icons-before($icon, $margin: 0 5px 0 0, $size: 16px, $color: $color-black, $va: middle, $font: 'icons-default') {
    &:before {
        font-family: $font;
        content: $icon;
        margin: $margin;
        font-size: $size;
        color: $color;
        vertical-align: $va;
        display: inline-block;
        line-height: normal;
        text-transform: inherit;
    }
}

// Add inline icon after
@mixin default-icons-after($icon, $margin: 0 0 0 5px, $size: 16px, $color: $color-black, $va: middle, $font: 'icons-default') {
    &:after {
        font-family: $font;
        content: $icon;
        margin: $margin;
        font-size: $size;
        color: $color;
        vertical-align: $va;
        display: inline-block;
        line-height: normal;
        text-decoration: none;
    }
}

// Add absolute icon before
@mixin default-icons-absolute-before($icon, $size: 16px, $color: $color-black, $top: inherit, $right: inherit, $bottom: inherit, $left: inherit, $font: 'icons-default') {
    position: relative;
    &:before {
        font-family: $font;
        font-size: $size;
        color: $color;
        position: absolute;
        top: $top;
        right: $right;
        bottom: $bottom;
        left: $left;
        content: $icon;
        line-height: 1;
    }
}

// Add absolute icon after
@mixin default-icons-absolute-after($icon, $size: 16px, $color: $color-black, $top: inherit, $right: inherit, $bottom: inherit, $left: inherit, $font: 'icons-default') {
    position: relative;
    &:after {
        font-family: $font;
        font-size: $size;
        color: $color;
        position: absolute;
        top: $top;
        right: $right;
        bottom: $bottom;
        left: $left;
        content: $icon;
        line-height: 1;
    }
}

// Add image before
@mixin img-before($position: relative, $bgColor: transparent, $folderName: $image-bg-url, $fileName: $fileName, $width: auto, $height: auto, $zindex: 1, $top: inherit, $right: inherit, $bottom: inherit, $left: inherit) {
    position: $position;
    &:before {
        content: '';
        background: $bgColor url("#{$folderName}/#{$fileName}") no-repeat 0 0;
        width: $width;
        height: $height;
        position: absolute;
        top: $top;
        right: $right;
        bottom: $bottom;
        left: $left;
        z-index: $zindex;
    }
}

// Add image after
@mixin img-after($position: relative, $bgColor: transparent, $folderName: $image-bg-url, $fileName: $fileName, $width: auto, $height: auto, $zindex: 1, $top: inherit, $right: inherit, $bottom: inherit, $left: inherit) {
    position: $position;
    &:after {
        content: '';
        background: $bgColor url("#{$folderName}/#{$fileName}") no-repeat 0 0;
        width: $width;
        height: $height;
        position: absolute;
        top: $top;
        right: $right;
        bottom: $bottom;
        left: $left;
        z-index: $zindex;
    }
}

/// Dependency - str-replace mixin from https://css-tricks.com/snippets/sass/str-replace-function/
/// Replace `$search` with `$replace` in `$string`
/// @author Hugo Giraudel
/// @param {String} $string - Initial string
/// @param {String} $search - Substring to replace
/// @param {String} $replace ('') - New value
/// @return {String} - Updated string
@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

@mixin multi-inherit($this, $mask) {
    $selector: ();
    @each $amp in $this {
        $selector: append($selector, unquote(str-replace($mask, '&', $amp)), comma);
    }
    @at-root {
        #{$selector} {
            @content;
        }
    }
}

// Set width and height for element
@mixin size($width, $height: $width) {
    height: $height;
    width: $width;
}

// Set absolute position with settings
@mixin absolute($top: null, $right: null, $bottom: null, $left: null) {
    bottom: $bottom;
    left: $left;
    position: absolute;
    right: $right;
    top: $top;
}

@mixin reset-position {
    bottom: auto;
    left: auto;
    position: static;
    right: auto;
    top: auto;
}

// Add styles for :hover/:focus state
@mixin on-event {
    &:hover,
    &:focus,
    &:focus-within {
        @content;
    }
}

// Set transition for element with settings
@mixin transition($prop: all, $duration: 400ms, $easing: ease-in-out, $delay: 0ms) {
    transition: $prop $duration $easing $delay;
}

// Shortcode for transition mixin
@mixin trs($prop: all, $duration: 250ms, $easing: ease-in-out, $delay: 0ms) {
    @include transition($prop, $duration, $easing, $delay);
}

// Use this for creating scalable elements (usually images / background images) that maintain a ratio.
@mixin responsive-ratio($x,$y, $pseudo) {
    $padding: unquote(( $y / $x ) * 100 + '%');
    @if $pseudo == 'before' {
        &::before {
            content: '';
            display: block;
            padding-top: $padding;
            width: 100%;
        }
    } @else if $pseudo == 'after' {
        &::after {
            content: '';
            display: block;
            padding-top: $padding;
            width: 100%;
        }
    } @else {
        padding-top: $padding;
    }
}

// Set relative position with settings
@mixin relative($top: auto, $right: auto, $bottom: auto, $left: auto) {
    bottom: $bottom;
    left: $left;
    position: relative;
    right: $right;
    top: $top;
}

// Set width and height for element
@mixin size($width, $height: $width) {
    height: $height;
    width: $width;
}

// Set max-width and max-height for element
@mixin max-size($width, $height: $width) {
    max-height: $height;
    max-width: $width;
}

// Set min-width and min-height for element
@mixin min-size($width, $height: $width) {
    min-height: $height;
    min-width: $width;
}

// Add styles for font-awesome icon inside block
@mixin fa-icon-style($pseudo: true) {
    span[class*="fa-"] {
        @if $pseudo == true {
            &::before {
                @content;
            }
        }

        @else {
            @content;
        }
    }
}

// Set object fit property include polyfill values
// @param {string} $fit - object-fit value
// @param {string} $position - object-fit-position value
@mixin object-fit($fit: "cover", $position: "center") {
    font-family: "object-fit: #{$fit}; object-position: #{$position};";
    object-fit: unquote($fit);
}

%icons-font-aliasing {
    display: inline-block;
    font-family: 'Font Awesome 6 Pro';
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    font-weight: $bold;
    -moz-osx-font-smoothing: grayscale;
    //vertical-align: -0.125em;
}

// Add inline icon before
@mixin icon( $position, $icon, $ff: null, $va: null, $fw: null) {
    &::#{$position} {
        @extend %icons-font-aliasing;
        content: if(str-index($icon, 'attr'), $icon, unquote('\"#{ $icon }\"'));
        font-family: $ff;
        font-weight: $fw;
        vertical-align: $va;
    }
}

@mixin icon-before($icon, $ff: null, $va: null, $fw: null) {
    @include icon('before', $icon, $ff, $va, $fw);
}

@mixin icon-after($icon, $ff: null, $va: null, $fw: null) {
    @include icon('after', $icon, $ff, $va, $fw);
}

// Mixin for truncating text
// @param {Integer} - $width - row max-width
@mixin truncate($width) {
    max-width: $width;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
