.resetButton {
    @include size(30px);
    align-items: center;
    display: flex;
    justify-content: center;

    @include fa-icon-style(false) {
        font-size: 3rem;
    }

    &.disabled {
        opacity: 0.3;
    }
}
