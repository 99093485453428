.agenda-block {
    padding: 42px 0 41px;

    h2 {
        margin-bottom: 45px;
    }

    &-gallery {
        margin: 0 0 48px;

        .listItems {
            margin: 0 -12px;
            overflow: hidden;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .item {
                width: 20%;
                padding: 0 12px;
                float: left;
                text-align: center;

                a {
                    display: block;
                }

                &:hover {
                    a {
                        text-decoration: none;
                    }

                    .agenda-block-title {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    &-caption {
        font-size: 1.2rem;
        line-height: 1.2;
        font-style: italic;
        font-weight: 400;
        color: $color-3--3;
        text-transform: uppercase;
        padding: 0 0 12px;
        border-bottom: 2px solid $color-picto-2;
        margin: 0 0 13px;
        display: block;
    }

    &-title {
        color: $color-3--3;
        font-size: 2.2rem;
        line-height: 1.2;
        margin: 0 0 8px;
        font-weight: 700;
        display: block;

        a {
            color: $color-3--3;
        }
    }

    &-time {
        color: $color-3--3;
        font-size: 1.6rem;
        line-height: 1.2;
        font-weight: 300;
    }

    &-text {
        font-weight: 300;
        margin: 0;
        font-size: 1.2rem;
        line-height: 1.2;
        color: $color-3--3;
    }

    &.my-agenda-block {
        background: url(#{$path-images}bg/bg-decor-01.png);
        padding: 37px 0 46px;
    }
}

@include breakpoint(medium down) {
    .agenda-block {
        padding-top: 21px;
        padding-bottom: 50px;

        &-gallery {
            margin-bottom: 18px;

            .listItems {
                font-size: 0;
                line-height: 0;
                text-align: center;

                .item {
                    width: 248px;
                    float: none;
                    display: inline-block;
                    vertical-align: top;
                    padding-bottom: 43px;
                }
            }
        }
    }
}

@include breakpoint(small only) {
    .agenda-block {
        padding-top: 35px;
        padding-bottom: 27px;

        h2 {
            margin: 0 0 35px;
        }

        &-gallery {
            padding: 0 38px;
            margin-bottom: 41px;
            position: relative;

            .listItems {
                margin: 0;

                .item {
                    padding: 0;
                    float: none;
                    white-space: normal;
                    vertical-align: top;
                }
            }

            .prevCaroussel3,
            .nextCaroussel3 {
                position: absolute;
                top: 22px;
                left: 0;
            }

            .nextCaroussel3 {
                left: auto;
                right: 0;
            }
        }

        &.my-agenda-block {
            padding: 25px 0 22px;
        }

        .cycle-slide {
            opacity: 0 !important;

            &.cycle-slide-active {
                opacity: 1 !important;
            }
        }
    }
}
