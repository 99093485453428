.fixed-bar {
    position: fixed;
    top: 70%;
    right: 0;
    transform: translate(0, -50%);
    z-index: 101;

    .mobile-menu & {
        display: none;
    }

    @include breakpoint(small only) {
        display: none;

        .mobile-menu & {
            position: static;
            display: flex;
            justify-content: space-around;
            transform: none;
            right: auto;
            top: auto;
        }
    }
}
