.kiosk-block {
    padding-top: 20px;
    padding-bottom: 92px;
    h2 {
        margin: 0 0 43px;
    }
    &-wrap {
        overflow: hidden;
        margin: 0 0 9px;
        position: relative;
        &-sep {
            position: absolute;
            top: 42%;
            left: 70%;
            width: 6.6%;
            height: 186px;
            overflow: hidden;
            -webkit-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            transform: translate(0, -50%);
            &:after {
                background: #bcbcbc;
                width: 1px;
                height: 200px;
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%) rotate(23deg);
                -ms-transform: translate(-50%, -50%) rotate(23deg);
                transform: translate(-50%, -50%) rotate(23deg);
                content: '';
            }
        }
    }
    &-description {
        float: left;
        width: 49%;
        padding: 0 45px 0 0;
        &-img {
            float: left;
            margin: 0 18px 0 0;
            width: 283px;
            img {
                display: block;
                width: 100%;
                height: auto;
            }
        }
        &-text {
            overflow: hidden;
        }
        &-title {
            color: $color-1--2;
            font-size: 3.8rem;
            line-height: 1.1;
            margin: 0 0 19px;
            font-weight: 400;
            a {
                color: $color-1--2;
                &:focus {
                    outline-offset: -1px;
                }
            }
        }
        &-number {
            display: block;
            color: $color-2--2;
            font-size: 2.4rem;
            line-height: 1.2;
        }
        &-date {
            color: $color-3--3;
            display: block;
            font-weight: 300;
            font-size: 2rem;
            line-height: 1.2;
            margin: 0 0 18px;
            text-transform: uppercase;
        }
        .button-3-download {
            display: block;
            font-size: 1.1rem;
            font-weight: 600;
            text-align: left;
            width: 100%;
        }
    }
    &-menu {
        float: left;
        width: 32%;
        padding: 0 175px 0 0;
        &-title {
            font-weight: 700;
            font-size: 1.6rem;
            line-height: 1.1;
            color: $color-3--4;
            margin: 0 0 16px;
            text-transform: uppercase;
        }
    }
    &-list {
        li {
            margin: 0 0 10px;
        }
        a {
            display: block;
            &:hover {
                text-decoration: none;
                span {
                    text-decoration: underline;
                }
            }
        }
        .category {
            font-size: 1.2rem;
            line-height: 1.2;
            color: $color-1--2;
            font-style: italic;
            text-transform: uppercase;
        }
        p {
            font-size: 1.4rem;
            line-height: 1.2;
            color: $color-3--4;
            margin: 0;
            font-weight: 300;
        }
    }
    &-product {
        width: 18.9%;
        float: left;
        &-list {
            margin: 0 -15px;
            font-size: 0;
            line-height: 0;
            li {
                display: inline-block;
                vertical-align: top;
                width: 50%;
                padding: 0 15px 16px;
                a {
                    color: $color-black;
                }
            }
            img {
                display: block;
                width: 100%;
                height: auto;
                margin: 0 0 13px;
            }
        }
        &-title {
            color: $color-3--3;
            font-size: 1.4rem;
            line-height: 1.2;
            font-weight: 700;
            display: block;
            margin: 0 0 8px;
        }
        &-date {
            color: $color-1--2;
            font-size: 1.2rem;
            line-height: 1.2;
            font-style: italic;
            font-weight: 400;
            display: block;
            text-transform: uppercase;
        }
    }
    @media (max-width: 1200px) {
        .kiosk-block-description-img {
            width: 46%;
        }
    }
}

@include breakpoint(medium down) {
    .kiosk-block {
        padding: 20px 0 67px;
        &-description {
            width: 32%;
            padding: 0 5% 0 0;
            .kiosk-block-description-img {
                width: 100%;
                float: none;
                margin: 0 0 15px;
            }
        }
        &-menu {
            padding: 0 4% 0 0;
        }
        &-product {
            width: 30.2%;
        }
        &-wrap {
            margin-bottom: 27px;
            &-sep {
                display: none;
            }
        }
    }
}

@include breakpoint(small only) {
    .kiosk-block {
        padding-top: 24px;
        padding-bottom: 37px;
        h2 {
            margin: 0 0 36px;
        }
        &-wrap {
            margin-bottom: 10px;
            &-sep {
                display: none;
            }
        }
        &-description {
            float: none;
            width: 100%;
            padding: 0 0 19px;
            &:after {
                content: "";
                display: block;
                clear: both;
            }
            .kiosk-block-description-img {
                width: 110px;
                margin: 0 18px 0 0;
                float: left;
            }
            &-title {
                font-size: 3rem;
                margin-bottom: 22px;
                margin-top: -4px;
            }
            &-number {
                font-size: 2.2rem;
                line-height: 1.1;
            }
            &-date {
                font-size: 1.4rem;
            }
        }
        &-menu {
            float: none;
            width: 100%;
            padding: 0 10px 25px;
            &-title {
                margin: 0 0 19px;
            }
        }
        &-product {
            width: 100%;
            float: none;
            padding: 0 20px;
            &-list {
                max-width: 350px;
                margin: 0 auto 7px;
                li {
                    padding-bottom: 25px;
                }
            }
        }
    }
}
