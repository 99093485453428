.project {
    $this: &;
    margin: 8rem -0.8rem 4rem;

    @include breakpoint(medium down) {
        margin-top: 6rem;
    }

    @include breakpoint(small down) {
        margin-left: 0;
        margin-right: 0;
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        margin-top: 3rem;
    }

    &__item {
        flex-basis: calc(100% / 3 - 1.6rem);
        margin: 0 0.8rem 5rem;
        width: calc(100% / 3 - 1.6rem);

        @include breakpoint(small down) {
            flex-basis: 50%;
            margin-left: 0;
            margin-right: 0;
            width: 50%;
        }

        @include on-event() {
            #{$this}__image {
                &::before {
                    height: 100%;
                }
            }

            div.project__picture {
                &::before {
                    opacity: 0;
                }
            }

            #{$this}__link {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &__image {
        position: relative;

        &::before {
            @include absolute(null, null, 0, 0);
            @include size(100%, 0);
            @include trs();
            background-color: rgba($color-1--3, 0.6);
            content: '';
        }

        & + #{$this}__content {
            #{$this}__category {
                margin-top: -1.6rem;
            }
        }
    }

    &__picture {
        width: 100%;

        img {
            @include size(100%, auto);
        }

        @at-root {
            div#{&} {
                &::before {
                    @include absolute(50%, null, null, 50%);
                    color: $color-white;
                    content: fa-content($fa-var-images);
                    font-family: $font-awesome;
                    font-size: 5rem;
                    font-weight: $light;
                    opacity: 1;
                    transform: translate(-50%, -50%);
                    transition: opacity ease 0.3s;
                }

                &::after {
                    background: $color-1--2;
                    content: '';
                    display: block;
                    padding-top: 77.895%;
                }
            }
        }
    }

    &__link {
        @include absolute(50%, null, null, 50%);
        @include trs();
        border: 0.1rem solid $color-white;
        border-left: 0;
        display: inline-block;
        opacity: 0;
        transform: translate(-50%, -50%);
        visibility: hidden;
        z-index: 1;

        &::before,
        &::after {
            @include size(0.1rem, calc(50% - 1.2rem));
            background-color: $color-white;
            content: '';
            display: block;
            left: 0;
            position: absolute;
        }

        &::before {
            top: 0;
        }

        &::after {
            bottom: 0;
        }

        &-text {
            color: $color-white;
            font-size: 1.2rem;
            font-weight: $bold;
            padding: 1.6rem 1.8rem;
            text-transform: uppercase;
            white-space: nowrap;

            &:after {
                @include absolute(50%, null, null, -0.7rem);
                color: $color-white;
                content: '\e008';
                font-family: icons-project;
                font-size: 1.2rem;
                line-height: 1;
                transform: translate(0, -50%);
            }
        }
    }

    &__content {
        text-align: center;
    }

    &__category {
        background-color: $color-1--2;
        color: $color-white;
        display: table;
        font-size: 1.2rem;
        font-style: italic;
        margin: 0 auto;
        padding: 0.85rem 2.1rem;
        position: relative;
        text-transform: uppercase;

        & + #{$this}__title {
            margin-top: 1rem;
        }
    }

    &__title {
        color: $color-black;
        font-size: 2rem;
        font-weight: $bold;
        line-height: calc(2.4 / 2);
        margin-top: 2.4rem;

        @include breakpoint(small down) {
            font-size: 1.6rem;
        }

        a {
            color: inherit;
        }
    }
}

.project-block {
    .list-paginated__wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -0.8rem;

        @include breakpoint(small down) {
            margin: 0;
        }
    }
}
