.order-form {
	fieldset {
		margin: 0;
	}
	&-title {
		color: $color-white;
		font-size: 1rem;
		line-height: 1.2;
		text-transform: uppercase;
		display: block;
		font-weight: 700;
		margin: 0 0 30px;
	}
	&-field {
		position: relative;
		border-bottom: 1px solid #9d9d9d;
		padding: 0 0 0 30px;
		margin: 0 0 6px;
		&-ico {
			width: 30px;
			height: 44px;
			position:absolute;
			top:0;
			left:0;
			margin: 0;
			img {
				position:absolute;
				top:50%;
				left:50%;
				-webkit-transform: translate(-50%, -50%);
				-ms-transform: translate(-50%, -50%);
				transform: translate(-50%, -50%);
			}
		}
		input {
			display: block;
			width: 100%;
			background: none;
			border: none;
			color: $color-white;
			font-size: 16px;
			font-weight: 300;
			padding: 0 10px;
			height: 44px;
			&:focus {
				outline-color:$color-white;
				outline-offset:-1px;
			}
		}
	}
	&-question {
		margin: 14px 0 32px;
		li {
			margin: 0 0 5px;
		}
		a {
			color: $color-white;
			text-decoration: underline;
			font-size: 1.3rem;
			line-height: 1.2;
			position: relative;
			padding: 0 0 0 12px;
			&:after {
				font-family: icons-default;
				font-size: 9px;
				line-height: 1;
				position:absolute;
				top:4px;
				left:-1px;
				content: '\e017';
			}
			&:hover,
			&:focus {
				text-decoration: none;
			}
			&:focus {
				outline-color:$color-white;
				outline-offset:-1px;
			}
		}
	}
	&-btn {
		display: block;
		margin: 0 auto;
		background: $color-2--1;
		font-size: 1.4rem;
		line-height: 1.2;
		color: $color-white;
		font-weight: 700;
		padding: 17px 34px 16px;
		text-transform: uppercase;
		&:hover,
		&:focus {
			background: darken($color-2--1, 10%);
		}
		&:focus {
			outline-color:$color-white;
			outline-offset:-1px;
		}
		&-submit {
			margin-bottom: 44px;
			&:before {
				font-family: icons-default;
				font-size: 12px;
				line-height: 1;
				display:inline-block;
				vertical-align:-1px;
				margin: 0 10px 0 0;
				font-weight: 400;
				content: '\e057';
			}
		}
		&-logout {
			&:before {
				font-family: icons-default;
				font-size: 12px;
				line-height: 1;
				display:inline-block;
				vertical-align:-1px;
				margin: 0 12px 0 0;
				font-weight: 400;
				content: '\e002';
			}
		}
	}
	p {
		color: $color-white;
		font-weight: 300;
		font-size: 1.6rem;
		line-height: 1.2;
		margin: 0 0 30px;
	}
	&-button-list {
		max-width: 249px;
		margin: 0 auto;
		padding: 5px 0 8px;
		li {
			margin: 0 0 20px;
		}
		button {
			width: 100%;
			display: block;
			padding-left: 10px;
			padding-right: 10px;
		}
	}
}
