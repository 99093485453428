.side-menu,
.side-menu2,
.side-menu3 {
    border-left: 5px solid $color-3--5;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    transform: translate3d(100%, 0, 0);
    width: 100%;
    max-width: 385px;
    z-index: 101;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    overflow-y: auto; // Can break slide animation in Firefox
    transition: all 0.3s linear;
    will-change: transform;
    box-shadow: 50px 0 30px -30px rgba(255, 255, 255, 0.1) inset;
    visibility: hidden;
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;
    @include multi-inherit(&, '&-opened &') {
        transform: translate3d(0%, 0, 0);
        visibility: visible;
    }
    &-inner {
        overflow-y: auto;
        padding: 20px 50px 20px 89px;
        position: relative;
        width: 100%;
        max-height: 100%;
    }
    &-title {
        color: $color-white;
        font-size: 2.2rem;
        line-height: 1.2;
        font-weight: 700;
        display: block;
        text-transform: uppercase;
        margin: 0 0 28px;
        span {
            position: relative;
            &:after {
                position: absolute;
                bottom: -5px;
                left: 50%;
                width: 10px;
                height: 3px;
                transform: translate(-50%, 0);
                background: $color-2--2;
                content: '';
            }
        }
    }
    &-list {
        font-size: 1.6rem;
        line-height: 1.2;
        font-weight: 700;
        text-transform: uppercase;
        li {
            margin: 0 0 21px;
        }
        a {
            color: $color-white;
            &:focus {
                outline-color: $color-white;
                outline-offset: -1px;
            }
        }
    }
    &-ico {
        position: absolute;
        top: 25px;
        left: 32px;
        > svg {
            fill: $color-2--2;
        }
    }
    &-opener {
        background: $color-3--4;
        position: relative;
        display: block;
        width: 8rem;
        min-height: 8rem;
        border: none;
        border-left: 5px solid $color-3--5;
        color: $color-white;
        text-transform: uppercase;
        font-size: 1.2rem;
        line-height: 1.2;
        font-weight: 700;
        padding: 53px 5px 14px;
        box-shadow: 25px 0 30px -20px rgba(255, 255, 255, 0.1) inset;
        margin: 5px 0;
        white-space: normal;

        &-img {
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
            top: 14px;
        }
        span {
            position: relative;
            &:after {
                position: absolute;
                bottom: -5px;
                left: 50%;
                width: 7px;
                height: 3px;
                transform: translate(-50%, 0);
                background: $color-2--2;
                content: '';
            }
        }
        &:focus {
            outline-offset: -1px;
            outline-color: $color-white;
        }
    }
    &-close {
        position: absolute;
        top: 13px;
        right: 14px;
        width: 40px;
        height: 40px;
        overflow: hidden;
        background: none;
        border: none;
        box-shadow: none;
        padding: 0;
        &:focus {
            outline-offset: -1px;
            outline-color: $color-white;
        }
        &:after,
        &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 24px;
            height: 1px;
            background: $color-white;
            transform: translate(-50%, -50%) rotate(-43deg);
            content: '';
        }
        &:before {
            transform: translate(-50%, -50%) rotate(43deg);
        }
    }
}

.side-menu {
    background: $color-3--4;
    flex-wrap: wrap;
    &-inner {
        &--acces-rapides {
            .side-menu-ico {
                top: 15px;
                svg {
                    fill: $color-4--2;
                }
            }
            .side-menu-title {
                span {
                    &:after {
                        background: $color-4--2;
                    }
                }
            }
        }
    }
    &-opener {
        &:hover,
        &:focus {
            .side-menu-opener-img {
                svg {
                    fill: $color-1--2 !important;
                }
            }
            span {
                &:after {
                    background: $color-1--2;
                }
            }
        }
        &-img {
            svg {
                fill: $color-2--2;
            }
        }
    }
}

.side-menu2 {
    background: $color-4--1;
    border-left-color: $color-4--3;
    color: $color-1--2;
    background: $color-4--1;
    border-left: 5px solid $color-4--3;
    &-title {
        color: $color-1--2;
        margin-bottom: 48px;
    }
    &-list {
        a {
            color: $color-1--2;
        }
    }
    &-ico {
        top: 9px;
        left: 28px;
        svg {
            fill: $color-1--2;
        }
    }
    &-opener {
        background: #b2d95a;
        border-left-color: $color-4--3;
        color: $color-1--2;
        padding: 43px 5px 7px;
        &:hover,
        &:focus {
            .side-menu-opener-img {
                svg {
                    fill: #912CEE !important;
                }
            }
            span {
                &:after {
                    background: #912CEE;
                }
            }
        }
        &-img {
            top: 10px;
            fill: $color-1--2;
        }
    }
    &-close {
        &:after,
        &:before {
            background: $color-1--2;
        }
    }
    .link-button {
        background: $color-1--2;
        border-left: 5px solid $color-1--3;
        display: inline-block;
        line-height: 1;
        padding: 15px 30px;
        color: $color-white;
        font-size: 1.3rem;
        text-transform: uppercase;
        font-family: $typo-5;
    }
}

.side-menu3 {
    background: $color-3--4;
    flex-wrap: wrap;
    &-inner {
        &--acces-rapides {
            .side-menu3-ico {
                top: 15px;
                svg {
                    fill: $color-1--1;
                }
            }
            .side-menu3-title {
                span {
                    &:after {
                        background: $color-1--1;
                    }
                }
            }
        }
    }
    &-opener {
        &:hover,
        &:focus {
            .side-menu3-opener-img {
                svg {
                    fill: #F28028 !important;
                }
            }
            span {
                &:after {
                    background: #F28028;
                }
            }
        }
        &-img {
            svg {
                fill: $color-white;
            }
        }
        span {
            &:after {
                background: $color-white;
            }
        }
    }
}

.page-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(54, 64, 64, 0.6);
    z-index: 100;
}

// Comment because we leave it on mobile view
//@include breakpoint(small only) {
//    .side-menu,
//    .side-menu3 {
//        position: static;
//        transform: translate(0, 0);
//        border: none;
//        display: block;
//        width: auto;
//        margin: 0 -20px;
//        display: none;
//        &-inner {
//            padding: 53px 50px 40px 85px;
//        }
//        &-close {
//            display: none;
//        }
//        &-ico {
//            left: 30px;
//            top: 58px;
//        }
//    }
//}
