// fancyBox v2.1.4 fancyapps.com | fancyapps.com/fancybox/#license
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
    padding: 0;
    margin: 0;
    border: 0;
    vertical-align: top;
}

.fancybox-wrap {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 8020;
    color: #000;

    p {
        font-size: 1.3em;
    }

    a:focus {
        outline: 1px dotted #fff;
    }

    .fancybox-close {
        @include breakpoint(medium down) {
            right: 0;
            bottom: 0;
            padding: 0;
            background: none;
            border: 1px solid transparent;
            &:hover,
            &:focus {
                border: 1px dotted #fff;
            }
            span {
                display: none;
            }
            img {
                display: inline;
            }
        }
    }
}

.fancybox-galerie {
    @include breakpoint(medium down) {
        padding: 0;
    }

    .fancybox-close-wrap {
        button {
            @include size(auto);
            background: none;
            border: none;
            border-radius: 0;
            bottom: -1.5rem;
            margin: 0;
            padding: 0;
            right: 1.5rem;
            text-decoration: none;
            top: auto;

            &:before {
                background: none;
                content: "";
                speak: none;
            }
        }
    }

    &.fancybox-wrap {
        //background: #000;
    }

    .fancybox-skin {
        background: #000;
    }

    .fancybox-title {
        .numberOfFancybox {
            margin: 0;
        }

        .captionFancybox {
            border-top: 1px solid #353535;
            margin-top: 10px;
            padding-top: 5px;
            padding-right: 0;
        }
    }

    @include breakpoint(medium down) {
        .fancybox-wrap-title {
            position: fixed;
            bottom: 32px;
            left: 0;
            width: 100%;
            z-index: 9996;
        }
        .fancybox-more-infos {
            position: fixed;
            bottom: 0;
            left: 32px;
            z-index: 9996;
            display: block;
            border: 1px solid transparent;

            &:hover,
            &:focus {
                border: 1px dotted #fff;
            }
        }
    }
}

.fancybox-skin {
    background-color: $color-white;
    position: relative;
    text-shadow: none;
    z-index: 9000;
}

.fancybox-opened {
    z-index: 10011;
}

.fancybox-outer, .fancybox-inner {
    position: relative;

}

.fancybox-outer span {
    display: inline-block;
}

.fancybox-inner {
    overflow: hidden;
}

.fancybox-type-iframe .fancybox-inner {
    -webkit-overflow-scrolling: touch;
}

.fancybox-error {
    color: #444;
    margin: 0;
    white-space: nowrap;
}

.fancybox-image, .fancybox-iframe {
    display: block;
    width: 100%;
    height: 100%;
}

.fancybox-image {
    max-width: 100%;
    max-height: 100%;
}

#fancybox-loading {
    background: url("#{$path-images}fancybox/loading_bg.png") no-repeat 0 0;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -22px;
    margin-left: -22px;
    opacity: 0.8;
    cursor: pointer;
    z-index: 8060;
}

#fancybox-loading div {
    width: 44px;
    height: 44px;
    background: url('#{$path-images}fancybox/fancybox_loading.gif') center center no-repeat;
}

.fancybox-close {
    @include absolute(-1.5rem, -1.5rem);
    @include size(1.1rem);
    padding-right: 15px;
    color: #fff;
    cursor: pointer;
    z-index: 8060;
    font-size: 1.3em;
    font-weight: $bold;
    text-decoration: none;
    background: url("#{$path-images}fancybox/close.png") no-repeat 100% 50%;

    i {
        font-style: normal;
    }

    img {
        display: none;
    }

    &:hover, &:focus {
        color: #fff;
        font-weight: $bold;
        font-size: 1.3em;
        text-decoration: none;

        .hover {
            text-decoration: underline;
        }
    }
}

.fancybox-nav {
    position: absolute;
    bottom: -35px;
    width: 40%;
    height: 20px;
    cursor: pointer;
    text-decoration: none;
    z-index: 8070;
    color: #fff;
    font-size: 1.3em;
    @include breakpoint(medium down) {
        bottom: 50%;
        width: 35px;
        height: 35px;
        display: block;
        &:focus {
            border: 1px dotted #fff;
        }
        span {
            display: none;
        }
    }

    &:hover,
    &:focus {
        .hover {
            text-decoration: underline;
        }
    }

    &:hover span {
        visibility: visible;
    }

    img {
        display: none;

        @include breakpoint(medium down) {
            display: inline;
        }
    }
}

.fancybox-prev {
    left: 10%;
    text-align: right;
    @include breakpoint(medium down) {
        left: 0;
    }

    span {
        padding: 0 10px 0 20px;
        background: url("#{$path-images}fancybox/precedent.png") no-repeat 0 50%;
    }
}


.fancybox-next {
    right: 10%;
    text-align: left;
    @include breakpoint(medium down) {
        right: 0;
    }

    span {
        padding: 0 20px 0 10px;
        background: url("#{$path-images}fancybox/suivant.png") no-repeat 100% 50%;
    }
}

.fancybox-tmp {
    position: absolute;
    top: -99999px;
    left: -99999px;
    visibility: hidden;
    max-width: 99999px;
    max-height: 99999px;
    overflow: visible !important;
}

// Overlay helper
.fancybox-lock {
    overflow: visible !important;

    body {
        overflow: hidden !important;
    }
}

.fancybox-overlay {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    display: none;
    z-index: 8010;
    background: rgba(0, 0, 0, .95);
    //background: rgba(0, 0, 0, .5);
}

.fancybox-overlay-fixed {
    position: fixed;
    bottom: 0;
    right: 0;
}

.fancybox-lock .fancybox-overlay {
    overflow: auto;
    overflow-y: scroll;
}

// Title helper
.fancybox-title {
    visibility: hidden;
    position: relative;
    text-shadow: none;
    z-index: 8050;
    padding: 0 15px 15px 15px;
    @include breakpoint(medium down) {
        //display: none;
    }

    .fancybox-galerie & {
        @include breakpoint(medium down) {
            position: absolute;
            bottom: -9999px;
            left: 0;
        }
    }

    .display & {
        @include breakpoint(medium down) {
            display: block;
            bottom: 0;
            z-index: 9800;
            background: rgba(0, 0, 0, .8);
        }
    }

    .fancybox-opened & {
        visibility: visible;
        background: transparent;
        width: 100%;
        @include breakpoint(medium down) {
            padding: 10px;
        }
    }

    .captionFancybox {
        padding-right: 100px;
    }

    &-float-wrap {
        position: absolute;
        bottom: 0;
        left: -15px;
        margin-bottom: -55px;
        z-index: 8050;

        .child {
            display: inline-block;
            padding: 2px 0;
            color: #FFF;
            font-weight: $bold;
            line-height: 24px;
        }
    }

    &-outside-wrap {
        position: relative;
        color: #fff;
    }

    &-inside-wrap {
        padding-top: 10px;
    }

    &-over-wrap {
        position: absolute;
        bottom: 0;
        left: 0;
        color: #fff;
        padding: 10px;
        background: #000;
        background: rgba(0, 0, 0, .8);
    }
}

.fancybox-more-infos {
    display: none;
    @include breakpoint(medium down) {
        display: block;
    }
}

#fancybox-buttons {
    position: fixed;
    width: 150px;
    height: 30px;
    bottom: 0;
    left: 0;
    text-align: left;
    z-index: 8050;
    @include breakpoint(medium down) {
        width: 34px;
        height: 32px;
        overflow: hidden;
        position: fixed;
        top: inherit;
        bottom: 0;
        z-index: 9996;
    }

    .btnPlay {
        color: #fff;
        text-decoration: none;
        font-size: 1.3em;
        @include img-before(relative, transparent, $path-images-fancybox, 'play.png', 30px, 30px, 1, -4px, inherit, inherit, 0);
        padding-left: 40px;

        @include breakpoint(medium down) {
            padding-left: 0;
        }

        img {
            display: none;
            @include breakpoint(medium down) {
                display: block;
            }
        }

        .hover {
            margin-top: 2px;
            display: block;
        }

        &:hover,
        &:focus {
            @include breakpoint(medium down) {
                border: 1px dotted #fff;
            }

            span {
                text-decoration: underline;
            }
        }

        &:before {
            @include breakpoint(medium down) {
                background: none;
            }
        }

        &.btnPlayOn {
            &:before {
                background-image: url('#{$path-images-fancybox}pause.png');
                @include breakpoint(medium down) {
                    background: none;
                }
            }
        }
    }
}

.fancybox-close-wrap {
    @include breakpoint(medium down) {
        position: fixed;
        bottom: 0;
        right: 0;
        width: 32px;
        //background: #000;
        //background: rgba(0, 0, 0, .8);
        text-align: right;
        z-index: 9995;
        .fancybox-close {
            position: static;
            bottom: inherit;
            right: inherit;
        }
    }
}

#popup-block {
    display: none;
}
