@include breakpoint(medium down) {
    .mobile-menu {
        position: absolute;
        top: 0;
        left: 0;
        -webkit-transform: translate(100%, 0);
        -ms-transform: translate(100%, 0);
        transform: translate(100%, 0);
        -webkit-transition: -webkit-transform 0.3s linear;
        transition: transform 0.3s linear;
        background: $color-3--4;
        color: $color-white;
        z-index: 999;
        padding: 0 20px;
        width: 100%;
        &-opener {
            float: right;
            width: 36px;
            height: 36px;
            position: relative;
            margin: 32px 0 0 10px;
            display: block;
            span {
                position: absolute;
                top: 52%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            }
            svg {
                width: 33px;
                height: 33px;
                fill: $search-bg;
                //path {
                //    fill: $color-1--2;
                //}
            }
            .mobile {
                display: none;
            }
        }
        .menu-main__level-1 > li .active {
            background-color: transparent;
        }
        &-overlay {
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 998;
            background: rgba(0, 0, 0, 0.7);
            display: none;
        }
        &-top {
            background: url(#{$path-images}bg/bg-decor-01.png);
            padding: 32px 20px 28px;
            margin: 0 -20px;
            overflow: hidden;
        }
        .btn-menu {
            float: left;
            color: $search-bg;
            font-size: 2.5rem;
            line-height: 1.2;
            font-weight: 700;
            background: none;
            border: none;
            padding: 0 0 2px 60px;
            text-transform: uppercase;
            position: relative;
            height: 40px;
            span {
                position: absolute;
                top: 50%;
                left: 0;
                -webkit-transform: translate(0, -50%);
                -ms-transform: translate(0, -50%);
                transform: translate(0, -50%);
            }
            svg {
                width: 33px;
                height: 33px;
                fill: $search-bg;
                //path {
                //    fill: $color-1--2 !important;
                //}
            }
        }
        .btn-close {
            float: right;
            width: 40px;
            height: 40px;
            position: relative;
            background: none;
            border: none;
            text-indent: -9999px;
            overflow: hidden;
            margin: 0 -10px 0 0;
            &:after,
            &:before {
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%) rotate(-45deg);
                -ms-transform: translate(-50%, -50%) rotate(-45deg);
                transform: translate(-50%, -50%) rotate(-45deg);
                width: 2px;
                height: 25px;
                background: $search-bg;
                content: '';
            }
            &:before {
                -webkit-transform: translate(-50%, -50%) rotate(45deg);
                -ms-transform: translate(-50%, -50%) rotate(45deg);
                transform: translate(-50%, -50%) rotate(45deg);
            }
        }
        .side-menu,
        .side-menu3 {
            display: block;
        }
    }
    .main-mob-menu {
        border-top: 1px solid $color-3--4;
        margin: 0 -20px;
        background: url(#{$path-images}bg/bg-decor-01.png);
        .menu-main__level-1 {
            float: none;
            display: block;
            > li {
                float: none;
                border-bottom: 1px solid $color-3--4;
                &.active {
                    > .title {
                        background: $color-3--4;
                        a {
                            color: $color-white;
                        }
                        .toggle {
                            border-left-color: $color-white;
                            &:after {
                                background: $color-white;
                            }
                            &:before {
                                display: none;
                            }
                        }
                    }
                }
                > .title {
                    padding: 0 84px 0 0;
                    position: relative;
                    a {
                        padding: 38px 20px 36px 51px;
                        display: block;
                        color: $color-3--4;
                        font-size: 2rem;
                        line-height: 1.2;
                        text-transform: uppercase;
                        font-weight: 700;
                        text-decoration: none;
                        //text-indent: -16px;
                        span {
                            padding: 0 2px 0 0;
                            color: $color-2--2;
                            letter-spacing: 1px;
                        }
                    }
                    .toggle {
                        width: 84px;
                        height: 38px;
                        position: absolute;
                        top: 50%;
                        right: 0;
                        -webkit-transform: translate(0, -50%);
                        -ms-transform: translate(0, -50%);
                        transform: translate(0, -50%);
                        border-left: 1px solid $color-2--2;
                        text-indent: -9999px;
                        overflow: hidden;
                        outline: none;
                        &:before,
                        &:after {
                            width: 1px;
                            height: 11px;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            -webkit-transform: translate(-50%, -50%);
                            -ms-transform: translate(-50%, -50%);
                            transform: translate(-50%, -50%);
                            background: $color-2--2;
                            margin: 0 0 0 -17px;
                            content: '';
                        }
                        &:after {
                            width: 11px;
                            height: 1px;
                        }
                    }
                }
            }
        }
        .menu-main__drop {
            background: $color-3--4;
            padding: 0 52px 50px;
            position: static;
            &-col {
                float: none;
                width: 100%;
                padding: 0;
                &.active {
                    .toggle {
                        background: $color-white;
                        &:before {
                            display: none;
                        }
                        &:after {
                            background: $color-3--4;
                        }
                    }
                }
                .title {
                    padding: 0 27px 0 0;
                    border-bottom: 3px solid $color-2--2;
                    position: relative;
                }
                .toggle {
                    width: 28px;
                    height: 28px;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    -webkit-transform: translate(0, -50%);
                    -ms-transform: translate(0, -50%);
                    transform: translate(0, -50%);
                    border: 1px solid $color-white;
                    text-indent: -9999px;
                    overflow: hidden;
                    &:before,
                    &:after {
                        width: 1px;
                        height: 11px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        -webkit-transform: translate(-50%, -50%);
                        -ms-transform: translate(-50%, -50%);
                        transform: translate(-50%, -50%);
                        background: $color-white;
                        content: '';
                    }
                    &:after {
                        width: 11px;
                        height: 1px;
                    }
                }
            }
            &-title {
                border: none;
                margin: 0;
                padding: 25px 10px 25px 0;
                cursor: pointer;
            }
        }
        .menu-main__drop-list {
            padding: 39px 0 22px 22px;
            li {
                margin: 0 0 10px;
            }
        }
    }

    .mob-menu-opened {
        .mobile-menu {
            -webkit-transform: translate(0, 0);
            -ms-transform: translate(0, 0);
            transform: translate(0, 0);
            &-overlay {
                display: block;
            }
        }
    }
}

@include breakpoint(small only) {
    .mobile-menu {
        &-opener {
            background: $search-bg;
            margin: 0 0 0 10px;
            svg {
                width: 16px;
                height: 16px;
                fill: $color-white;
            }
            .table {
                display: none;
            }
            .mobile {
                display: block;
            }
        }
        &-top {
            padding: 18px 20px 17px;
        }
        .btn-menu {
            font-size: 1.9rem;
            padding: 0 0 1px 26px;
            svg {
                width: 16px;
                height: 16px;
                path {
                    fill: $color-1--2 !important;
                }
            }
        }
        .btn-close {
            margin: 0 -12px 0 0;
            &:after,
            &:before {
                height: 21px;
            }
        }
    }
    .main-mob-menu {
        .menu-main__level-1 {
            float: none;
            > li {
                > .title {
                    padding: 0 64px 0 0;
                    a {
                        padding: 32px 20px 32px 27px;
                        font-size: 1.6rem;
                    }
                    .toggle {
                        width: 64px;
                        &:before,
                        &:after {
                            margin: 0 0 0 -7px;
                        }
                    }
                }
            }
        }
        .menu-main__drop {
            padding: 0 20px 50px;
        }
        .menu-main__drop-list {
            padding: 16px 0 6px 22px;
        }
    }
}
