.parking {
    align-items: flex-start;

    &__info {
        padding: 0 2rem;
        border-right: 1px solid $color-4--2;
        border-left: 1px solid $color-4--2;
        flex-shrink: 0;

        @include breakpoint(medium down) {
            padding: 2rem 0;
            border-top: 1px solid $color-4--2;
            border-right: 0;
            border-bottom: 1px solid $color-4--2;
            border-left: 0;
        }
    }

    > * {
        margin-right: 2rem;
    }

    @include breakpoint(medium down) {
        > * {
            margin: 0 0 2rem 0;
        }
    }
}

p.zone,
.zone {
    max-width: 12rem;
    padding: 1rem 2.5rem;
    margin: 0 auto 1.5rem;
    font-size: 2.8rem;
    font-weight: $bold;
    color: $color-black;
    text-align: center;
    line-height: 1;
    &--red {
        background-color: $color-red;
    }

    &--green {
        background-color: $color-4--1;
    }

    .parking & {
        margin: 0;
    }
}
