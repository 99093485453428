.list-type-1--event {
    .list-type-1__picture,
    .list-type-1__wrapper-date,
    .list-type-1__wrapper {
        display: table-cell;
        vertical-align: top;
    }
    .list-type-1__picture {
        float: none;
    }
    .list-type-1__wrapper-date {
        padding-right: 2em;
        width: 17em;
        border-right: 1px solid $color-4--2;
    }
    .list-type-1__wrapper {
        padding-left: 2em;
    }
}

@include breakpoint(medium down) {
    .list-type-1--event {
        .list-type-1__picture,
        .list-type-1__wrapper-date,
        .list-type-1__wrapper {
            display: table-cell;
            vertical-align: top;
        }
        .list-type-1__picture {
            img {
                margin-right: 2em;
            }
        }
        .list-type-1__wrapper-date {
            padding-right: 2em;
            border-right: 1px solid $color-3--3;

            display: table-cell;
            vertical-align: top;
            width: 13em;
            border-bottom: 0;
            margin-bottom: 0;
            .date-1, .hour-place {
                width: auto;
                display: block;
                vertical-align: inherit;
                padding-bottom: 0;
            }
            .date-1 {
                text-align: center;
                time:nth-of-type(1) {
                    margin-left: 1em;
                }
            }
        }
        .list-type-1__wrapper {
            padding-left: 2em;
        }
    }
}

@include breakpoint(small only) {
    .list-type-1--event {
        .list-type-1__picture, .list-type-1__wrapper-date, .list-type-1__wrapper {
            display: block;
            vertical-align: inherit;
        }
        .list-type-1__picture {
            img {
                margin-right: 0;
                width: 100%;
            }
            margin-bottom: 10px;
        }
        .list-type-1__wrapper-date {
            padding-right: 0;
            border-right: none;
            display: table;
            width: 100%;
            margin-bottom: 2em;
            .date-1, .hour-place {
                width: 50%;
                display: table-cell;
                vertical-align: middle;
                padding-bottom: 0;
            }
            .date-1 {
                text-align: left;
                time:nth-of-type(1) {
                    margin-left: 0;
                }
            }
        }
        .list-type-1__wrapper {
            padding-left: 0;
        }
    }
}
