.quartier-block {
	padding-top: 11px;
	padding-bottom: 19px;
	p {
		font-size: 1.4rem;
		line-height: 1.2;
		color: $color-3--4;
		margin-bottom: 20px;
	}
	&-threecolumns {
		display: flex;
		margin: 0 -25px;
		padding-top: 17px;
		&-col {
			flex: 1;
			padding: 0 25px;
		}
		.decor-title {
			text-transform: none;
			font-size: 2.5rem;
			text-align: left;
			margin-bottom: 17px;
			&:before {
				font-size: 2rem;
			}
		}
	}
	&-position-list {
		font-size: 1.4rem;
		line-height: 1.2;
		color: $color-3--4;
		font-weight: 300;
		li {
			margin: 0 0 17px;
			strong {
				font-weight: 700;
				color: $color-1--2;
			}
		}
		&-info {
			font-size: 1.4rem;
			line-height: 1.2;
			color: $color-3--4;
			li {
				margin: 0 0 17px;
			}
			strong {
				display: block;
				font-weight: 700;
				position: relative;
				padding: 0 0 0 10px;
				&:after {
					width: 6px;
					height: 6px;
					position:absolute;
					top:5px;
					left:0;
					background: $color-3--4;
					border-radius: 50%;
					content: '';
				}
			}
		}
	}
	&-address {
		font-size: 1.4rem;
		line-height: 1.2;
		color: $color-3--4;
		font-style: normal;
		span {
			display: block;
			margin: 0 0 17px;
		}
		a {
			color: $color-3--4;
		}
		strong {
			font-weight: 700;
			color: $color-1--2;
		}
		&-img {
			margin-bottom: 16px;
		}
	}
	&-info-list {
		font-size: 1.4rem;
		line-height: 1.2;
		color: $color-3--4;
		font-weight: 700;
		margin-bottom: 30px;
		li {
			margin: 0 0 17px;
		}
		a {
			display: block;
			position: relative;
			color: $color-3--4;
			padding: 0 0 0 10px;
			&:after {
				width: 6px;
				height: 6px;
				position:absolute;
				top:5px;
				left:0;
				background: $color-3--4;
				border-radius: 50%;
				content: '';
			}
		}
	}
}
@include breakpoint(small only) {
.quartier-block {
	padding-left: 5px;
	padding-right: 5px;
	padding-bottom: 0;
	&-threecolumns {
		flex-wrap: wrap;
		&-col {
			flex: none;
			width: 100%;
			padding: 0 25px 20px;
		}
		.decor-title {
			text-transform: none;
			font-size: 2.5rem;
			text-align: left;
			margin-bottom: 17px;
			&:before {
				font-size: 2rem;
			}
		}
	}
}
}
