.steps-lines {
	margin: 0 7px;
	p {
		color: $color-3--4;
		font-size: 1.6rem;
		line-height: 1.4;
		margin: 0 0 14px;
		font-weight: 400;
	}
	&-fild {
		margin: 0 0 26px;
		padding: 0 50px 0 0;
		position: relative;
		.button-search {
			position:absolute;
			top:0;
			right: 0;
		}
		select {
			background-image: url(#{$path-images}form/select_right-4.png);
		}
	}
	&-img {
		margin: 0 -7px;
		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}
}
@include breakpoint(small only) {
.steps-lines {
	margin: 0;
	&-img {
		margin: 0;
	}
}
}
