.quicklinks-block {
    $this: &;

    background-color: #f8f8f8;
    padding: 42px 0 48px;
    position: relative;

    .container {
        margin: 0 auto;
        max-width: 1220px;
        padding: 0 10px;
        width: 100%;
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 -17px;
    }

    &__item {
        padding: 0 17px 20px;
        width: 11.1111%;

        @include breakpoint(medium down) {
            width: 33.3333%;
        }

        @include breakpoint(small down) {
            width: 50%;
        }
    }

    &__content {
        align-items: center;
        display: flex;
        flex-direction: column;

        @include on-event() {
            text-decoration: none;

            #{$this}__icon {
                transform: translateY(-10px);

                svg {
                    fill: $color-2--5;
                }
            }

            #{$this}__title {
                color: $color-2--5;

                &::after {
                    max-width: 40px;
                }
            }
        }
    }

    &__icon {
        @include size(40px);
        @include trs();
        display: block;
        margin-bottom: 15px;

        svg {
            @include size(100%);
            @include trs();
            fill: $color-1--2;
        }

        img {
            @include size(100%, auto);
        }
    }

    &__title {
        @include trs();
        color: $color-3--4;
        font-size: 17px;
        font-weight: 300;
        padding-bottom: 10px;
        position: relative;
        text-align: center;

        &::after {
            @include size(100%, 2px);
            @include trs();
            background-color: $color-2--5;
            bottom: 0;
            content: '';
            left: 50%;
            max-width: 0;
            position: absolute;
            transform: translateX(-50%);
        }
    }

    .hero-banner-decor {
        background: $color-white;
    }
}
