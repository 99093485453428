// @name Table
.table-responsive {
    display: block;
    -webkit-overflow-scrolling: touch;
    /* stylelint-disable */
    -ms-overflow-style: -ms-autohiding-scrollbar;
    overflow-x: auto;
    /* stylelint-enable */
    width: 100%;

    &::-webkit-scrollbar {
        appearance: none;
        background-color: #efefef;
        width: 13px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $color-3--2;
    }
}

.proposer-table {
    $fz-caption: 1.8;
    $color-first-column: $color-2--3;
    $color-th: $color-1--2;
    $color-caption: $color-3--3;
    $border-color: $color-3--2;

    background-color: transparent;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 1.4rem;
    margin: 1rem 0;
    max-width: 100%;
    width: 100%;

    caption {
        color: $color-caption;
        font-size: em($fz-caption, 1.6);
        font-style: italic;
        font-weight: $bold;
        margin-bottom: em(1.5, $fz-caption);
        text-align: left;

        @include breakpoint(medium down) {
            text-align: left;
        }
    }

    th,
    td {
        @include trs(background-color);
        border: 0;
        min-width: 10rem;
        padding: 1rem 1.4rem;
        vertical-align: middle;

        ul,
        p {
            font-size: 1em;
        }
    }

    th {
        font-family: $typo-2;
        font-stretch: condensed;
        font-weight: $bold;
        text-align: left;
        text-transform: uppercase;
    }

    thead th {
        background: $color-white;
        color: $color-th;
    }

    tbody th {
        color: $color-first-column;
        padding-left: em(5, 1.6);
        padding-right: em(5, 1.6);
        text-align: right;

        @include breakpoint(medium down) {
            padding-left: em(3, 1.6);
            padding-right: em(3, 1.6);
        }
    }

    td,
    th {
        @include trs(background-color);
        border: 0;
        min-width: 10rem;
        padding: 1rem 1.4rem;
    }

    td {
        background: $color-white;
        color: $color-black;
        font-size: 1.6rem;
        font-weight: $light;
        min-width: 10rem;

    }

    tbody tr:nth-child(odd) td {
        background-color: $color-3--1;
        cursor: pointer;
    }

    tbody tr:hover td {
        background-color: $color-3--2;
        cursor: pointer;
    }

    td:last-child {
        text-align: center;
    }

    .proposer-action {
        @include trs(color);
        @include size(3rem);
        color: $color-1--2;
        font-size: 1.6rem;
        margin: 0 0.2rem;

        @include on-event() {
            color: $color-2--2;
            text-decoration: none;
        }
    }
}

.proposer {
    &--btn {
        text-decoration: none !important;
    }
}

.confirmation-popup {
    position: relative;
    padding: 4rem;
    text-align: center;
    width: 100%;

    &__close {
        @include trs;
        @include absolute(0, 0);
        @include size(4rem);
        align-items: center;
        background-color: $color-1--2;
        border: 0;
        border-radius: 0;
        color: $color-white;
        cursor: pointer;
        display: flex;
        font-size: 1.4rem;
        justify-content: center;
        margin: 0;
        padding: 0;

        @include on-event {
            background-color: $color-1--3;
        }
    }

    &__title {
        color: $color-1--2;
        font-family: $typo-1;
        font-size: 1.8rem;
        font-weight: $bold;
        margin: 0 0 1.5rem;
    }

    &__action {
        margin: 0.5rem;
        min-width: 9rem;
    }
}
